export const TIMER_MAX_VALUE = 60 * 3;
export const TIMER_MAX_LABEL_VALUE = "03:00";
export const AUTHCODE_MAX_LENGTH = 6;

export const CommonString = {
  // 인증번호 받지 못하셨나요? 관련 string
  AuthCodeInfoTitle: "인증번호 메시지를 받지\n못하셨나요?",
  AuthCodeInfoDescription: "1660-3114번으로 문자가 발송되며,\n휴대전화의 차단 메세지함을 확인하거나\n가입한 통신사로 문자 차단 서비스를\n이용하고 있는지 확인해 주세요.​",

  // 유저 전화번호 인증시 회원가입하지 않은 유저일 경우
  NotFoundUserTitle: "가입된 정보가 없습니다.",
  NotFoundUserDescription: "회원가입 페이지로 이동할까요?",

  // 회원가입시 이미 가입된 유저일 경우
  AlreadyUserTitle: "이미 가입된 회원입니다.",
  AlreadyUserDescription: "로그인 페이지로 이동할까요?",

  // 비밀번호 확인 에러 메세지
  PasswordErrorMessage: "영문, 숫자, 특수문자 중 2가지를 포함한 8~20자 이내로 입력해주세요.",
  PasswordCheckErrorMessage: "입력하신 새 비밀번호와 일치하지 않습니다.",

  // 비밀번호 변경 성공 메세지
  PasswordChangeSuccessMessage: '비밀번호가 변경되었습니다.',
  PasswordChangeSuccessDescription: '로그인 후 이용해 주세요.',
}