<script setup>
import {computed, ref, onBeforeMount, watch, nextTick} from "vue";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import coffee_icon_home from "@/assets/icon/coffee_icon_home.svg";
import {useRouter, useRoute} from "vue-router";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {checkDisposeAvailable, getCafe, getRequestRegister} from "@/api/coffee.js";
import {storeToRefs} from "pinia";
import dayjs from "dayjs";
import iconMain from "@/assets/image/coffee/icon-main.svg?raw";
import iconMore from "@/assets/image/coffee/icon-more.svg?raw";
import iconRequest from "@/assets/image/coffee/icon-request.svg?raw";
import iconRequestList from "@/assets/image/coffee/icon-request-list.svg?raw";
import iconZiguhada from "@/assets/image/coffee/icon-ziguhada.svg?raw";
import iconMainActive from "@/assets/image/coffee/icon-main-active.svg?raw";
import iconMoreActive from "@/assets/image/coffee/icon-more-active.svg?raw";
import iconRequestActive from "@/assets/image/coffee/icon-request-active.svg?raw";
import iconRequestListActive from "@/assets/image/coffee/icon-request-list-active.svg?raw";
import iconZiguhadaActive from "@/assets/image/coffee/icon-ziguhada-active.svg?raw";
import {navigateTo} from "@/utils/coffee.js";


const coffeeStore = useCoffeeStore()
const {cafe} = storeToRefs(coffeeStore)
const router = useRouter()
const route = useRoute()


const alertIsShown = ref(false)
const alertBody = ref('')
const alertCallback = ref(null)

const showAlert = (body, cb = null) => {
  alertBody.value = body
  alertIsShown.value = true
  alertCallback.value = cb
}

const closeAlert = async () => {
  console.log(alertCallback.value)
  if (alertCallback.value) {
    await alertCallback.value()
  }
  await nextTick(() => {
    alertIsShown.value = false
    alertBody.value = ''
    alertCallback.value = null
  })
}


const menuItems = [
  {route_name: null, action: 'close', label: '지구하다', icon: iconZiguhada, iconActive: iconZiguhadaActive},
  {route_name: 'coffee-index', action: null, label: '메인', icon: iconMain, iconActive: iconMainActive},
  {
    route_name: null,
    action: 'coffee-request-collection',
    label: '수거신청',
    icon: iconRequest,
    iconActive: iconRequestActive
  },
  {
    route_name: 'coffee-list-collection',
    action: null,
    label: '신청내역',
    icon: iconRequestList,
    iconActive: iconRequestListActive
  },
  {route_name: 'coffee-menu', action: null, label: '더보기', icon: iconMore, iconActive: iconMoreActive},
]

// window.flutter_inappwebview.callHandler('zguhadaWebviewClose')

const menuAction = async (action_name) => {
  if (action_name === 'close') {
    if (window?.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('zguhadaWebviewClose')
      return {success: true}
    } else {
      showAlert('지구하다 앱에서만 사용 가능합니다.')
    }
  } else {
    const {success, message, callback} = await navigateTo(action_name)
    if (success === false) {
      showAlert(message, callback)
    } else {
      await nextTick(async () => {
        await callback()
      })
    }
  }
}
</script>
<template>
  <div
      class="footer fixed mx-auto bottom-0 inline-flex left-0 right-0 w-full max-w-md px-5 items-center justify-between z-0 pt-3 pb-5">
    <template v-for="menuItem in menuItems">
      <template v-if="menuItem.route_name">
        <router-link :to="{name: menuItem.route_name}"
                     class="menu-item flex flex-column justify-center items-center flex-grow-1">
          <template v-if="route.name === menuItem.route_name">
            <div class="menu-icon" v-html="menuItem.iconActive"/>
            <div class="icon-label active">{{ menuItem.label }}</div>
          </template>
          <template v-else>
            <div class="menu-icon" v-html="menuItem.icon"/>
            <div class="icon-label">{{ menuItem.label }}</div>
          </template>
        </router-link>
      </template>
      <template v-else>
        <div class="menu-item flex flex-column justify-center items-center flex-grow-1"
             @click="menuAction(menuItem.action)">
          <template v-if="route.name === menuItem.route_name">
            <div class="menu-icon" v-html="menuItem.iconActive"/>
            <div class="icon-label active">{{ menuItem.label }}</div>
          </template>
          <template v-else>
            <div class="menu-icon" v-html="menuItem.icon"/>
            <div class="icon-label">{{ menuItem.label }}</div>
          </template>
        </div>
      </template>
    </template>
  </div>
  <!--  <CoffeeAlert :body="'업체 정보 변경이 필요할 경우 고객센터(1660-3114)로 문의하세요.'" :show="alertIsShown" @close="alertIsShown=false"/>-->
  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="closeAlert"/>
</template>

<style scoped>
.footer {
  border-top: 1px solid #F5F5F5;
  background-color: white;
  height: 87px;
}

.menu-item {
  width: 51px;
  user-select: none;
  cursor: pointer;
}

.menu-icon {
  width: 35px;
  height: 35px;
}

.menu-icon > svg > path {
  fill: #3182FF;
}

.icon-label {
  color: #B7B7B7;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
}

.icon-label.active {
  color: #3182FF;
}

</style>