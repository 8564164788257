import authAxiosServices from "@/utils/axios/authAxios";
import axiosServices from "@/utils/axios/axios";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";

const putCellPhoneAuth = async (cellphone) => {
  const url = `/common/cellphoneauth/simple/`;

  const response = await authAxiosServices({
    method: PUT,
    url: url,
    data: {
      cellphone: cellphone,
    },
  });

  return response.data;
};

/* response 예시
{
    "is_new_user": false,
    "user_id": "2418c16e-0bec-41a2-bac9-6804db1f2d02",
    "user_name": "이상하"
}
*/
const postCellphoneAuth = async (id, authNo) => {
  const url = `/zguhada/cellphone/auth/`;

  const response = await authAxiosServices({
    method: POST,
    url: url,
    data: {
      id: id,
      auth_no: authNo,
    },
  });

  return response.data;
};

/* response 예시
{
    "refresh": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcwNjg1Mzg3MywiaWF0IjoxNzA0MjYxODczLCJqdGkiOiJlN2UwMzdmNjMwZDc0NGY0OTBkMTVjYjJlNjgyNzc1ZCIsInVzZXJfaWQiOjI0MX0.pqKYkTJooJk648JQmsNp6y0tKZoLUG8h6jqJNOJ-63g",
    "access": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA0MjYyNDczLCJpYXQiOjE3MDQyNjE4NzMsImp0aSI6ImQwMDZlNzExMDg1YjQ1MWQ4NjdhNzJhZDBkZWM0NmUwIiwidXNlcl9pZCI6MjQxfQ.hTfyyM0tWEjFzLdB7UsSxjVKis98OnGKSpIVg82OWnY",
    "is_new_user": false,
    "profile": {
        "id": "2418c16e-0bec-41a2-bac9-6804db1f2d02",
        "cellphone": "01047086969",
        "name": "이상하",
        "subscription_date": 91,
        "terms_state": [
            {
                "terms_code": "old_14",
                "is_agree": true,
                "agree_at": "2023-10-04T10:48:52.474987+09:00"
            },
            {
                "terms_code": "service_use",
                "is_agree": true,
                "agree_at": "2023-10-04T10:48:52.479313+09:00"
            },
            {
                "terms_code": "personal_info",
                "is_agree": true,
                "agree_at": "2023-10-04T10:48:52.482346+09:00"
            },
            {
                "terms_code": "marketing_sms",
                "is_agree": true,
                "agree_at": "2023-10-04T10:48:52.484971+09:00"
            },
            {
                "terms_code": "marketing_push",
                "is_agree": true,
                "agree_at": "2023-10-04T10:48:52.487457+09:00"
            }
        ],
        "marketing_sms": {
            "terms_code": "marketing_sms",
            "is_agree": true,
            "agree_at": "2023-10-04T10:48:52.484971+09:00"
        },
        "marketing_push": {
            "terms_code": "marketing_push",
            "is_agree": true,
            "agree_at": "2023-10-04T10:48:52.487457+09:00"
        }
    }
}
*/
const postLogin = async (userId, password, phoneAuthId) => {
  const url = `/zguhada/login/password/`;

  try {
    const response = await authAxiosServices({
      method: POST,
      url: url,
      data: {
        password: password,
        user_id: userId,
        phone_auth_id: phoneAuthId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

/* response 예시
{
    "refresh": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcwNjkyMjk2NCwiaWF0IjoxNzA0MzMwOTY0LCJqdGkiOiI5ODAwZjlkNmY4NWQ0Y2M4YWVmN2VkN2MzOGI0MzAwNSIsInVzZXJfaWQiOjl9.ixi4hbNbX1asgGS6tfKue4M_xnyIUiBc3LQ02XqBULE",
    "access": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzA0MzMxNTY0LCJpYXQiOjE3MDQzMzA5NjQsImp0aSI6IjJlOWViNDE4YTg1ZjQxMmJiOTMxMDZlZTA3ZWFhMjViIiwidXNlcl9pZCI6OX0.sYRftebR5LAkZQR6x7lJJrmle8__9ifzLSMKkYUGt9Q",
    "is_new_user": true,
    "profile": {
        "id": "d5d06da1-dec0-4f64-be42-968478f52389",
        "cellphone": "01047086969",
        "name": "이상하",
        "subscription_date": 0,
        "terms_state": [
            {
                "terms_code": "old_14",
                "is_agree": true,
                "agree_at": "2024-01-04T10:16:04.685147+09:00"
            },
            {
                "terms_code": "service_use",
                "is_agree": true,
                "agree_at": "2024-01-04T10:16:04.688402+09:00"
            },
            {
                "terms_code": "personal_info",
                "is_agree": true,
                "agree_at": "2024-01-04T10:16:04.690348+09:00"
            },
            {
                "terms_code": "marketing_push",
                "is_agree": true,
                "agree_at": "2024-01-04T10:16:04.694043+09:00"
            },
            {
                "terms_code": "marketing_sms",
                "is_agree": true,
                "agree_at": "2024-01-04T10:16:04.692213+09:00"
            }
        ],
        "marketing_sms": {
            "terms_code": "marketing_sms",
            "is_agree": true,
            "agree_at": "2024-01-04T10:16:04.692213+09:00"
        },
        "marketing_push": {
            "terms_code": "marketing_push",
            "is_agree": true,
            "agree_at": "2024-01-04T10:16:04.694043+09:00"
        }
    }
}
*/

const postRegister2 = async (
  phone_auth_id,
  name,
  password,
  old_14,
  personal_info,
  service_use,
  marketing_push,
  marketing_sms
) => {
  try {
    const response = await authAxiosServices({
      method: POST,
      url: `/zguhada/register2/`,
      data: {
        phone_auth_id: phone_auth_id,
        name: name,
        password: password,
        old_14: old_14,
        personal_info: personal_info,
        service_use: service_use,
        marketing_push: marketing_push,
        marketing_sms: marketing_sms,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const postRefresh = async (refresh) => {
  try {
    const response = await authAxiosServices({
      method: POST,
      url: `/account/token/refresh/`,
      data: {
        refresh: refresh,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const postLoginToken = async () => {
  const url = `/zguhada/login/`;

  try {
    const response = await axiosServices({
      method: POST,
      url: url,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  putCellPhoneAuth,
  postCellphoneAuth,
  postLogin,
  postRefresh,
  postRegister2,
  postLoginToken,
};
