import { defineStore } from "pinia";

export const useLayoutStore = defineStore({
  id: "layout",
  state: () => ({
    onLoading: false,
    errorMessage: "",
    errorDescription: "",
    isShowErrorPopup: false,
    infoMessage: "",
    infoDescription: "",
    infoOkCallback: null,
    isShowInfoPopup: false,
    isShowConfrimPopup: false,
    confirmMessage: "",
    confirmDescription: "",
    confirmOkayAction: null,
    confirmCancelAction: null,
    isMobile: false,
  }),
  actions: {
    setLoading(loading) {
      this.onLoading = loading;
    },
    showErrorPopup(message, description) {
      this.errorMessage = message;
      this.errorDescription = description;
      this.isShowErrorPopup = true;
    },
    closeErrorPopup() {
      this.errorMessage = "";
      this.errorDescription = "";
      this.isShowErrorPopup = false;
    },
    showInfoPopup(message, description, infoOkCallback) {
      this.infoMessage = message;
      this.infoDescription = description;
      this.isShowInfoPopup = true;
      this.infoOkCallback = infoOkCallback;
    },
    showConfirmPopup({ message, description, okayAction, cancelAction }) {
      this.isShowConfrimPopup = true;
      this.confirmMessage = message;
      this.confirmDescription = description;
      this.confirmOkayAction = okayAction;
      this.confirmCancelAction = cancelAction;
    },
    closeInfoPopup() {
      this.infoMessage = "";
      this.infoDescription = "";
      if (this.infoOkCallback) {
        this.infoOkCallback();
      }
      this.isShowInfoPopup = false;
    },
    closeConfirmPopup() {
      this.isShowConfrimPopup = false;
      this.confirmMessage = "";
      this.confirmDescription = "";
      this.confirmOkayAction = null;
      this.confirmCancelAction = null;
    },
  },
});
