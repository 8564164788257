<script setup>
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {storeToRefs} from "pinia";

const coffeeStore = useCoffeeStore()
const {onLoading} = storeToRefs(coffeeStore)
</script>

<template>
  <div v-if="onLoading" class="loading-wrapper z-40" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <svg class="animate-spin" fill="none" height="32" viewBox="0 0 33 32" width="33" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd"
            d="M16.5 24C20.9183 24 24.5 20.4183 24.5 16C24.5 11.5817 20.9183 8 16.5 8C12.0817 8 8.5 11.5817 8.5 16C8.5 20.4183 12.0817 24 16.5 24ZM16.5 32C25.3366 32 32.5 24.8366 32.5 16C32.5 7.16344 25.3366 0 16.5 0C7.66344 0 0.5 7.16344 0.5 16C0.5 24.8366 7.66344 32 16.5 32Z"
            fill="#DFEDFF"
            fill-rule="evenodd"/>
      <mask id="path-2-inside-1_569_447" fill="white">
        <path
            d="M32.2458 18.8409C31.7942 21.3439 30.7523 23.7032 29.2067 25.7232C27.6611 27.7431 25.6562 29.3654 23.3582 30.4556C21.0603 31.5458 18.5356 32.0725 15.9934 31.992C13.4513 31.9114 10.9649 31.226 8.74061 29.9926C6.51629 28.7591 4.61807 27.0131 3.20338 24.8994C1.78869 22.7857 0.89828 20.3652 0.605998 17.8387C0.313715 15.3121 0.627981 12.7522 1.52273 10.3714C2.41748 7.99052 3.86694 5.85728 5.7509 4.14855L11.1306 10.0799C10.1895 10.9335 9.46547 11.9991 9.01853 13.1884C8.57158 14.3777 8.4146 15.6564 8.5606 16.9185C8.7066 18.1805 9.15138 19.3896 9.85805 20.4455C10.5647 21.5013 11.5129 22.3734 12.624 22.9896C13.7351 23.6057 14.9771 23.9481 16.247 23.9883C17.5168 24.0286 18.778 23.7655 19.9258 23.2209C21.0737 22.6763 22.0752 21.8659 22.8473 20.8569C23.6194 19.8479 24.1398 18.6694 24.3654 17.4191L32.2458 18.8409Z"/>
      </mask>
      <path
          d="M32.2458 18.8409C31.7942 21.3439 30.7523 23.7032 29.2067 25.7232C27.6611 27.7431 25.6562 29.3654 23.3582 30.4556C21.0603 31.5458 18.5356 32.0725 15.9934 31.992C13.4513 31.9114 10.9649 31.226 8.74061 29.9926C6.51629 28.7591 4.61807 27.0131 3.20338 24.8994C1.78869 22.7857 0.89828 20.3652 0.605998 17.8387C0.313715 15.3121 0.627981 12.7522 1.52273 10.3714C2.41748 7.99052 3.86694 5.85728 5.7509 4.14855L11.1306 10.0799C10.1895 10.9335 9.46547 11.9991 9.01853 13.1884C8.57158 14.3777 8.4146 15.6564 8.5606 16.9185C8.7066 18.1805 9.15138 19.3896 9.85805 20.4455C10.5647 21.5013 11.5129 22.3734 12.624 22.9896C13.7351 23.6057 14.9771 23.9481 16.247 23.9883C17.5168 24.0286 18.778 23.7655 19.9258 23.2209C21.0737 22.6763 22.0752 21.8659 22.8473 20.8569C23.6194 19.8479 24.1398 18.6694 24.3654 17.4191L32.2458 18.8409Z"
          mask="url(#path-2-inside-1_569_447)" stroke="#3182FF" stroke-width="16"/>
    </svg>
  </div>
</template>

<style scoped>
.loading-wrapper {
  position: fixed;
  display: flex;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.68);
  overflow: hidden;
  inset: 0;
  justify-content: center;
  align-items: center;
}
</style>