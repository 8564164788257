<script setup>
import { ref, defineEmits, defineProps } from "vue";

const props = defineProps({
  showDialog: Boolean
});

const emit = defineEmits(["closeDialog"]);

const closeDialog = () => {
  console.log("waht waht what");
  emit("closeDialog");
};

// function

function onClickDownloadGoogle() {
  window.open(
    "https://play.google.com/store/apps/details?id=com.chunil.zguhada.platform.app.zguhada_platform",
    "_blank"
  );
}

function onClickDownloadApple() {
  window.open("https://apps.apple.com/app/id6459451210", "_blank");
}

function onClickAppLink() {
  window.open("https://onelink.to/epwdef", "_blank");
}

</script>

<template>
  <div class="modal_overlay_container pc" v-show="props.showDialog">
    <div class="modal_container bg_white">
      <div class="bottom_modal_wrap">
        <div class="modal_header flex flex_row justify_end align_center">
          <button @click="closeDialog">
            <img src="../assets/icon/ic_x.png" alt="닫기" />
          </button>
        </div>
        <div class="modal_content">
          <p class="modal_title center">
            지구하다로<br>
            <span class="extra_bold"> 폐기물처리가 쉬워집니다.</span>
          </p>
          <div class="flex flex_row justify_center align_start">
            <!-- 스토어 다운로드 -->
            <div
              class="store_download_wrap flex flex_col justify_center align_center"
            >
              <p class="text_22 gray2 semi_bold center wrap_title">
                스토어 다운로드
              </p>
              <button
                id="googlePlay"
                class="mid left white flex justify_start align_center"
                @click="onClickDownloadGoogle"
              >
                <img src="../assets/image/img_google_play.png" alt="googlePlay" />
                Google Play
              </button>
              <button
                id="appStore"
                class="mid left white flex justify_start align_center"
                @click="onClickDownloadApple"
              >
                <img src="../assets/image/img_app_store.png" alt="appStore" />
                App Store
              </button>
            </div>
            <!-- 세로 구분줄 -->
            <div class="divide"></div>
            <!-- QR 코드 -->
            <div class="qr_code_wrap flex flex_col justify_center align_center">
              <p class="text_22 gray2 semi_bold center wrap_title">
                스토어 다운로드
              </p>
              <img src="../assets/image/img_qr_code.png" alt="QR코드 이미지" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile bg-white position-fixed w-100 h-100" style="top: 50%; left: 50%;   transform: translate(-50%, -50%); z-index: 110;" v-if="props.showDialog">
    <div class="flex flex_row justify_end align_center position-absolute" style=" top: 0; right: 0; margin: 20px;">
      <button @click="closeDialog">
        <img src="../assets/icon/ic_x.png" alt="닫기" />
      </button>
    </div>
    <div class="modal_content flex position-absolute" style="flex-grow: 1; transform: translate(-50%, -50%);top: 50%; left: 50%;  ">
      <div class="fixded_bg flex flex_col justify_center align_center" style="margin: auto">
        <p class="title black2 bold center">지구를 구하는<br />가장 쉬운방법</p>
        <img class="ic_zguhada" src="../assets/ic_zguhada.png" alt="지구하다 앱 아이콘" />
        <p class="subtitle black center">지구하다 앱으로<br />이동합니다.</p>
        <button class="open_app mid black flex justify_center align_center" @click="onClickAppLink">지구하다 앱에서 열기</button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.pc {
  display: block;
}

.mobile {
  display: none;
}


.dialog-center .v-overlay__content {
  width: auto !important;
  max-width: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_overlay_container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #00000080;
}

.modal_container {
  width: auto;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  box-shadow: 0px -17px 25px 0px #00000040;
  z-index: 110;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.bottom_modal_wrap {
  max-width: 1200px;
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
}

.bottom_modal_wrap .modal_header {
  height: 94px;
  padding-right: 30px;
}

.bottom_modal_wrap .modal_header img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.bottom_modal_wrap p.modal_title {
  font-size: 50px;
  line-height: 66px;
  margin-bottom: 60px;
}

.bottom_modal_wrap .modal_content {
  padding: 0px 86px 80px 86px;
}

.bottom_modal_wrap .modal_content .wrap_title {
  margin-bottom: 30px;
}

.modal_content .store_download_wrap {
  width: 290px;
}

.modal_content .store_download_wrap button {
  background-color: #242b35;
  border-radius: 200px;
  padding: 24px 40px;
  font-size: 26px;
  line-height: 36px;
  white-space: nowrap;
}

.modal_content .store_download_wrap button#appStore {
  margin-top: 30px;
  letter-spacing: 0.05em;
}

.modal_content .store_download_wrap button > img {
  width: 54px;
  height: 54px;
  margin-left: 9px;
}

.modal_content .divide {
  width: 1px;
  height: 210px;
  background-color: #d9d9d9;
  margin: 70px 75px auto 66px;
}

.modal_content .qr_code_wrap {
  width: 270px;
}

.modal_content .qr_code_wrap img {
  width: 270px;
  height: 270px;
}

/* 태블릿 이하 */
@media screen and (max-width: 992px) {

  .pc {
    display: none;
  }

  .mobile {
    display: block;
  }

  .modal_overlay_container {
    display: none;
  }

  .modal_container {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }

  .bottom_modal_wrap {
    width: 100%;
    height: 100%;
  }

  .bottom_modal_wrap .modal_header {
    height: 100px;
  }

  .bottom_modal_wrap p.modal_title {
    font-size: 40px;
    line-height: 56px;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }

  img.ic_zguhada {
    width: 80px;
    height: 80px;
    margin: 30px auto;
  }

  .subtitle {
    font-size: 17px;
    line-height: 26px;
  }

  button.open_app {
    width: 194px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 27px;
    margin-top: 60px;
  }
}
</style>
