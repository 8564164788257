<script setup>
import { useRequestStore } from "@/stores/requestStore";
import { computed } from "vue";

const props = defineProps({
  disposeDate: String,
  disposeAdress: String,
  disposeAdressDetail: String,
});

const selectedDisposeDate = computed(() => {
  return props.disposeDate === null || props.disposeDate === undefined
    ? requestStore.disposeDate
    : props.disposeDate;
});

const selectedDisposeAddress = computed(() => {
  return props.disposeAdress === null || props.disposeAdress === undefined
    ? requestStore.address
    : props.disposeAdress;
});

const selectedDisposeAddressDetail = computed(() => {
  return props.disposeAdressDetail === null ||
    props.disposeAdressDetail === undefined
    ? requestStore.addressDetail
    : props.disposeAdressDetail;
});

const requestStore = useRequestStore();

const totalItemTitle = computed(() => requestStore.getNavigatorItemTitle());
</script>

<template>
  <div class="right_wrap bg_white">
    <p class="subtitle_5 color_secondary left">배출정보</p>

    <!-- 배출일 -->
    <div class="input_row">
      <div
        class="input_wrap2 flex flex_row justify_center align_end"
        :class="{
          active: selectedDisposeDate !== '' && selectedDisposeDate !== null,
        }"
      >
        <div class="grow flex flex_col justify_start align_stretch">
          <label for="throwDate" class="input_label label_2 color_secondary">
            배출일
          </label>
          <input
            class="input body_2"
            type="text"
            name="throwDate"
            id="throwDate"
            v-model="selectedDisposeDate"
            disabled
          />
        </div>
      </div>
    </div>
    <!-- 배출장소 -->
    <div class="input_row">
      <div
        class="input_wrap2 flex flex_row justify_center align_end"
        :class="{
          active:
            selectedDisposeAddress !== '' && selectedDisposeAddress !== null,
        }"
      >
        <div class="grow flex flex_col justify_start align_stretch">
          <label for="throwAddress" class="input_label label_2 color_secondary">
            배출장소
          </label>
          <input
            class="input body_2"
            placeholder="배출지 주소 입력"
            type="text"
            name="throwAddress"
            id="throwAddress"
            v-model="selectedDisposeAddress"
            disabled
          />
          <input
            class="input body_2"
            placeholder="상세 주소 입력"
            type="text"
            name="throwAddressDetail"
            id="throwAddressDetail"
            style="margin-top: 10px"
            v-model="selectedDisposeAddressDetail"
            disabled
          />
        </div>
      </div>
    </div>
    <!-- 품목 선택 -->
    <div class="input_row">
      <div
        class="input_wrap2 flex flex_row justify_center align_end"
        :class="{
          active: totalItemTitle !== '' && totalItemTitle !== null,
        }"
      >
        <div class="grow flex flex_col justify_start align_stretch">
          <label for="throwType" class="input_label label_2 color_secondary">
            배출품목
          </label>
          <input
            class="input body_2"
            placeholder="배출 품목 선택"
            type="text"
            name="throwType"
            id="throwType"
            v-model="totalItemTitle"
            disabled
          />
        </div>
      </div>
    </div>

    <!-- 합계 -->
    <div
      class="total_wrap bg_white flex flex_col justify_space_between align_start"
    >
      <div
        class="flex flex_row justify_space_between align_center"
        style="width: 100%"
      >
        <span class="subtitle_7 color_secondary left">총 합계 금액</span>
        <span class="text_14 black3 right">
          <span class="subtitle_5 color_primary right">
            {{ $formatNumber(requestStore.getTotalPrice()) }}
            <span class="subtitle_7 color_secondary right">원 </span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input_wrap2 {
  margin-top: 30px;
}
</style>
