import axiosServices from "@/utils/axios/axios";
import axios from "axios";
import { useRequestStore } from "@/stores/requestStore";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";

/* response 예시
{
    "id": 1,
    "name": "성동구청",
    "payment_account_id": "f0614336-a0ae-4d40-9065-4b856bab060f",
    "pg_payment_method_id": "1f5c2484-be9c-4fc9-ab34-16d148ba5f9a",
    "payment_secret_key": "hIoedqHmTc6cToBCsosY7AFcAn0mpRa7SQpZj8tcaZU",
    "bank_account": {
        "id": 1,
        "name": "김원빈계좌",
        "bank": "031",
        "bank_display": "대구은행",
        "account_number": "508140843236",
        "account_holder": "김원빈"
    }
}
*/

const getBlwServiceGovernment = async (
  guCode,
  addressName,
  addressType,
  x,
  y,
  address,
  roadAddress
) => {
  const url = `/zguhada/blw/serviceGovernment/${guCode}`;

  const params = {
    address_name: addressName,
    addressType: addressType,
    x: x,
    y: y,
    address: address,
    road_address: roadAddress,
  };

  const response = await axiosServices({
    method: GET,
    url: url,
    params: params,
  });

  return response.data;
};

/*
{
    "registered_location_id": 3,
    "dispose_location_id": 1
}
*/
const getBlwDisposeLocation = async (govId, x, y, b_code, bonbun, bubun) => {
  const url = `/zguhada/blw/${govId}/disposeLocation/`;
  const response = await axiosServices({
    method: GET,
    url: url,
    params: {
      x: x,
      y: y,
      b_code: b_code,
      bonbun: bonbun,
      bubun: bubun,
    },
  });

  return response.data;
};

/*
"category_list": [
    {
      "id": 635,
      "name": "공기청정기",
      "parent_id": 318,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 315,
      "name": "TV/오디오",
      "parent_id": 312,
      "level": 2,
      "zguhada_icon": null
    },
    {
      "id": 720,
      "name": "아동용 자전거",
      "parent_id": 344,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 700,
      "name": "선반",
      "parent_id": 308,
      "level": 3,
      "zguhada_icon": null
    },
    {
      "id": 734,
      "name": "씨디장",
      "parent_id": 300,
      "level": 3,
      "zguhada_icon": null
    },
*/
const getProductCategoryList = async (govId) => {
  const url = `/zguhada/blw/${govId}/product/category/list/`;

  const response = await axiosServices({
    method: GET,
    url: url,
  });

  return response.data;
};

/*
{
    "category_list": [
        {
            "id": 303,
            "name": "의자",
            "parent_id": 297,
            "level": 2,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 730,
            "name": "스툴/화장대 의자(미니)",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 736,
            "name": "안마의자",
            "parent_id": 319,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 753,
            "name": "범보의자",
            "parent_id": 344,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 784,
            "name": "바퀴 달린 의자",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 785,
            "name": "의자",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 799,
            "name": "의자(플라스틱)",
            "parent_id": 303,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 810,
            "name": "차량용 의자(앞좌석)",
            "parent_id": 346,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        },
        {
            "id": 813,
            "name": "캠핑/낚시 의자",
            "parent_id": 340,
            "level": 3,
            "zguhada_icon": null,
            "order_value": 9999
        }
    ]
}
*/
const getProductCategoryListSearch = async (govId, name) => {
  const url = `/zguhada/blw/${govId}/product/category/list/`;

  let params = {};

  if (name !== "" || name !== null) {
    params = {
      name: name,
    };
  }

  const response = await axiosServices({
    method: GET,
    url: url,
    params: params,
  });

  return response.data;
};

/*
{
    "id": 786,
    "name": "모든 규격",
    "price": 7000
}
*/

const getProductStandardList = async (govId, categoryId) => {
  const url = `/zguhada/blw/${govId}/product/standard/list/`;

  const response = await axiosServices({
    method: GET,
    url: url,
    params: {
      product_id: categoryId,
    },
  });

  return response.data;
};

/*
{
  "id": "1a12ae6c-c36a-455b-a459-c27ce4b1a001",
  "dispose_no": null,
  "deposit_deadline": "2024-01-14"
}
*/

const postBlwDisposeReportRequest = async (
  govId,
  name,
  cellphone,
  disposeMemo,
  disposeDate,
  address,
  detailAddress,
  addressType,
  postcodeJson,
  disposeLocationId,
  registeredLocationId,
  paymentMethod,
  paymentId,
  itemList,
  uploadImages,
  channel,
  cash_receipt_type,
  cash_receipt_number
) => {
  const url = `/zguhada/blw/${govId}/disposeReport/request/`;

  const convertAddressType = addressType === "ROAD_ADDR" ? "road" : "jibun";

  var convertPostCodeJson = postcodeJson;
  if (typeof postcodeJson == "string") {
    convertPostCodeJson = JSON.parse(postcodeJson);
  }

  const params = {
    name: name,
    cellphone: cellphone,
    address: address,
    detail_address: detailAddress,
    address_type: convertAddressType,
    postcode_json: convertPostCodeJson,
    dispose_location_id: disposeLocationId,
    registered_location_id: registeredLocationId,
    payment_method: paymentMethod,
    payment_id: paymentId,
    item_list: itemList,
    upload_images: uploadImages,
    channel: channel,
    dispose_memo: disposeMemo,
    dispose_date: disposeDate,
    cash_receipt_type: cash_receipt_type,
    cash_receipt_number: cash_receipt_number,
  };

  const response = await axiosServices({
    method: POST,
    url: url,
    data: params,
  });

  return response.data;
};

/* response 예시
{
    "payment_account_id": "f0614336-a0ae-4d40-9065-4b856bab060f",
    "payment_method_id": "1f5c2484-be9c-4fc9-ab34-16d148ba5f9a",
    "payment_id": "53a5222a-519f-428d-9dfc-d943c5783ff4",
    "payment_type": "cert",
    "next_url": "https://backend-dev.soogobot.com/payment/1f5c2484-be9c-4fc9-ab34-16d148ba5f9a/53a5222a-519f-428d-9dfc-d943c5783ff4/cert/",
    "cert_callback_url": "https://backend-dev.soogobot.com/payment/1f5c2484-be9c-4fc9-ab34-16d148ba5f9a/53a5222a-519f-428d-9dfc-d943c5783ff4/cert/callback/",
    "finish_url": "https://backend.soogobot.com/?uid=53a5222a-519f-428d-9dfc-d943c5783ff4",
    "buyer_name": null,
    "buyer_tel": null,
    "buyer_email": null,
    "request_data": {
        "GoodsName": "대형 생활 폐기물 배출 품목",
        "Amt": 2000,
        "TaxFreeAmt": 0,
        "MID": "nicepay00m",
        "EdiDate": "202401091412",
        "Moid": "53a5222a-519f-428d-9dfc-d943c5783ff4",
        "SignData": "afb34610fea141d03f822bc44f928d199dbfecca6ae4ab782f2623f9679ed127",
        "BuyerName": null,
        "BuyerTel": null,
        "PayMethod": "CARD",
        "BuyerEmail": null,
        "CharSet": "utf-8"
    },
    "cert_response_data": null,
    "approve_response_data": null,
    "status": "pc"
}

*/

const postPaymentRequest = async (
  payment_method_id,
  amount,
  buyerName,
  buyerTel,
  isMobile
) => {
  const requestStore = useRequestStore();
  const url = `/payment/${payment_method_id}/request/`;

  const payemntAccountId = requestStore.paymentAccountId;
  const paymentAccountSecretKey = requestStore.paymentAccountSecretKey;

  const response = await axiosServices({
    method: POST,
    url: url,
    headers: {
      "X-Chunil-Payment-Id": payemntAccountId,
      "X-Chunil-Payment-Secret": paymentAccountSecretKey,
    },
    data: {
      amount: amount,
      app: "zguhada",
      item_title: "대형 생활 폐기물 배출 품목",
      is_mobile: isMobile,
      tax_free: amount, // 대폐 비과세라 amount 같게.
      buyer_name: buyerName,
      buyer_tel: buyerTel,
      // finish_url: "https://backend.soogobot.com/",
    },
  });

  return response.data;
};

const getUserServiceHistory = async () => {
  const url = `/zguhada/user/service/history/`;

  const response = await axiosServices({
    method: GET,
    url: url,
  });

  return response.data;
};

const getUserServiceCancelHistory = async () => {
  const url = `/zguhada/user/service/history/`;

  const response = await axiosServices({
    method: GET,
    url: url,
    params: {
      cancel_yn: 1, // 취소가 1 ,
    },
  });

  return response.data;
};

const postUserServiceCancel = async (
  id,
  serviceType,
  cancelReasonCode,
  cancelReasonDetail,
  refundBank,
  refundAccountNumber,
  refundAccountHolder
) => {
  const url = `/zguhada/user/service/cancel/`;

  const response = await axiosServices({
    method: POST,
    url: url,
    data: {
      id: id,
      service_type: serviceType,
      // blw_dispose_report_cancel_reason: cancelReasonCode,
      // blw_dispose_report_cancel_reason_detail: cancelReasonDetail,
      // blw_dispose_report_refund_bank: refundBank,
      // blw_dispose_report_refund_account_number: refundAccountNumber,
      // blw_dispose_report_refund_account_holder: refundAccountHolder,
    },
  });

  return response.data;
};

// 계좌이체 환불계좌가 필요할때
const postUserServiceCancelMb = async (
  id,
  serviceType,
  refundBankId,
  refundAccountNumber,
  refundAccountHolder
) => {
  const url = `/zguhada/user/service/cancel/`;

  const response = await axiosServices({
    method: POST,
    url: url,
    data: {
      id: id,
      service_type: serviceType,
      blw_dispose_report_refund_bank: refundBankId,
      blw_dispose_report_refund_account_number: refundAccountNumber,
      blw_dispose_report_refund_account_holder: refundAccountHolder,
    },
  });

  return response.data;
};

const getImageUploadUrl = async (extension) => {
  const url = `zguhada/blw/collectionRequest/image/upload/`;
  const response = await axiosServices({
    method: GET,
    url: url,
    params: {
      extension: extension,
    },
  });

  return response.data;
};

const putImageUpload = async (url, formData) => {
  try {
    await axios
      .put(url, formData, {
        headers: {
          "Content-Type": "image",
        },
      })
      .then((res) => {
        console.log(`res.data ${res.data}`);
      });
  } catch (error) {
    console.error("업로드 실패:", error);
    throw error;
  }
};

const postZguhadaWithdrawal = async () => {
  const url = `zguhada/withdrawal/`;
  const response = await axiosServices({
    method: POST,
    url: url,
    data: {
      withdrawal: true,
    },
  });

  return response.data;
};

const postPasswordChange = async ({ phone_auth_id, password }) => {
  const url = `zguhada/password/change/`;

  const response = await axiosServices({
    method: POST,
    url: url,
    data: {
      phone_auth_id: phone_auth_id,
      password: password,
    },
  });

  return response.data;
};

export {
  getBlwServiceGovernment,
  getBlwDisposeLocation,
  getProductCategoryList,
  getProductStandardList,
  postBlwDisposeReportRequest,
  postPaymentRequest,
  getProductCategoryListSearch,
  getUserServiceHistory,
  getUserServiceCancelHistory,
  postUserServiceCancel,
  getImageUploadUrl,
  postZguhadaWithdrawal,
  postPasswordChange,
  putImageUpload,
  postUserServiceCancelMb,
};
