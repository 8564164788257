import * as Sentry from "@sentry/browser";

const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

// yyyy-MM-dd 형식으로 받아야함.
const formatDateFullString = (date) => {
    let day = ["일", "월", "화", "수", "목", "금", "토"];

    const targetDay = new Date(date);

    const timeSeparate = targetDay.getHours() >= 12 ? "오후" : "오전";
    const time =
        targetDay.getHours() >= 12
            ? targetDay.getHours() - 12
            : targetDay.getHours();

    const hours = time.toString().padStart(2, "0");
    const minutes = targetDay.getMinutes().toString().padStart(2, "0");

    const formatDate =
        targetDay.getFullYear() +
        "년 " +
        (targetDay.getMonth() + 1) +
        "월 " +
        targetDay.getDate() +
        "일 " +
        day[targetDay.getDay()] +
        "요일 " +
        timeSeparate +
        " " +
        hours +
        ":" +
        minutes;

    return formatDate;
};

const isNumericString = (s) => {
    return /^\d+$/.test(s);
};

function validatePassword(password) {
    if (password.length < 8 || password.length > 20) {
        return false;
    }
    let conditionCount = 0;

    if (/[A-Za-z]/.test(password)) {
        conditionCount++;
    }

    if (/\d/.test(password)) {
        conditionCount++;
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        conditionCount++;
    }

    return conditionCount >= 2;
}

// 공백 체크
const validateEmptySpace = (str) => {
    if (/[\s]/g.test(str) == true) {
        return false;
    }
}

/*
  ###.####.####(. 빼고) 의 형식이어서 그냥 11글자로 체크하는 로직 구현
*/
const validatePhoneNumber = (phoneNumber) => {
    // logic 고도화
    let value = extractNumbers(phoneNumber.toString())
    if (value.length !== 11) {
        return false;
    }
    if (value.substring(0, 3) !== '010') {
        return false;
    }
    return true;
};

const validateAuthCode = (authCode) => {
    return authCode.length === 6;
};

/* sentry log 처리 */
function sendErrorLogToSentry(error) {
    Sentry.captureException(error);
}

const extractNumbers = (str) => {
    const matches = str.match(/[0-9]/g);
    return matches ? matches.join('') : '';
}

const formatPhoneNumber = (phoneNumber) => {
    let value = extractNumbers(phoneNumber.toString())
    if (phoneNumber.toString().length !== 11) {
        return value;
    } else {
        return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
}

const formatBrn = (brnNumber) => {
    let value = extractNumbers(brnNumber.toString())
    if (value.length !== 10) {
        return value;
    } else {
        return value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    }
}
export {
    isNumericString,
    validatePassword,
    formatDate,
    formatDateFullString,
    validatePhoneNumber,
    validateAuthCode,
    sendErrorLogToSentry,
    extractNumbers,
    formatPhoneNumber,
    formatBrn
};
