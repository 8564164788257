import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import {
  postLogin,
  postLoginToken,
  postRefresh,
  postRegister2,
} from "@/api/auth";
import { postZguhadaWithdrawal } from "@/api/request";
import router from "@/router/index";
import Cookies from "js-cookie";

const { VITE_COOKIE_DOMAIN, VITE_COOKIE_SECURE } = import.meta.env;

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    access: useSessionStorage("accessToken", null),
    refresh: useSessionStorage("refreshToken", null),
    loginUserName: useSessionStorage("loginUserName", ""),
    loginUserId: useSessionStorage("loginUserId", null),
    loginUserPhoneNumber: useSessionStorage("loginUserPhoneNumber", ""),
    // 약관 인증 관련
    personalAgree: useSessionStorage("psersonalAgree", false),
    serviceAgree: useSessionStorage("serviceAgree", false),
    marketingAgree: useSessionStorage("marketingAgree", false),
    ageAgree: useSessionStorage("ageAgree", false),
    channel: useSessionStorage("channel", "zg_web"),
  }),
  actions: {
    async login(userId, password, phoneAuthId) {
      try {
        const response = await postLogin(userId, password, phoneAuthId);

        this.access = response.access;
        this.refresh = response.refresh;
        this.loginUserName = response.profile.name;
        this.loginUserId = response.profile.id;
        this.loginUserPhoneNumber = response.profile.cellphone;

        router.replace("/");
      } catch (error) {
        throw error;
      }
    },
    async regiserUser(
      phoneAuthId,
      name,
      password,
      serviceAgree,
      personalAgree,
      pushAgree,
      smsAgree,
      ageAgree
    ) {
      try {
        // const serviceAgree = this.serviceAgree;
        // const personalAgree = this.personalAgree;
        // const marketingAgree = this.marketingAgree;
        // const ageAgree = this.ageAgree;

        const response = await postRegister2(
          phoneAuthId,
          name,
          password,
          ageAgree,
          personalAgree,
          serviceAgree,
          pushAgree,
          smsAgree
        );

        this.access = response.access;
        this.refresh = response.refresh;
        this.loginUserName = response.profile.name;
        this.loginUserId = response.profile.id;
        this.loginUserPhoneNumber = response.profile.cellphone;
      } catch (error) {
        throw error;
      }
    },
    async refreshToken() {
      try {
        const response = await postRefresh(this.refresh);
        this.access = response.access;

        return response;
      } catch (error) {
        throw error;
      }
    },
    async doWithdrawal() {
      try {
        const resposne = await postZguhadaWithdrawal();

        this.clearToken();
      } catch (error) {
        throw error;
      }
    },
    clearToken() {
      this.access = null;
      this.refresh = null;
      this.loginUserName = "";
      this.loginUserId = null;
      this.loginUserPhoneNumber = null;
    },
    logout() {
      this.clearToken();
      this.removeTokenCookies();
      router.push("/");
    },
    setAgreeField(personalAgree, serviceAgree, marketingAgree, ageAgree) {
      this.personalAgree = personalAgree;
      this.serviceAgree = serviceAgree;
      this.marketingAgree = marketingAgree;
      this.ageAgree = ageAgree;
    },
    isLogin() {
      return this.access !== null;
    },
    async provideToken(access, refresh) {
      this.access = access;
      this.refresh = refresh;

      try {
        const response = await postLoginToken();

        this.loginUserName = response.name;
        this.loginUserId = response.id;
        this.loginUserPhoneNumber = response.cellphone;
      } catch (error) {
        throw error;
      }
    },
    removeTokenCookies() {
      Cookies.remove("zguhadaCookie", {
        domain: VITE_COOKIE_DOMAIN, // 공통 도메인 설정
        path: "/", // 경로 설정
        secure: VITE_COOKIE_SECURE, // HTTPS에서만 사용
        // sameSite: VITE_COOKIE_SAMESITE, // 도메인 간 사용을 허용
      });
      Cookies.remove("zguhadaAccess", {
        domain: VITE_COOKIE_DOMAIN, // 공통 도메인 설정
        path: "/", // 경로 설정
        secure: VITE_COOKIE_SECURE, // HTTPS에서만 사용
        // sameSite: VITE_COOKIE_SAMESITE, // 도메인 간 사용을 허용
      });
      Cookies.remove("zguhadaRefresh", {
        domain: VITE_COOKIE_DOMAIN, // 공통 도메인 설정
        path: "/", // 경로 설정
        secure: VITE_COOKIE_SECURE, // HTTPS에서만 사용
        // sameSite: VITE_COOKIE_SAMESITE, // 도메인 간 사용을 허용
      });
      Cookies.remove("zguhadaUser", {
        domain: VITE_COOKIE_DOMAIN, // 공통 도메인 설정
        path: "/", // 경로 설정
        secure: VITE_COOKIE_SECURE, // HTTPS에서만 사용
        // sameSite: VITE_COOKIE_SAMESITE, // 도메인 간 사용을 허용
      });
    },
  },
});
