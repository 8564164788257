<script setup>
import {ref, watch} from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: false
  },
  title: {
    type: String,
    default: '알림',
    required: false
  },
  body: {
    type: String,
    required: true
  },
  confirmText: {
    type: String,
    default: '확인',
    required: false
  },
  cancelText: {
    type: String,
    default: '취소',
    required: false
  },
})

const emits = defineEmits(['confirm', 'close'])

</script>

<template>
  <div v-if="show" class="wrapper z-60" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <div class="outer-modal">
      <div class="inner-modal-upper">
        <div class="modal-title">{{ props.title }}</div>
        <div class="modal-body">{{ props.body }}</div>
      </div>
      <div class="inner-modal-lower w-full gap-x-1">
        <button class="confirm-white-btn" type="button" @click="emits('close')">{{ props.cancelText }}</button>
        <button class="confirm-primary-btn" type="button" @click="emits('confirm')">{{ props.confirmText }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.confirm-white-btn {
  display: flex;
  width: 136px;
  height: 44px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: #B7B7B7;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.confirm-white-btn:focus {
  outline: none;
}

.confirm-primary-btn {
  display: flex;
  width: 136px;
  height: 44px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3182FF;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.confirm-primary-btn:focus {
  outline: none;
  border: 1px solid #3182FF;
}

.modal-title {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
  margin-bottom: 8px;
}

.modal-body {
  color: #555;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.48);
  overflow: hidden;
  inset: 0;
  justify-content: center;
  align-items: center;
}

.outer-modal {
  width: 316px;
}

.inner-modal-upper {
  border-radius: 16px 16px 0 0;
  background: white;
  padding: 24px 16px 0 16px;
}

.inner-modal-lower {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-radius: 0 0 16px 16px;
  background: white;
  padding: 24px 16px 24px 16px;
}
</style>