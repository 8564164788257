<script setup>
import checkRoundPrimary from "@/assets/icon/ic_round_check_primary.svg";
import checkRoundGray from "@/assets/icon/ic_round_check_gray.svg";
import { ref, computed } from "vue";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";
import { sendErrorLogToSentry } from "@/utils/utils";

const userStore = useUserStore();

const isShow = defineModel("isShow", {
  type: Boolean,
  required: true,
});

const emit = defineEmits(["closeAlert"]);

const isAgreeWithdrawal = ref(false);

//
const showWithdrawSuccessAlert = ref(false);

const withdrawSuccessAlertOkayAction = () => {
  showWithdrawSuccessAlert.value = false;
  router.push("/");
};

const withdrawSuccessAlertCancelAction = () => {
  showWithdrawSuccessAlert.value = false;
  router.push("/");
};

function onClickCloseButton() {
  emit("closeAlert");
}

function onClickChangeWithdrawalAgree() {
  isAgreeWithdrawal.value = !isAgreeWithdrawal.value;
}

async function onClickDoWithdarawalButton() {
  try {
    await userStore.doWithdrawal();

    showWithdrawSuccessAlert.value = true;
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}
</script>

<template>
  <common-alert
    id="withdrawSuccessAlert"
    :is-show="showWithdrawSuccessAlert"
    title="회원 탈퇴가 완료되었습니다."
    description=""
    @okayAction="withdrawSuccessAlertOkayAction"
    @closeAlert="withdrawSuccessAlertCancelAction"
  ></common-alert>
  <v-dialog
    v-model="isShow"
    class="alert_wrap"
    @click:outside="onClickCloseButton"
  >
    <v-card class="alert_container pa-0 ma-0" elevation="0">
      <v-container class="alert_body">
        <div class="alert_head">
          <button @click="onClickCloseButton" class="close_button">
            <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
          </button>
        </div>
        <div class="alert_content flex flex_col justify_center align_center">
          <img src="@/assets/icon/ic_warning.svg" />
          <span class="subtitle_5 color_secondary center">
            잠깐! 탈퇴하기 전에 아래 정보를 확인해 주세요.
          </span>
          <span
            class="body_2 color_caption center"
            style="word-wrap: break-word"
          >
            지구하다 회원탈퇴 시 지구하다 서비스에 탈퇴하며, 회사가 운영하는
            다른 제휴 서비스 또한 탈퇴 처리 됩니다. 회원이 보유하고 있던 포인트,
            쿠폰 및 상품권, 이용권, 정기권은 모두 소멸되며, 복구가 불가능
            합니다. 회원탈퇴 후 재가입하더라도 탈퇴 전의 회원정보, 거래정보 및
            쿠폰 등은 복구되지 않습니다. 지구하다 회원은 포인트 및 유상성 쿠폰을
            회원탈퇴 신청 전에 환불 신청하거나 소진하여야 합니다. 회원이
            환불신청 없이 자진 탈퇴 하는 경우, 회사는 포인트 및 유상성 쿠폰 등에
            대한 소멸 동의를 받습니다.
          </span>
        </div>
        <div class="content_divider"></div>
        <div
          @click="onClickChangeWithdrawalAgree"
          class="check_row cusor_point flex flex_row"
        >
          <img :src="isAgreeWithdrawal ? checkRoundPrimary : checkRoundGray" />
          <span class="subtitle_7 color_secondary">
            유의사항을 모두 확인하였으며, 회원 탈퇴 시 포인트, 쿠폰, 상품권,
            이용권, 정기권 소멸에 동의합니다.
          </span>
        </div>
        <button
          @click="onClickDoWithdarawalButton"
          :disabled="!isAgreeWithdrawal"
          class="okay_button bg_primary g0 subtitle_7"
        >
          탈퇴하기
        </button>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.alert_body {
  padding: 20px;
}
.alert_head {
  top: 0;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  background: #fff;
  z-index: 200;
}

.alert_content {
  margin-top: 10px;
  margin-bottom: 30px;
}

.alert_content img {
  width: 42px;
  height: 42px;
}

.alert_content span {
  margin-top: 10px;
}

.close_button img {
  width: 20px;
  height: 20px;
}

.check_row {
  margin-bottom: 30px;
}

.check_row img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.okay_button {
  width: 100%;
  background-color: #1e64ff;
  color: white;
  height: 40px;
  border-radius: 4px;
}
.okay_button:disabled {
  width: 100%;
  background-color: #ededed;
  color: #7a7a7a;
  height: 40px;
  border-radius: 4px;
}
</style>
