<script setup>
import { ref, computed } from "vue";
import { useRequestStore } from "@/stores/requestStore";
import { getImageUploadUrl, putImageUpload } from "@/api/request";
import { sendErrorLogToSentry } from "@/utils/utils";
import router from "@/router";

const MAX_IMAGE_COUNT = 8;

const requestStore = useRequestStore();

/*
  {
    imageUid: response.uid,
    imageUrl: url link,
    file: file,
    extension: extension,
  }
*/
const disposeImageList = ref([]);
const disposeImageCount = computed(() => disposeImageList.value.length);

const isValidRequest = computed(() => {
  return requestStore.itemList.length > 0;
});

const navigatorItemTitleField = computed(() => {
  return requestStore.getNavigatorItemTitle();
});

// function

function onClickAddItem() {
  router.push("/blw-search-item");
}

function onClickNextButton() {
  requestStore.imageList = disposeImageList.value;
  router.push("/blw-check-info");
}

function onClickRemoveItem(targetItemId) {
  requestStore.removeItemFormList(targetItemId);
}

function onClickChangeQuantityButton(isPlus, itemId) {
  requestStore.changeQuantityButton(isPlus, itemId);
}

function onClickPreviousButton() {
  // router.go(-1);
  history.back();
}

// image 관련

function onClickDeleteImage(index) {
  if (confirm("해당 사진을 삭제하겠습니까?")) {
    disposeImageList.value.splice(index, 1);
  }
}

function onClickAddImage(event) {
  if (disposeImageList.value.length + 1 > MAX_IMAGE_COUNT) {
    alert("이미지는 최대 8장 까지 업로드 가능합니다.");
    return;
  }

  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    createImagePreview(file);
  }
}

async function createImagePreview(file) {
  const reader = new FileReader();

  reader.onload = async (e) => {
    const extension = file.name.split(".").pop().toLowerCase();

    try {
      const response = await requestGetImageUploadUrl(extension, file);

      disposeImageList.value.push({
        imageUid: response.uid,
        imageUrl: response.download_url,
        file: file,
        extension: extension,
      });
    } catch (error) {
      alert(
        `이미지 업로드 중 오류가 발생했습니다. 이미지를 다시 업로드해주세요.\n${error.message}`
      );
      sendErrorLogToSentry(error);
      throw error;
    }
  };
  reader.readAsDataURL(file);
}

function setPrevData() {
  if (requestStore.imageList.length > 0) {
    disposeImageList.value = requestStore.imageList;
  }
}

// api request
async function requestGetImageUploadUrl(extension, file) {
  try {
    const response = await getImageUploadUrl(extension);

    const imageUid = response.uid;
    const upload_url = response.upload_url;

    const uploadResponse = await putImageUpload(upload_url, file);

    return response;
  } catch (error) {
    sendErrorLogToSentry(error);
    throw error;
  }
}
</script>

<template>
  <div id="pc_Service_03">
    <div class="page_container flex flex_col justify_start align_center">
      <div class="single-content-page-wrap">
        <!-- flex flex_col justify_start align_end -->
        <div class="content_layout flex flex_row justify_end align_start">
          <!-- 좌측 레이어 -->
          <div class="left_wrap">
            <p class="caption_1 color_secondary left page_sub_title">
              대형 생활 폐기물
            </p>
            <p class="subtitle_3 left page_title">배출신고</p>
            <!-- step -->
            <div class="tag_wrap flex flex_row justify_start align_center">
              <span class="tag body_3 flex justify_center align_center"
                >배출일 선택</span
              >
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">" />
              <span class="tag body_3 flex justify_center align_center">
                배출정보 입력
              </span>
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">" />
              <span class="tag active body_3 flex justify_center align_center"
                >배출품목 선택</span
              >
            </div>

            <p class="mb-10 subtitle_7 color_secondary left">
              폐기물 배출 품목을 선택해 주세요.
            </p>

            <!-- 배출 품목 list -->
            <p class="sub_title label_2 color_secondary left">
              배출 품목&nbsp;({{ requestStore.itemList.length }})
            </p>
            <div class="flex flex_col product_list">
              <div
                v-for="item in requestStore.itemList"
                class="product flex flex_col justify_start align_start">
                <button
                  @click="() => onClickRemoveItem(item.id)"
                  class="close_button"
                  style="align-self: flex-end">
                  <img src="@/assets/icon/ic_delete.svg" alt="닫기" />
                </button>

                <p
                  class="product_title caption_1 color_secondary left ellipsis">
                  {{ item.categoryName }}
                </p>
                <p class="body_3 color_caption left ellipsis">
                  {{ item.name }}
                </p>

                <div class="product_item_box_01">
                  <div class="number_input_wrap align_center justify_center">
                    <button
                      class="minus"
                      @click="
                        () => onClickChangeQuantityButton(false, item.id)
                      ">
                      <img src="@/assets/icon/ic_minus.svg" alt="-" />
                    </button>
                    <span
                      class="caption_1 color_secondary center"
                      style="width: 30px">
                      {{ item.quantity }}
                    </span>
                    <button
                      class="plus"
                      @click="() => onClickChangeQuantityButton(true, item.id)">
                      <img src="@/assets/icon/ic_plus.svg" alt="+" />
                    </button>
                  </div>
                  <p class="caption_1 color_primary right" style="flex-grow: 1">
                    {{ $formatNumber(item.price * item.quantity) }}
                    <span class="caption_1 color_secondary"> 원 </span>
                  </p>
                </div>
              </div>
            </div>

            <!-- 배출 품목 추가하기 -->
            <div
              class="area_add_product flex flex_row justify_center align_center cusor_point"
              @click="onClickAddItem">
              <img src="@/assets/icon/ic_small_add.svg" alt="+" />
              <span class="subtitle_7 color_caption center"
                >배출 품목 추가</span
              >
            </div>

            <!-- 배출 품목 이미지 -->
            <div class="sub_title label_2 color_secondary left">
              배출 품목 이미지&nbsp;({{ disposeImageCount }}/{{
                MAX_IMAGE_COUNT
              }})
            </div>

            <div class="image_container">
              <!-- 이미지 추가 버튼 -->
              <input
                id="upload-image"
                type="file"
                ref="fileInput"
                @change="onClickAddImage"
                accept="image/*"
                hidden />
              <label for="upload-image">
                <div
                  class="area_add_image flex flex_col justify_center align_center"
                  style="cursor: pointer">
                  <img src="@/assets/icon/ic_camera.svg" alt="이미지 추가" />
                  <span class="subtitle_7 color_caption center"
                    >이미지 추가</span
                  >
                </div>
              </label>

              <!-- 이미지 칸 -->
              <template v-for="(imageItem, idx) in disposeImageList">
                <div class="image_item_container">
                  <img
                    class="image_item"
                    :src="imageItem.imageUrl"
                    alt="이미지" />
                  <div class="image_item_dim"></div>
                  <button
                    class="delete_button"
                    @click="() => onClickDeleteImage(idx)">
                    <img
                      class="x_icon"
                      src="@/assets/icon/ic_delete.svg"
                      alt="닫기" />
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 하단 버튼 -->
        <div class="content_layout flex flex_row justify_end align_start">
          <div class="left_wrap">
            <div class="flex justify_center align_center">
              <button
                id="next_button"
                class="prev_button label_1 g200"
                @click="onClickPreviousButton">
                이전
              </button>
              <button
                @click="onClickNextButton"
                :disabled="!isValidRequest"
                class="next_button label_1">
                다음
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#pc_Service_03 .single-content-page-wrap {
  padding-top: 60px;
  padding-bottom: 100px;
}
#pc_Service_03 .product_list {
  width: 100%;
  gap: 14px;
}
#pc_Service_03 .product_list .product {
  width: 100%;
  border: 1px solid #e7ecf4;
  border-radius: 4px;
  padding: 10px;
}
.product_list .product .product_title {
  margin-bottom: 4px;
}

.product_list .product .img_wrap {
  margin-top: 10px;
  gap: 4px;
}
.product .img_wrap .img_box {
  width: 46px;
  height: 46px;
  border: 1px solid #7c8fac;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.product .img_wrap .img_box img {
  width: 16px;
  height: 16px;
}
.product .img_wrap .img_box:hover {
  background-color: #f9f9f9;
}

.product_list .product .product_badge {
  margin-bottom: 12px;
  width: 75px;
  height: 30px;
  border: 1px solid #c1c8d4;
  border-radius: 4px;
}

.product_list .product .close_button {
  width: 17px;
  height: 17px;
  margin-bottom: 10px;
}
.product_list .product .close_button:hover {
  opacity: 0.8;
}

.sub_title {
  padding-bottom: 4px;
}

#pc_Service_03 .area_add_product {
  width: 100%;
  min-height: 50px;
  background-color: #f3f6fa;
  border-radius: 4px;
  border: 1px #c1c8d4 solid;
  gap: 3px;
  margin-bottom: 30px;
  margin-top: 4px;
}
#pc_Service_03 .area_add_product img {
  width: 21px;
  height: 21px;
}
/* .image-item {
  width: 106px;
  height: 106px;;
} */

#pc_Service_03 .area_add_image {
  min-height: 109px;
  min-width: 109px;
  border-radius: 4px;
  background-color: #f3f6fa;
  gap: 4px;
  border: 1px #c1c8d4 solid;
}
#pc_Service_03 .image_item_container {
  min-height: 106px;
  min-width: 106px;
  max-height: 106px;
  max-width: 106px;
}
#pc_Service_03 .image_item_container .image_item {
  border-radius: 4px;
  object-fit: contain;
}

#pc_Service_03 .image_item_container .image_item_dim {
  width: 106px;
  height: 106px;
  border-radius: 4px;
  background-color: black;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.image_item_container .delete_button {
  position: absolute;
  top: 6px;
  right: 6px;
}

.image_item_container .delete_button:hover {
  opacity: 0.8;
}

#pc_Service_03 .area_add_image img {
  width: 21px;
  height: 21px;
}

#pc_Service_03 .image_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
  gap: 10px;
  margin-bottom: 60px;
}

#pc_Service_03 .product_item_box_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.prev_button {
  width: 98px;
}

.next_button {
  width: 100%;
  color: white;
  background-color: #1e64ff;
  border-radius: 4px;
  height: 50px;
  margin-left: 6px;
}
.next_button:disabled {
  width: 100%;
  color: #7a7a7a;
  background-color: #ededed;
  border-radius: 4px;
  height: 50px;
  margin-left: 10px;
}
</style>
