<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper/modules";
import QrCodeGuidePopup from "@/components/Popup/QrCodeGuidePopup.vue";
import DownloadModal from "@/components/DownloadModal.vue";
import "swiper/css";
import router from "@/router/index.js";
import GooglePlayIcon from "@/assets/icon/ic_googleplay_color.svg";
const mainContainer = ref();
const showDownloadModal = ref(false);
const floatingButtonColor = ref("unset");
const floatingButtonTextColor = ref("white");
const floatingButtonColorChangeHeight = ref(0);
const isMobile = ref(false);

const showQrCodeGuidePopup = ref(false);

// function

function clickAppstore() {
  window.open("https://apps.apple.com/app/id6459451210", "_blank");
}

function clickPlaystore() {
  window.open(
    "https://play.google.com/store/apps/details?id=com.chunil.zguhada.platform.app.zguhada_platform",
    "_blank"
  );
}

function clickQrCode() {
  showQrCodeGuidePopup.value = true;
}

function onClickCloseQrCodeGuidePopup() {
  showQrCodeGuidePopup.value = false;
}

function onClickTelButton() {
  document.location.href = "tel:1660-3114";
}

function onClickRequestButton() {
  showDownloadModal.value = true;
}

function onClickCloseDownloadModal() {
  showDownloadModal.value = false;
}

function pushQuick() {
  router.push("quick-quote-default");
}

function handelScroll() {
  if (!isMobile.value) {
    if (window.innerHeight <= 1080) {
      floatingButtonTextColor.value =
        window.scrollY > floatingButtonColorChangeHeight.value
          ? "#1E64FF"
          : "white";
    } else {
      floatingButtonTextColor.value = "#1E64FF";
    }
  }
}

function initScrollEvent() {
  floatingButtonColorChangeHeight.value =
    window.innerHeight - mainContainer.value.clientHeight + 20;
  isMobile.value = window.innerWidth <= 992;

  // console.log("initScrollEvent", window.innerHeight, mainContainer.value.clientHeight, floatingButtonColorChangeHeight.value, isMobile.value)

  if (isMobile.value) {
    floatingButtonTextColor.value = "#1E64FF";
  } else {
    floatingButtonTextColor.value = "white";
    if (window.innerHeight > 1080) {
      floatingButtonTextColor.value = "#1E64FF";
    } else {
      floatingButtonTextColor.value = "white";
    }
  }
  handelScroll();
  window.addEventListener("scroll", handelScroll);
}

onMounted(() => {
  isMobile.value = window.innerWidth <= 992;
  initScrollEvent();
  window.addEventListener("resize", initScrollEvent);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handelScroll);
  window.removeEventListener("resize", initScrollEvent);
});
</script>

<template>
  <div>
    <DownloadModal
      :show-dialog="showDownloadModal"
      @closeDialog="onClickCloseDownloadModal"
    ></DownloadModal>
    <qr-code-guide-popup
      :show-popup="showQrCodeGuidePopup"
      @hidePopup="onClickCloseQrCodeGuidePopup"
    ></qr-code-guide-popup>
    <div
      class="floating-button flex"
      :style="{
        backgroundColor: floatingButtonColor,
        color: floatingButtonTextColor,
      }"
      @click="pushQuick"
    >
      <div
        style="
          width: 58px;
          height: 58px;
          border-radius: 50%;
          background-color: #1e64ff;
          margin-right: 14px;
          display: flex;
        "
      >
        <img
          src="../assets/icon/icon_service.svg"
          alt="서비스 아이콘"
          style="width: 40px; height: 40px; margin: auto"
        />
      </div>
      <div style="margin-right: 14px; align-self: center">
        <div class="text-start title">폐업철거 지원받기</div>
        <div class="text-start description">클릭해서 문의하기</div>
      </div>
    </div>
    <!-- <header class="flex flex-col justify_center align_center bg_white">
      <div
        class="header_wrap flex justify_space_between align_center"
        @click="onClickCloseDownloadModal"
      >
        <img
          class="logo only_pc_block"
          src="../assets/logo.svg"
          alt="지구하다 로고"
        />
        <img
          class="logo only_mo_block"
          src="../assets/logo_white.svg"
          alt="지구하다 로고"
        />
        <div class="flex justify_end align_center" v-show="false">
          <button
            type="button"
            class="header_button only_pc_block text_20 mid center"
          >
            로그인
          </button>
          <button
            type="button"
            class="header_button only_pc_block text_20 bold center blue_grey bg_blue_grey_light border_r100"
          >
            신청하기
          </button>
          <button type="button" class="menu_button only_mo_block">
            <img src="../assets/icon/ic_menu_black.png" alt="메뉴 아이콘" />
          </button>
        </div>
        <div class="top_nav_list bg_white flex flex_col justify_start align_start">
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_user.png" alt="내 정보" />
                <p class="text_14 mid left">내정보</p>
            </button>
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_history.png" alt="신청 내역" />
                <p class="text_14 mid left">신청 내역</p>
            </button>
            <button class="top_nav flex justify_start align_center">
                <img src="../assets/icon/ic_logout.png" alt="로그아웃" />
                <p class="text_14 mid left">로그아웃</p>
            </button>
        </div>
      </div>
    </header> -->

    <div>
      <!-- main -->
      <section id="main" class="bg_blue">
        <!-- ㅡmain_bg -->
        <img
          class="img_earth only_pc_block"
          src="../assets/image/img_earth.svg"
          alt="지구이미지"
        />
        <img
          class="img_earth only_mo_block"
          src="../assets/image/img_earth_mo.svg"
          alt="지구이미지"
        />

        <!-- main_container -->
        <div
          class="main_container flex justify_center align_center"
          ref="mainContainer"
        >
          <div
            class="main_title_wrap flex flex_row justify_space_between align_center"
          >
            <div style="width: 100%">
              <!-- pc -->
              <div class="only_pc_block">
                <h2 class="head_1 g0 left animation fadeUp">
                  지구를 구하는 <br />가장 쉬운 방법
                </h2>
                <p class="subtitle_6 g0 left animation fadeUp delay_2">
                  <b class="only_pc_inline">폐기물 처리 No.1 플랫폼 </b>
                  지구하다와 함께 <br />올바른폐기 문화를 만들어요.
                </p>

                <div
                  class="down_button_wrap flex flex_row justify_start align_center animation fadeUp delay_3"
                >
                  <button
                    v-on:click="clickPlaystore()"
                    class="route_button g0 center flex flex_row justify_center align_center"
                  >
                    <img
                      :src="GooglePlayIcon"
                      alt="googlePlay"
                    />
                    <div class="flex flex_col justify_start align_start">
                      <p class="subtitle_7 g0">GooglePlay</p>
                      <p class="body_4 g0">이동해서 다운로드하기</p>
                    </div>
                  </button>
                  <button
                    v-on:click="clickAppstore()"
                    class="route_button g0 center flex flex_row justify_center align_center"
                  >
                    <img src="@/assets/icon/ic_appstore.svg" alt="googlePlay" />
                    <div class="flex flex_col justify_start align_start">
                      <p class="subtitle_7 g0">App Store</p>
                      <p class="body_4 g0">이동해서 다운로드하기</p>
                    </div>
                  </button>
                  <button
                    v-on:click="clickQrCode()"
                    class="route_button g0 center flex flex_row justify_center align_center"
                  >
                    <img src="@/assets/icon/ic_qrcode.svg" alt="googlePlay" />
                    <div class="flex flex_col justify_start align_start">
                      <p class="subtitle_7 g0">QR Code</p>
                      <p class="body_4 g0">이동해서 다운로드하기</p>
                    </div>
                  </button>
                </div>
              </div>
              <!-- mobile -->
              <div class="only_mo_block">
                <h2 class="subtitle_1 g0 left animation fadeUp">
                  지구를 <br class="only_mo_block" />구하는 <br />가장<br
                    class="only_mo_block"
                  />
                  쉬운 방법
                </h2>
                <p class="body_2 g0 left animation fadeUp delay_2">
                  지구하다와 함께 <br />올바른 폐기 문화를 만들어요.
                </p>

                <div
                  class="down_button_wrap flex flex_row justify_start align_center animation fadeUp delay_3"
                >
                  <button
                    v-on:click="clickPlaystore()"
                    class="route_button g0 center flex flex_row justify_center align_center"
                  >
                    <img
                      src="@/assets/icon/ic_googleplay_color.svg"
                      alt="googlePlay"
                    />
                    <div class="flex flex_col justify_start align_start">
                      <p class="subtitle_7 g0">GooglePlay</p>
                      <p class="body_4 g0">이동해서 다운로드하기</p>
                    </div>
                  </button>
                  <button
                    v-on:click="clickAppstore()"
                    class="route_button g0 center flex flex_row justify_center align_center"
                  >
                    <img src="@/assets/icon/ic_appstore.svg" alt="googlePlay" />
                    <div class="flex flex_col justify_start align_start">
                      <p class="subtitle_7 g0">App Store</p>
                      <p class="body_4 g0">이동해서 다운로드하기</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <img
              class="img_hero animation fadeUp delay_2"
              src="../assets/image/img_hero.svg"
              alt="히어로이미지"
            />
          </div>
        </div>
      </section>

      <!-- section1 -->
      <section id="section1" class="main_section">
        <div
          class="main_section_wrap flex flex_col justify_center align_center"
        >
          <p
            class="center animation fadeUp"
            :class="isMobile ? 'subtitle_3' : 'head_2'"
          >
            이제,
            <span
              class="color_primary"
              :class="isMobile ? 'subtitle_3' : 'head_2'"
              >폐기물 처리</span
            >가 쉬워집니다.
          </p>
          <p
            class="color_caption center animation fadeUp delay_2"
            :class="isMobile ? 'subtitle_7' : 'subtitle_4'"
          >
            언제 어디서나, 어떤 폐기물이든<br class="only_mo_block" />간편하게
            입력하고 배출하면 끝!
          </p>

          <swiper
            :loop="true"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
            }"
            :modules="[Autoplay]"
            :speed="5000"
            :allow-touch-move="false"
            :no-swiping="true"
            :loopAdditionalSlides="true"
            :slides-per-view="1"
            :space-between="18"
            :centered-slides="true"
            :breakpoints="{
              800: {
                slidesPerView: 4,
                spaceBetween: 18,
              },
              1080: {
                slidesPerView: 7,
                spaceBetween: 18,
              },
            }"
          >
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_01.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_02.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_03.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_04.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_05.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_06.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_07.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_08.svg"
                class="slider_image"
              />
            </swiper-slide>
            <swiper-slide>
              <img
                src="../assets/rolling_category/img_category_09.svg"
                class="slider_image"
              />
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <!-- section2 -->
      <section id="section2" class="main_section">
        <div
          class="main_section_wrap flex flex_row justify_center align_center"
        >
          <div class="left_wrap flex flex_col justify_center align_center">
            <img
              class="img_main_section"
              src="../assets/image/img_main_section2.svg"
              alt="수거신청_위치선택"
            />
          </div>
          <div class="right_wrap flex flex_col justify_center align_center">
            <div class="section_desc">
              <!-- pc -->
              <div class="only_pc_block">
                <span
                  class="subtitle_4 color_primary left animation fadeDown delay_3 grid_left"
                  >배출신고</span
                >
                <p class="head_2 extra_bold left animation fadeUp">
                  전화•방문 없이<br />간편하게 신청해요!
                </p>
                <p class="subtitle_4 left g300 animation fadeUp delay_2">
                  언제 어디서나 간편하게 신청하고 원하는 시간에 배출하세요.
                </p>

                <div
                  class="btn_more_wrap flex flex_col justify_start align_center"
                  style="gap: 14px"
                >
                  <div
                    class="flex flex_row justify_start align_center more_container"
                    style="width: 100%; gap: 14px"
                  >
                    <div class="ic_wrap">
                      <img src="@/assets/icon/ic_sticker.svg" />
                    </div>
                    <span class="subtitle_6"> 대형 생활폐기물 스티커 </span>
                  </div>
                  <div
                    class="flex flex_row justify_start align_center more_container"
                    style="width: 100%; gap: 14px"
                  >
                    <div class="ic_wrap">
                      <img src="@/assets/icon/ic_document.svg" />
                    </div>
                    <span class="subtitle_6">
                      공사장 생활폐기물 배출신고제
                    </span>
                  </div>
                </div>
              </div>

              <!-- mobile -->
              <div class="only_mo_flex flex_col justify_center align_start">
                <span
                  class="subtitle_7 color_primary left animation fadeDown delay_3 grid_left"
                  >배출신고</span
                >
                <p class="subtitle_3 g900 left animation fadeUp">
                  전화•방문 없이<br />간편하게 신청해요!
                </p>
                <p
                  class="subtitle_7 color_caption left animation fadeUp delay_2"
                >
                  언제 어디서나 간편하게 신청하고<br />원하는 시간에 배출하세요.
                </p>

                <div
                  class="btn_more_wrap flex flex_col justify_start align_center"
                  style="gap: 14px"
                >
                  <div
                    class="flex flex_row justify_start align_center more_container"
                    style="width: 100%; gap: 14px"
                  >
                    <div class="ic_wrap">
                      <img src="@/assets/icon/ic_sticker.svg" />
                    </div>
                    <span class="subtitle_7"> 대형 생활폐기물 스티커 </span>
                  </div>
                  <div
                    class="flex flex_row justify_start align_center more_container"
                    style="width: 100%; gap: 14px"
                  >
                    <div class="ic_wrap">
                      <img src="@/assets/icon/ic_document.svg" />
                    </div>
                    <span class="subtitle_7">
                      공사장 생활폐기물 배출신고제
                    </span>
                  </div>
                </div>

                <img
                  class="mo_img_section"
                  src="../assets/image/img_section_02.png"
                  alt="신고가 접수되었어요! 팝업 이미지"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- section3 -->
      <section id="section3" class="main_section">
        <div
          class="main_section_wrap flex flex_row justify_center align_center"
        >
          <div class="left_wrap flex flex_col justify_center align_center">
            <div class="section_desc">
              <div class="only_pc_block">
                <span
                  class="text_22 primary semi_bold left animation fadeDown delay_3 grid_left"
                  >방문수거</span
                >
                <p class="text_50 extra_bold left animation fadeUp">
                  무거운 폐기물,<br />혼자 버리기 힘들다면?
                </p>
                <p class="text_20 mid left gray5 animation fadeUp delay_2">
                  저렴한 비용으로 알아서 옮겨주고<br />올바른 과정으로 처리해
                  드려요.
                </p>

                <div
                  class="btn_more_wrap flex flex_row justify_start align_center"
                >
                  <button
                    class="btn_more text_16 center mid border_r14"
                    style="display: none"
                  >
                    더 알아보기
                  </button>
                </div>
              </div>

              <div class="only_mo_block">
                <span
                  class="subtitle_7 color_primary left animation fadeDown delay_3 grid_left"
                  >방문수거</span
                >
                <p class="subtitle_3 g900 left animation fadeUp">
                  무거운 폐기물,<br />혼자 버리기 힘들다면?
                </p>
                <p
                  class="subtitle_7 color_caption left animation fadeUp delay_2"
                >
                  저렴한 비용으로 알아서 옮겨주고<br />올바른 과정으로 처리해
                  드려요.
                </p>

                <img
                  class="mo_img_section"
                  style="
                    display: flex;
                    justify-content: center;
                    align-content: center;
                  "
                  src="../assets/image/img_section_03.png"
                  alt="방문수거 배출신고 선택 이미지"
                />
              </div>
            </div>
          </div>
          <div class="right_wrap flex flex_col justify_center align_center">
            <img
              class="img_main_section"
              src="../assets/image/img_main_section3.svg"
              alt="대생폐 배출방식_배출신고"
            />
          </div>
        </div>
      </section>

      <!-- section4 -->
      <section id="section4" class="main_section">
        <div
          class="main_section_wrap flex flex_row justify_center align_center"
        >
          <div class="left_wrap flex flex_col justify_center align_center">
            <!-- pc image  -->
            <img
              class="img_main_section only_pc_block"
              src="../assets/image/img_main_section4.svg"
              alt="집하장_찾기"
            />

            <!-- mobile image  -->
            <img
              class="img_main_section only_mo_block"
              src="../assets/image/img_section_04.png"
              alt="집하장_찾기"
            />
          </div>
          <div class="right_wrap flex flex_col justify_center align_center">
            <div class="section_desc">
              <!-- pc -->
              <div class="only_pc_block">
                <span
                  class="text_22 primary semi_bold left animation fadeDown delay_3 grid_left"
                  >집하장 찾기</span
                >
                <p class="text_50 extra_bold left animation fadeUp">
                  처리비용, 여기가<br />가장 저렴해요!
                </p>
                <p class="text_20 mid left gray5 animation fadeUp delay_2">
                  내 근처의 모든 집하장 정보를 미리 확인하고
                  <br class="only_pc_block" />처리비용을 절약해 보세요.
                </p>
              </div>

              <!-- mobile -->
              <div class="only_mo_block">
                <span
                  class="subtitle_7 color_primary left animation fadeDown delay_3 grid_left"
                  >집하장 찾기</span
                >
                <p class="subtitle_3 g900 left animation fadeUp">
                  처리비용, 여기가<br />가장 저렴해요!
                </p>
                <p
                  class="subtitle_7 color_caption left animation fadeUp delay_2"
                >
                  내 근처의 모든 집하장 정보를 미리
                  <br />확인하고 처리비용을 절약해 보세요.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- section5 -->
      <section id="section5" class="main_section">
        <div
          class="main_section_wrap flex flex_col justify_center align_center"
        >
          <!-- pc -->
          <div class="only_pc_flex flex_col justify_center align_center">
            <p class="head_2 color_primary center animation fadeUp">
              폐기물 처리비<br />
              <span class="head_2 g900 center"> 절감율이 다릅니다. </span>
            </p>
            <p class="subtitle_4 color_caption center">
              수집•운반부터 처리까지 원스톱 솔루션으로<br />시간과 비용을
              혁신적으로 절감할 수 있어요.
            </p>

            <img
              class="img_main_section5 only_pc_block"
              src="../assets/image/img_main_section5.svg"
              alt="철거,수집운반,집하,중간처리,최종처리"
            />

            <!-- <button
              class="main_button text_20 mid center white bg_primary border_r14 animation fadeUp delay_2"
              v-on:click="onClickRequestButton"
            >
              지금 신청하기
            </button> -->
          </div>

          <!-- mobile -->
          <div class="only_mo_block">
            <p class="subtitle_3 color_primary center animation fadeUp">
              폐기물 처리비<br />
              <span class="subtitle_3 g900 center"> 절감율이 다릅니다. </span>
            </p>
            <p class="subtitle_7 color_caption center">
              수집•운반부터 처리까지 원스톱 솔루션으로<br />시간과 비용을
              혁신적으로 절감할 수 있어요.
            </p>

            <img
              class="img_main_section5 only_pc_block"
              src="../assets/image/img_main_section5.svg"
              alt="철거,수집운반,집하,중간처리,최종처리"
            />
            <img
              class="img_main_section5 only_mo_block"
              src="../assets/image/img_section_05.svg"
              alt="철거,수집운반,집하,중간처리,최종처리"
            />
          </div>
        </div>
      </section>

      <!-- section6 -->
      <section id="section6" class="main_section">
        <div
          class="main_section_wrap flex flex_col justify_center align_center"
        >
          <!-- pc -->
          <div class="only_pc_flex flex_col justify_center align_center">
            <p class="head_2 color_primary center">
              서비스 이용<span class="head_2 g900"
                >이<br />
                처음이라 고민이세요?</span
              >
            </p>
            <p class="subtitle_4 color_caption center">
              상담사가 더 쉽고 편리하게<br />이용할 수 있도록 도와드릴게요.
            </p>
            <div
              class="call_button flex flex_row justify_center align_center animation fadeUp delay_2"
              @click="onClickTelButton"
            >
              <img src="@/assets/icon/ic_phone_primary.svg" />
              <span class="subtitle_3 color_primary"> 1660-3114 </span>
            </div>
          </div>

          <!-- mobile -->
          <div class="only_mo_block">
            <p class="subtitle_3 color_primary center">
              서비스 이용<span class="subtitle_3 g900"
                >이<br />
                처음이라 고민이세요?</span
              >
            </p>
            <p class="subtitle_7 color_caption center">
              상담사가 더 쉽고 편리하게<br />이용할 수 있도록 도와드릴게요.
            </p>
            <div
              class="call_button flex flex_row justify_center align_center animation fadeUp delay_2"
              @click="onClickTelButton"
            >
              <img src="@/assets/icon/ic_phone_primary.svg" />
              <span class="subtitle_5 color_primary"> 1660-3114 </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style>
.swiper {
  width: 100%;
  height: 274px; /* 원하는 높이에 맞게 조정 */
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 이미지가 슬라이드를 꽉 채우도록 설정 */
}
</style>

<style scoped>
#main {
  width: 100%;
  height: calc(100vh - 80px);
  max-height: 990px;
  position: relative;
  overflow: hidden;
}

button.main_button {
  width: 210px;
  height: 68px;
}

.route_button {
  height: 35px;
}
.route_button img {
  max-width: 24px;
  max-height: 24px;
  margin-right: 6px;
}

/* main */
#main .img_earth {
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 20vw;
  height: auto;
  min-width: 200px;
  max-width: 330px;
}

#main .main_container {
  width: 100%;
  height: 100%;
}

#main .main_container .main_title_wrap {
  width: 80vw;
  /* width: 58.33vw; */
  min-width: 600px;
  max-width: 1120px;
}

.main_title_wrap h2 {
  margin-top: 0px;
  margin-bottom: 16px;
}

.main_title_wrap p.text_20 {
  line-height: 36px;
}

.main_title_wrap .down_button_wrap {
  margin-top: 60px;
  gap: 20px;
}

.main_title_wrap .down_button_wrap button img {
  width: 29px;
  height: auto;
}

.main_title_wrap .img_hero {
  width: 43.75%;
  min-width: 250px;
  max-width: 490px;
  height: auto;
}

/* main_section 공통 */
.main_section {
  width: 100%;
  height: 1040px;
  background: #fafafa;
  padding-bottom: 20px;
}

.main_section:last-of-type {
  height: 1000px;
  padding-bottom: 0px;
}

.main_section .main_section_wrap {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.main_section .main_section_wrap .img_main_section {
  width: 360px;
  height: auto;
}

.main_section .main_section_wrap p.text_20 {
  line-height: 38px;
}

.main_section .main_section_wrap p.text_50 {
  margin-top: 16px;
  margin-bottom: 20px;
}

.main_section .main_section_wrap p.subtitle_4 {
  margin-top: 20px;
  margin-bottom: 120px;
}

.main_section .main_section_wrap p.subtitle_7 {
  margin-top: 20px;
  margin-bottom: 60px;
}

.main_section .main_section_wrap .left_wrap,
.main_section .main_section_wrap .right_wrap {
  width: 50%;
  max-width: 600px;
  height: 100%;
}

.main_section .main_section_wrap .section_desc {
  width: 430px;
}

.main_section_wrap .section_desc .btn_more_wrap {
  margin-top: 60px;
  gap: 12px;
}

.main_section_wrap .section_desc .btn_more_wrap .btn_more {
  width: 210px;
  height: 66px;
  background-color: #ededed;
}

/* section1 */
.main_section#section1 {
  height: 950px;
}

.main_section#section1 p.text_20 {
  margin-top: 16px;
  margin-bottom: 60px;
}

.main_section#section1 .slider {
  width: 100%;
  height: 274px;
  overflow-x: hidden;
  overflow-y: hidden;
  /*border: 1px solid black;*/
}

.main_section#section1 .slider .slider_wrap {
  width: 4000px;
  height: 100%;
  transition-timing-function: linear;
}

/* section2 */
#section2 .btn_more_wrap .ic_wrap {
  background: rgba(30, 100, 255, 0.08);
  border-radius: 16px;
  width: 62px;
  height: 62px;
  padding: 11px;
}

#section4.main_section {
  background-color: #ffffff;
}

/* section5 */
.main_section#section5 .main_section_wrap {
  background-color: #ebebeb;
}

.main_section#section5 .main_section_wrap .subtitle_4 {
  margin-top: 20px;
  margin-bottom: 40px;
}

#section5.main_section {
  background-color: transparent;
  height: 842px;
}

.main_section#section5 .main_section_wrap {
  background: linear-gradient(
    180deg,
    rgba(30, 100, 255, 0.01) 0%,
    rgba(30, 100, 255, 0.07) 100%
  ) !important;
}

.main_section#section5 .img_main_section5 {
  width: 69.27%;
  min-width: 800px;
  max-width: 1330px;
  height: auto;
  margin-bottom: 20px;
}

/* section6 */
#section6.main_section {
  height: 675px;
}
.main_section#section6 .main_section_wrap .text_22 {
  line-height: 38px;
  margin: 40px 0px;
}

.floating-button {
  flex-shrink: 0;
  position: fixed; /* 버튼을 화면에 고정 */
  bottom: 20px; /* 화면 아래쪽에서 20px 떨어진 위치에 배치 */
  right: 20px; /* 화면 오른쪽에서 20px 떨어진 위치에 배치 */
  padding: 8px 12px; /* 내부 여백 */
  border: 1px solid rgba(255, 255, 255, 0.06);
  cursor: pointer; /* 마우스 오버 시 커서 모양 변경 */
  box-shadow: 0px 12px 24px -4px rgba(124, 143, 172, 0.12);
  z-index: 1000; /* 다른 요소들 위에 레이어 순서를 높임 */
  text-align: center; /* 텍스트 가운데 정렬 */
  border-radius: 999px;
  backdrop-filter: blur(12px);
}

.floating-button .title {
  font-size: 20px; /* 텍스트 크기 */
  font-weight: 800; /* 텍스트 굵기 */
  line-height: 28px;
  font-style: normal;
}

.floating-button .description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

#section6 .call_button {
  gap: 14px;
}

/* 전화걸기 버튼 */
#section6 .call_button img {
  width: 40px;
  height: 40px;
}

/*@media screen and (max-width: 1080px) {*/
/*  !* main title 폰트 사이즈, 버튼 조절 *!*/
/*  .main_title_wrap h2 {*/
/*    margin-bottom: 12px;*/
/*  }*/

/*  button.main_button {*/
/*    width: 180px;*/
/*    height: 58px;*/
/*  }*/

/*  !* main_section 공통 *!*/
/*  .main_section#section2 .right_wrap,*/
/*  .main_section#section4 .right_wrap {*/
/*    align-items: flex-start;*/
/*  }*/

/*  .main_section#section3 .left_wrap {*/
/*    align-items: flex-end;*/
/*  }*/

/*  .main_section .main_section_wrap .section_desc {*/
/*    width: 360px;*/
/*  }*/

/*  .main_section .main_section_wrap .img_main_section {*/
/*    width: 280px;*/
/*  }*/

/*  .main_section .main_section_wrap p.text_50 {*/
/*    margin-top: 12px;*/
/*    margin-bottom: 16px;*/
/*    font-size: 42px;*/
/*    line-height: 56px;*/
/*  }*/
/*}*/

/* 태블릿 이하 */
@media screen and (max-width: 992px) {
  header {
    background-color: #123ba3 !important;
    box-shadow: none;
  }

  header .menu_button img {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }

  #main {
    height: 444px;
  }

  #main .img_earth {
    width: 288px;
    min-width: 288px;
    bottom: -70px;
    right: calc(50% - (240px / 3));
  }

  #main .main_container .main_title_wrap {
    position: relative;
    width: 85vw;
    min-width: 272px;
    max-width: 380px;
    justify-content: center;
  }

  #main .main_container .main_title_wrap p.text_20 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.025em;
  }

  .main_title_wrap .img_hero {
    position: absolute;
    top: 25px;
    left: 44%;
    min-width: 153px;
    max-width: 220px;
    width: 48%;
  }

  .main_title_wrap .down_button_wrap {
    margin-top: 30px;
  }

  button.main_button {
    width: 50%;
    height: 50px;
    background-color: #1e64ff;
    border-radius: 27px;
    font-size: 14px;
    line-height: 20px;
  }

  #main .main_container button.main_button {
    background-color: #132b4f;
  }

  .main_section,
  .main_section#section1,
  .main_section:last-of-type {
    height: auto;
    min-height: 388px;
    background: #fff;
    padding-bottom: 0px;
  }

  .main_section .main_section_wrap {
    padding: 60px 30px;
    max-width: 380px;
    margin: 0 auto;
  }

  .main_section .main_section_wrap .left_wrap,
  .main_section .main_section_wrap .right_wrap,
  .main_section .main_section_wrap .section_desc {
    width: 100%;
    height: auto;
  }

  .main_section .main_section_wrap p.text_50 {
    margin-top: 14px;
    margin-bottom: 14px;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  }

  .main_section .main_section_wrap p.text_20 {
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
  }

  .main_section .main_section_wrap p.subtitle_7 {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .main_section_wrap .section_desc .btn_more_wrap {
    margin-top: 0px;
    flex-direction: column;
  }

  .main_section_wrap img.mo_img_section,
  #section4.main_section .main_section_wrap .img_main_section {
    width: 60vw;
    min-width: 192px;
    max-width: 250px;
    height: auto;
    margin: 40px auto;
  }

  #section3.main_section_wrap img.mo_img_section {
    justify-content: center;
    align-content: center;
  }

  .main_section_wrap .section_desc .btn_more_wrap .btn_more {
    width: 100%;
    max-width: 140px;
    height: 50px;
    border-radius: 30px;
  }

  /* section1  */
  .main_section#section1 p.text_20 {
    margin-top: 18px;
    margin-bottom: 50px;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
  }

  .main_section#section1 .slider {
    width: 100vw;
  }

  /* section2 ~ 4 */
  #section2.main_section .main_section_wrap,
  #section4.main_section .main_section_wrap {
    flex-direction: column-reverse;
  }

  #section3.main_section .main_section_wrap {
    flex-direction: column;
  }

  #section2.main_section .main_section_wrap .left_wrap {
    display: none;
  }

  #section3.main_section .main_section_wrap .right_wrap {
    display: none;
  }

  /* #section2 */
  #section2.main_section,
  #section2.main_section .main_section_wrap {
    background-color: #ffffff;
  }

  #section2 .main_section_wrap .section_desc .btn_more_wrap .btn_more {
    max-width: 240px;
  }

  #section2 .btn_more_wrap .ic_wrap {
    background: rgba(30, 100, 255, 0.08);
    border-radius: 16px;
    width: 52px;
    height: 52px;
    padding: 10px;
  }

  /* section3 */
  #section3.main_section,
  #section3.main_section .main_section_wrap {
    background-color: #fff;
  }

  #section3 .main_section_wrap .section_desc .btn_more_wrap .btn_more {
    background-color: #638097;
    color: white;
  }

  #section3 .main_section_wrap .section_desc .btn_more_wrap .btn_more {
    background-color: #638097;
    color: white;
  }

  /* section5 ~ 6 */
  .main_section#section5 .main_section_wrap .text_60,
  .main_section#section6 .main_section_wrap .text_60 {
    font-size: 28px;
    line-height: 34px;
  }

  .main_section#section5 .main_section_wrap .text_22,
  .main_section#section6 .main_section_wrap .text_22 {
    font-size: 17px;
    line-height: 26px;
    margin-top: 18px;
    margin-bottom: 40px;
  }

  /* section5 */
  #section5.main_section {
    background-color: transparent;
    height: auto;
  }

  .main_section#section5 .main_section_wrap {
    background-color: white;
    background: linear-gradient(
      180deg,
      rgba(30, 100, 255, 0.01) 0%,
      rgba(30, 100, 255, 0.07) 100%
    ) !important;
  }

  .main_section#section5 .img_main_section5 {
    width: 46.8vw;
    min-width: 150px;
    max-width: 180px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  #section6.main_section {
    height: auto;
  }
  #section6 .call_button {
    gap: 6px;
  }
  #section6 .call_button img {
    width: 20px;
    height: 20px;
  }
}
</style>
