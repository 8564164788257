<script setup>
import {ref, computed, onBeforeMount, onMounted, Transition, watch} from 'vue'
import {extractNumbers, formatBrn, formatPhoneNumber} from "@/utils/utils.js";
import {VueDaumPostcode} from 'vue-daum-postcode'
import DropzoneInput from "@/components/coffee/DropzoneInput.vue";
import {uploadProtectedFile} from "@/api/commons.js";
import SimpleAuth from "@/components/coffee/simpleAuth.vue";
import {checkRegion, getRequestRegister, requestRegisterCafe} from "@/api/coffee.js";
import {useRouter} from "vue-router";

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import CustomCheck from "@/components/coffee/CustomCheck.vue";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {navigateTo} from "@/utils/coffee.js";
import WorkingHourInput from "@/components/coffee/WorkingHourInput.vue";
import Toggler from "@/components/coffee/Toggler.vue";
import CoffeeBottomSheet from "@/components/coffee/CoffeeBottomSheet.vue";
import {useLayoutStore} from "@/stores/layoutStore.js";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import RegisterProcessing from "@/components/coffee/RegisterProcessing.vue";
import imageCompression from "browser-image-compression";
import PreviewInput from "@/components/coffee/PreviewInput.vue";

const registerData = ref(null)

onBeforeMount(async () => {
  let registerDataTemp = await getRequestRegister()
  if (registerDataTemp?.status === 'at') {
    showAlert(`이미 [${registerDataTemp.name}]로 등록 완료되었습니다.`, async () => {
      await router.replace({'name': 'coffee-index'})
    })
  } else if (registerDataTemp?.status === 'rq') {
    registerData.value = registerDataTemp
    showAlert('배출 업체 등록 신청이 완료되었습니다.\n' +
        '영업일 기준 3일 이내 승인 결과 문자가 \n' +
        '휴대전화번호로 발송됩니다.')
  }
})

const uploadImage = async (target) => {
  return await uploadProtectedFile(target)
}


const router = useRouter()
const name = ref('')
const rep_name = ref('')
const brn = ref('')
const openDaumPostcode = ref(false)
const address = ref('')
const addressDetail = ref('')
const addressDetailRef = ref(null)
const cellphone = ref('')
const agree = ref(false)
const region = ref(null)
const everyWeek = ref(null)
const monWeek = ref(null)
const tueWeek = ref(null)
const wedWeek = ref(null)
const thuWeek = ref(null)
const friWeek = ref(null)
const satWeek = ref(null)
const sunWeek = ref(null)
const holiday = ref('')
// const methodType = ref(null)
const method = ref('')
const estimation = ref(0)
const nightAgree = ref(true)
const isNightAgreeAlertVisible = ref(false)

const image = ref(null)
const frontImage = ref(null)

const regionName = ref(null)
// 이미지 관련

const setImageError = (error) => {
  showAlert(error)
}

const setFrontImageError = (error) => {
  showAlert(error)
}

// 사업자등록번호
const enterBrn = (event) => {
  let value = extractNumbers(event.target.value).slice(0, 10)
  brn.value = value
  event.target.value = value
}

// 야간 문자발송
watch(nightAgree, (nv, ov) => {
  // if (nv !== ov) {
  //   isNightAgreeAlertVisible.value = true
  //   setTimeout(() => {
  //     isNightAgreeAlertVisible.value = false
  //   }, 1000)
  // }
  if (nv !== ov, nv === false) {
    showAlert('오후 6시 이후 알림을 전달할 수 없습니다. 알림을 받지 못해 수거 가방을 바로 회수하지 않으면 분실 될 수 있습니다.')
  }
})

// 영업시간 컨트롤
const checkEveryWeek = (value) => {
  let isChecked = value
  everyWeek.value = isChecked !== true ? null : ['', '']
  monWeek.value = isChecked !== true ? null : monWeek.value ? monWeek.value : ['', '']
  tueWeek.value = isChecked !== true ? null : tueWeek.value ? tueWeek.value : ['', '']
  wedWeek.value = isChecked !== true ? null : wedWeek.value ? wedWeek.value : ['', '']
  thuWeek.value = isChecked !== true ? null : thuWeek.value ? thuWeek.value : ['', '']
  friWeek.value = isChecked !== true ? null : friWeek.value ? friWeek.value : ['', '']
  satWeek.value = isChecked !== true ? null : satWeek.value ? satWeek.value : ['', '']
  sunWeek.value = isChecked !== true ? null : sunWeek.value ? sunWeek.value : ['', '']
}

const applyAllWeek = (value) => {
  console.log('asdfsdf')
  monWeek.value = monWeek.value ? value : null
  tueWeek.value = tueWeek.value ? value : null
  wedWeek.value = wedWeek.value ? value : null
  thuWeek.value = thuWeek.value ? value : null
  friWeek.value = friWeek.value ? value : null
  satWeek.value = satWeek.value ? value : null
  sunWeek.value = sunWeek.value ? value : null
}

// 주소 입력
const setDaumAddress = async (addressData) => {
  try {
    let addressBcode = addressData.bcode
    let regionCheckResult = await checkRegion(addressBcode)
    if (regionCheckResult.success === false) {
      address.value = ''
      region.value = null
      regionName.value = null
      showAlert([`이용 가능 지역 내 소재한 사업장만 등록 가능합니다.`, `이용 가능 지역 : ${regionCheckResult.data.join(', ')}`])
    } else {
      address.value = addressData.roadAddress === "" ? addressData.jibunAddress : addressData.roadAddress
      region.value = regionCheckResult.data.id
      regionName.value = regionCheckResult.data.name
      openDaumPostcode.value = false
      addressDetailRef.value.focus()
    }
  } catch (e) {
    showAlert(e.message)
  }
}

const checkWeek = (week) => {
  if (week === null) {
    return true
  }
  if (week[0] === '' || week[1] === '') {
    return false
  }
  return true
}


// 검증
const validate = () => {
  let errorArray = []
  if (name.value.trim().length === 0) {
    errorArray.push({field: 'name', error: '업체명을 입력해주세요'})
  }
  if (rep_name.value.trim().length === 0) {
    errorArray.push({field: 'rep_name', error: '대표자명을 입력해주세요'})
  }
  if (brn.value.length !== 10) {
    errorArray.push({field: 'brn', error: '올바른 사업자등록번호를 입력해주세요'})
  }
  if (address.value.trim().length === 0) {
    errorArray.push({field: 'address', error: '주소를 입력하세요'})
  }
  if (addressDetail.value.trim().length === 0) {
    errorArray.push({field: 'addressDetail', error: '상세 주소를 입력하세요'})
  }
  if (cellphone.value.trim() === '') {
    errorArray.push({field: 'auth', error: '휴대전화번를 확인해주세요'})
  }
  if (image.value === null) {
    errorArray.push({field: 'image', error: '업체 전경 사진이 등록되지 않았습니다.'})
  }
  if (monWeek.value === null && tueWeek.value === null && wedWeek.value === null && thuWeek.value === null && friWeek.value === null && satWeek.value === null && sunWeek.value === null) {
    errorArray.push({field: 'workingHour', error: '영업 시간을 선택해주세요'})
  }
  if (checkWeek(monWeek.value) === false) {
    errorArray.push({field: 'monWeek', error: '월요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(tueWeek.value) === false) {
    errorArray.push({field: 'tueWeek', error: '화요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(wedWeek.value) === false) {
    errorArray.push({field: 'wedWeek', error: '수요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(thuWeek.value) === false) {
    errorArray.push({field: 'thuWeek', error: '목요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(friWeek.value) === false) {
    errorArray.push({field: 'friWeek', error: '금요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(satWeek.value) === false) {
    errorArray.push({field: 'satWeek', error: '토요일 영업 시간을 선택해주세요'})
  }
  if (checkWeek(sunWeek.value) === false) {
    errorArray.push({field: 'sunWeek', error: '일요일 영업 시간을 선택해주세요'})
  }
  if (method.value.trim() === '') {
    errorArray.push({field: 'method', error: '배출 방법을 입력해주세요'})
  }
  if (frontImage.value === null) {
    errorArray.push({field: 'frontImage', error: '배출 장소 사진이 등록되지 않았습니다.'})
  }
  // if (isNaN(parseInt(estimation.value)) === true) {
  //   errorArray.push({field: 'estimation', error: '예상 배출량을 입력해주세요.'})
  // }
  if (agree.value !== true) {
    errorArray.push({field: 'agree', error: '정보 활용 및 제공 동의를 해주세요.'})
  }
  // console.log(errorArray)
  return errorArray
}
const coffeeStore = useCoffeeStore()
const isValid = computed(() => validate().length === 0)
const register = async () => {
  let errors = validate()
  if (errors.length !== 0) {
    let errorArray = []
    errors.forEach(error => {
      errorArray.push(`${error.field} : ${error.error}`)
    })
    alert(errorArray.join('\n'))
    return
  }
  try {
    coffeeStore.setLoading(true)
    let imagePrepare = await uploadImage(image.value)
    let frontImagePrepare = await uploadImage(frontImage.value)
    let data = {
      region: region.value,
      name: name.value,
      rep_name: rep_name.value,
      brn: brn.value,
      address: address.value,
      address_detail: addressDetail.value,
      cellphone: cellphone.value,
      photo: imagePrepare.id,
      week_mon: monWeek.value,
      week_tue: tueWeek.value,
      week_wed: wedWeek.value,
      week_thu: thuWeek.value,
      week_fri: friWeek.value,
      week_sat: satWeek.value,
      week_sun: sunWeek.value,
      working_hour_etc: holiday.value === '' ? null : holiday.value,
      address_dispose: method.value,
      dispose_photo: frontImagePrepare.id,
      estimated_weight: estimation.value,
      night_agree: nightAgree.value
    }
    const registerResult = await requestRegisterCafe(data)
    coffeeStore.setLoading(false)
    await router.go(0)
  } catch (e) {
    coffeeStore.setLoading(false)
    if (e.name === 'AxiosError') {
      let errors = e.response.data.errors
      let errorString = errors.reduce((acc, cur) => {
        return acc + `${cur.detail}\n`
      }, '')
      showAlert(errorString)
    } else {
      showAlert(e.message)
    }
  }
}
// const isValid = computed(() => true)
// const register = async () => {
//   let data = {
//     region: 1,
//     name: '카페 지구하다',
//     rep_name: '박상원',
//     brn: '2978601887',
//     address: '양천궟ㅇ',
//     address_detail: '123',
//     cellphone: '01024846313',
//     photo: 'da753fc3-d927-46fb-8e1b-100920c3dac5',
//     week_mon: ['13:22', "19:22"],
//     week_tue: null,
//     week_wed: null,
//     week_thu: null,
//     week_fri: null,
//     week_sat: null,
//     week_sun: null,
//     working_hour_etc: '매주 화요일 2시',
//     address_dispose: '문 앞',
//     dispose_photo: 'da753fc3-d927-46fb-8e1b-100920c3dac5',
//     estimated_weight: 33,
//     night_agree: true
//   }
//   try {
//     const registerResult = await requestRegisterCafe(data)
//     await router.go(0)
//   } catch (e) {
//     if (e.name === 'AxiosError') {
//       let errors = e.response.data.errors
//       let errorString = errors.reduce((acc, cur) => {
//         return acc + `${cur.detail}\n`
//       }, '')
//       showAlert(errorString)
//     } else {
//       showAlert(e.message)
//     }
//   }
// }

//  Alert 관련
const alertIsShown = ref(false)
const alertBody = ref('')
const alertCloseCallback = ref(null)

const showAlert = (body, callback = null) => {
  alertBody.value = body
  alertIsShown.value = true
  alertCloseCallback.value = callback
}

const closeAlert = () => {
  alertIsShown.value = false
  if (alertCloseCallback.value !== null) {
    alertCloseCallback.value()
  }
}

const filterSpecialChar = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    return str.replace(/[^\u0000-\u007F\uAC00-\uD7A3]/g, '')
  }
}

// 약관 관련
const showPolicy = ref(null)

const openPolicy = (policy) => {
  if (policy === 'third' && regionName.value === null) {
    alert('주소 입력 후 약관을 확인해주세요.')
    return null
  }
  showPolicy.value = policy
}
</script>

<template>
  <template v-if="registerData">
    <RegisterProcessing :register-data="registerData"/>
  </template>
  <template v-else>
    <div class="pt-[62px] mb-[20px]">
      <div class="page-title mb-[8px]">업체 등록 정보 입력</div>
      <div class="mb-[48px]">
        <ul>
          <li>이용 가능 지역 내 소재한 사업장만 등록 가능합니다.</li>
          <li>정보를 등록하시면 심사 후 승인 결과 문자가 휴대전화번호로 발송됩니다.</li>
        </ul>
      </div>
      <!--업체명 시작-->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="name">업체명</label>
        </div>
        <div class="mt-[8px]">
          <input id="name" :value="name" class="input" name="name" placeholder="업체명 입력"
                 type="text" @input="name=filterSpecialChar($event.target.value)"/>
        </div>
      </div>
      <!--업체명 끝-->
      <!--대표자명 시작-->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="rep_name">대표자명</label>
        </div>
        <div class="mt-[8px]">
          <input id="rep_name" :value="rep_name" class="input" name="rep_name" placeholder="대표자명 입력"
                 type="text" @input="rep_name=filterSpecialChar($event.target.value)"/>
        </div>
      </div>
      <!--대표자명 끝-->
      <!--사업자등록번호 시작-->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="brn">사업자등록번호</label>
        </div>
        <div class="mt-[8px]">
          <input id="brn" :value="brn" class="input" inputmode="numeric" name="brn"
                 pattern="[0-9]*" placeholder="숫자만 입력" type="text" @input="enterBrn"/>
        </div>
      </div>
      <!--사업자등록번호 끝-->
      <!--업체 주소 그룹 시작-->
      <div class="mb-[36px]">
        <!--업체 주소 시작-->
        <div class="mb-[8px]">
          <label class="input-header" for="address">업체 주소</label>
        </div>
        <div :class="openDaumPostcode ? 'mb-[8px]':'mb-[4px]'" class="flex w-full mt-[8px] gap-x-[8px]">
          <input id="address" :value="address" class="input" name="address" placeholder="주소 입력" readonly
                 type="text"/>
          <button class="light-btn" type="button" @click="() => openDaumPostcode=true">검색</button>
        </div>
        <!--업체 주소 끝-->
        <!--다음 주소 검색 모달 시작-->
        <div v-if="openDaumPostcode" class="mb-[4px]">
          <VueDaumPostcode @complete="setDaumAddress"/>
        </div>
        <!--다음 주소 검색 모달 끝-->
        <!--수거 신청 상세 주소 시작-->
        <label class="input-header sr-only" for="addressDetail">상세 주소</label>
        <div class="mt-[8px]">
          <input id="addressDetail" ref="addressDetailRef" :value="addressDetail" class="input" placeholder="상세 주소 입력"
                 type="text" @input="addressDetail=filterSpecialChar($event.target.value)"/>
        </div>
        <!--수거 신청 상세 주소 끝-->
      </div>
      <!--업체 주소 그룹 끝-->
      <!-- 인증번호 그룹 시작 -->
      <SimpleAuth :contact="cellphone"
                  @set-contact="(contactValue) => {showAlert('인증되었습니다.'); cellphone=contactValue}"/>
      <!-- 인증번호 그룹 끝 -->
      <!-- 업체 전경 사진 시작 -->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="image">업체 전경 사진</label>
        </div>
        <PreviewInput v-model="image" description="클릭해서 업체 사진 첨부"></PreviewInput>
        <div class="flex gap-x-[4px] align-center">
          <div class="exclamation-mark">
            <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                  fill="white"/>
            </svg>
          </div>
          <div class="exclamation-text">
            파일 크기는 5MB의 용량 제한이 있습니다.
          </div>
        </div>
      </div>
      <!-- 업체 전경 사진 끝 -->
      <!-- 영업시간 블록 시작 -->
      <div class="mb-[20px]">
        <div class="mb-[8px]">
          <label class="input-header" for="workingHourGroup">영업 시간</label>
        </div>
        <div class="mb-[22px]">
          <!--          {{ everyWeek }}-->
          <WorkingHourInput :is-checked="everyWeek !== null" :isAll="true"
                            :timeValue="everyWeek"
                            label="전체 선택" @apply:All="applyAllWeek"
                            @update:checkedValue="checkEveryWeek" @update:timeValue="value => everyWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ monWeek }}-->
          <WorkingHourInput :is-checked="monWeek !== null"
                            :timeValue="monWeek"
                            label="월요일"
                            @update:checkedValue="value => monWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => monWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ tueWeek }}-->
          <WorkingHourInput :is-checked="tueWeek !== null"
                            :timeValue="tueWeek"
                            label="화요일"
                            @update:checkedValue="value => tueWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => tueWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ wedWeek }}-->
          <WorkingHourInput :is-checked="wedWeek !== null"
                            :timeValue="wedWeek"
                            label="수요일"
                            @update:checkedValue="value => wedWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => wedWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ thuWeek }}-->
          <WorkingHourInput :is-checked="thuWeek !== null"
                            :timeValue="thuWeek"
                            label="목요일"
                            @update:checkedValue="value => thuWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => thuWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ friWeek }}-->
          <WorkingHourInput :is-checked="friWeek !== null"
                            :timeValue="friWeek"
                            label="금요일"
                            @update:checkedValue="value => friWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => friWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ satWeek }}-->
          <WorkingHourInput :is-checked="satWeek !== null"
                            :timeValue="satWeek"
                            label="토요일"
                            @update:checkedValue="value => satWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => satWeek = value"/>
        </div>
        <div class="mb-[22px]">
          <!--        {{ sunWeek }}-->
          <WorkingHourInput :is-checked="sunWeek !== null"
                            :timeValue="sunWeek"
                            label="일요일"
                            @update:checkedValue="value => sunWeek = value === true ? ['', ''] : null"
                            @update:timeValue="value => sunWeek = value"/>
        </div>
      </div>
      <!-- 영업시간 블록 끝   -->
      <!-- 휴무일 상세 시작   -->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="holiday">휴무일</label>
        </div>
        <div class="mt-[8px]">
          <input id="holiday" :value="holiday" class="input" name="holiday" placeholder="상세 입력"
                 type="text" @input="holiday=filterSpecialChar($event.target.value)"/>
        </div>
      </div>
      <!-- 휴무일 상세 끝 -->
      <!-- 배출 방법 시작 -->
      <div class="mb-[36px]">
        <div class="mb-[8px]">
          <label class="input-header" for="methodtitle">배출 방법</label>
          <div class="flex gap-x-[4px] align-center mt-[4px]">
            <div class="exclamation-mark">
              <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                    fill="white"/>
              </svg>
            </div>
<!--            <div class="exclamation-text">-->
<!--              수거는 오전 9시 ~ 오후 9시에 진행됩니다.-->
<!--            </div>-->
          </div>
        </div>
        <div class="mt-[8px] mb-[10px]">
          <div class="mb-1">
            <label class="sr-only" for="method">배출 방법</label>
            <input id="method" :value="method" class="input" name="method" placeholder="매장 내부, 매장 문 앞 등 상세 입력"
                   type="text" @input="method=filterSpecialChar($event.target.value)"/>
          </div>
          <div class="mb-1">
            <label class="sr-only" for="image">장소 사진</label>
            <PreviewInput v-model="frontImage" description="클릭해서 장소 사진 첨부"></PreviewInput>
            <!-- <DropzoneInput :acceptExtensions="['image/*']" :description="'클릭해서 장소 사진 첨부'"
                           :files="frontImages"
                           :maxFiles="1"
                           @set-file="setFrontImages"
                           @set-error="setFrontImageError"/> -->
          </div>
          <div class="flex gap-x-[4px] align-center mt-[4px]">
            <div class="exclamation-mark">
              <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
                    fill="white"/>
              </svg>
            </div>
            <div class="exclamation-text">
              파일 크기는 5MB의 용량 제한이 있습니다.
            </div>
          </div>
        </div>
        <!--        <div>-->
        <!--          {{methodType}}-->
        <!--          <div class="mb-5">-->
        <!--            <CustomCheck :is-checked="methodType === 'storeIn'" :label="'매장 방문'" @update:modelValue="value => {if (value === true) {methodType = 'storeIn'}}"/>-->
        <!--          </div>-->
        <!--          <div class="mb-5">-->
        <!--          <CustomCheck :is-checked="methodType === 'storeOut'" :label="'매장 외부'" @update:modelValue="value => {if (value === true) {methodType = 'storeOut'}}"/>-->
        <!--          </div>-->
        <!--        </div>-->
        <div>

        </div>
      </div>
      <!-- 배출 방법 끝 -->
      <!-- 예상 배출량 시작 -->
      <!-- 20240626 홍찬의 : 양천구 주무관 요청으로 삭제-->
      <!--      <div class="mb-[36px]">-->
      <!--        <div class="mb-[8px]">-->
      <!--          <label class="input-header" for="estimation">예상 배출량(Kg/월)</label>-->
      <!--        </div>-->
      <!--        <div class="mt-[8px]">-->
      <!--          <input id="estimation" :value="estimation" class="input" name="estimation" placeholder="숫자만 입력"-->
      <!--                 type="number" @click="$event.target.select()" @input="estimation=$event.target.value"/>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- 예상 배출량 끝 -->

      <!-- 야간 알림 수신 여부 시작-->
      <div class="mb-9">
        <div class="label mb-2">야간 알림 수신 여부</div>
        <div class="flex w-full items-center justify-between mb-9">
          <div class="page-sub-title">야간(오후 6시 이후) 수신 동의</div>
          <Toggler :togglerValue="nightAgree"
                   @update:togglerValue="value => nightAgree = value"/>
        </div>
      </div>
      <!-- 야간 알림 수신 여부 끝-->
      <!-- 정보 활용 및 제공 동의 시작 -->
      <div class="mb-[36px]">
        <CustomCheck :is-checked="agree" :label="'정보 활용 및 제공 동의(필수)'" @update:modelValue="value => agree = value">
          <span class="policy" @click.stop="openPolicy('privacy')">개인정보 수집・이용</span> 및
          <span class="policy" @click.stop="openPolicy('third')">제 3자 제공</span>
          동의 (필수)
        </CustomCheck>
      </div>
      <!-- 정보 활용 및 제공 동의 끝 -->
      <!-- 버튼 영역 시작 -->
      <div class="flex w-full justify-space-between gap-x-[8px]">
        <button class="white-btn max-w-[88px]" @click="() => {router.push({name: 'coffee-index'})}">취소</button>
        <div class="w-full">
          <button :disabled="!isValid" class="primary-btn w-full" @click="register">등록 신청</button>
          <!--        <button class="primary-btn w-full" @click="showAlert('파일 크기는 5MB 이하로 첨부 가능합니다. 사진 용량을 확인해주세요.')">등록 신청</button>-->
        </div>
      </div>
      <!-- 버튼 영역 끝 -->
    </div>
  </template>
  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="closeAlert"/>

  <Transition name="fade">
    <div v-if="isNightAgreeAlertVisible" class="night-agree-alert-box">
      <div class="night-agree-alert-box-check">
        <svg fill="none" height="8" viewBox="0 0 10 8" width="10" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M9.78366 0.241753C10.0721 0.564091 10.0721 1.0867 9.78366 1.40904L4.10184 7.75825C3.81338 8.08058 3.34571 8.08058 3.05725 7.75825L0.216342 4.58364C-0.0721138 4.26131 -0.0721138 3.73869 0.216342 3.41636C0.504797 3.09402 0.972476 3.09402 1.26093 3.41636L3.57955 6.00732L8.73907 0.241753C9.02752 -0.0805844 9.4952 -0.0805844 9.78366 0.241753Z"
                fill="white"
                fill-rule="evenodd"/>
        </svg>
      </div>
      <div class="night-agree-alert-box-text">
        <template v-if="nightAgree">
          알림 수신 동의 확인
        </template>
        <template v-else>
          알림 수신 동의 취소
        </template>
      </div>
    </div>
  </Transition>
  <CoffeeBottomSheet :show="showPolicy !== null" body="test" @close="() => {showPolicy = null}">

    <template v-slot:title>
      <template v-if="showPolicy === 'privacy'">개인정보 수집 및 이용</template>
      <template v-else-if="showPolicy === 'third'">제 3자 제공</template>
    </template>
    <template v-slot:body>
      <template v-if="showPolicy === 'privacy'">
        <div class="bs-title mb-6">
          지구하다는 원할한 서비스 제공을 위해 최소한의 범위 내에서 개인정보를 수집 및 이용합니다.
        </div>
        <div>
          <div class="bs-title">1. 이용 목적</div>
          <div class="bs-body pl-4 mb-1">업체 등록, 수거 신청</div>
          <div class="bs-title">2. 수집 항목</div>
          <div class="bs-body pl-4 mb-1">업체 정보(업체명, 주소, 사업자번호, 연락처)</div>
          <div class="bs-title">3. 수집 목적</div>
          <div class="bs-body pl-4 mb-1">커피박 수거</div>
          <div class="bs-title">4. 보유 및 이용기간</div>
          <div class="bs-body pl-4">회원탈퇴시까지.(지구하다 이용약관 참조)</div>
        </div>
      </template>
      <template v-else-if="showPolicy === 'third'">
        <div class="bs-title mb-6">
          개인정보 제공에 동의하지 않으실 수 있으며, 동의하지 않으실 경우 서비스 이용에 제한될 수 있습니다.
        </div>
        <div>
          <div class="bs-title">1. 제공받는자</div>
          <div class="bs-body pl-4 mb-1">{{regionName}}</div>
          <div class="bs-title">2. 제공 목적</div>
          <div class="bs-body pl-4 mb-1">업체 등록 승인 및 커피박 수거</div>
          <div class="bs-title">3. 보유 및 이용기간</div>
          <div class="bs-body pl-4">서비스 제공 완료시까지(단, 관계 법령이 정한 시점까지 보존)</div>
          <div class="bs-title">4. 제공 항목</div>
          <div class="bs-body pl-4">업체명, 주소, 연락처, 사업자 번호</div>
        </div>
      </template>
    </template>
  </CoffeeBottomSheet>


</template>

<style scoped>

.bs-title {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.bs-body {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.image-preview {
  border-radius: 8px;
  object-fit: cover;
}

.night-agree-alert-box {
  position: fixed;
  bottom: 10px;
  left: 20px;
  right: 20px;
  border-radius: 8px;
  background: #DFEDFF;
  display: flex;
  height: 52px;
  padding: 12px 16px;
  justify-content: start;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.night-agree-alert-box-text {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.night-agree-alert-box-check {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background: #3182FF;
  border: none;
}

.fade-enter-active {
  transition: opacity 0.1s ease;
}

.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.policy {
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
  text-decoration-line: underline;
}
</style>