<script setup>
import { ref, computed, onMounted } from "vue";
import { formatDate } from "@/utils/utils";
import "v-calendar/dist/style.css";
import router from "@/router";
import { useRequestStore } from "@/stores/requestStore";
import BlwSideNavgiation from "@/components/BlwSideNavigation.vue";

const requestStore = useRequestStore();

const selectedDisposeDate = ref(null);

const nextButtonValid = computed(() => {
  return selectedDisposeDate.value !== null;
});

const calendarMinDate = computed(() => {
  const today = new Date();

  const cutoffTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    15,
    30
  );

  if (today >= cutoffTime) {
    const nextDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return nextDay;
  }

  return today;
});

const calendarMaxDate = computed(() => {
  const today = new Date();
  today.setDate(today.getDate() + 14);
  return today;
});

const calendarAttributes = ref([
  {
    key: "today",
    highlight: {
      color: "#1E64FF",
    },
    content: {
      style: {
        color: "white",
      },
    },
    dates: new Date(),
  },
]);

// function

function routeNextPage() {
  router.push("/blw-request02");
}

function onClickSelectDate(date) {
  if (date !== null) {
    if (!date.isDisabled) {
      selectedDisposeDate.value = formatDate(date.date);
    } else {
      selectedDisposeDate.value = null;
    }
  }
}

function onClickNextButton() {
  requestStore.setDisposeDate(selectedDisposeDate.value);
  routeNextPage();
}

function setPrevData() {
  if (requestStore.disposeDate !== null) {
    selectedDisposeDate.value = requestStore.disposeDate;
  }
}

onMounted(() => {
  setPrevData();
});
</script>

<template>
  <div class="page_container flex flex_col justify_start align_center">
    <div
      class="single-content-page-wrap flex flex_col justify_center align_start"
    >
      <div class="content_layout flex flex_row justify_end align_start">
        <!-- 좌측 레이어 -->
        <div class="left_wrap">
          <p class="caption_1 color_secondary left page_sub_title">
            대형 생활 폐기물
          </p>
          <p class="subtitle_3 left page_title">배출신고</p>
          <!-- step -->
          <div class="tag_wrap flex flex_row justify_start align_center">
            <span
              class="tag active body_3 color_secondary flex justify_center align_center"
              >배출일 선택</span
            >
            <img
              class="tag_arrow_right"
              src="@/assets/icon/ic_arrow_right.svg"
              alt=">"
            />
            <span
              class="tag body_3 color_secondary flex justify_center align_center"
              >배출정보 입력</span
            >
            <img
              class="tag_arrow_right"
              src="@/assets/icon/ic_arrow_right.svg"
              alt=">"
            />
            <span
              class="tag body_3 color_secondary flex justify_center align_center"
              >배출품목 선택</span
            >
          </div>

          <p class="subtitle_7 color_secondary left mb-10">
            폐기물을 배출하실 날짜를 선택해 주세요.
          </p>
          <v-calendar
            :masks="{ title: 'YYYY년 MM월' }"
            :min-date="calendarMinDate"
            :max-date="calendarMaxDate"
            :attributes="calendarAttributes"
            expanded
            borderless
            mode="date"
            @dayclick="onClickSelectDate"
            nav-visibility="click"
          >
            <template #header-title="{ title }">
              <div class="subtitle_7 color_secondary">
                {{ title }}
              </div>
            </template>
          </v-calendar>

          <ul class="help_text_wrap body_3 g300">
            <li>
              수거 완료까지는 배출일로부터 평균 2영업일이 소요되며, 상황에 따라
              지연될 수 있어요.
            </li>
            <li>
              공동주택(아파트)의 경우는 지정된 수거요일에만 수거하고 있어요.
            </li>
          </ul>

          <div class="flex flex_col justify_center align_center">
            <button
              id="next_button"
              class="next_button label_1"
              @click="onClickNextButton"
              :disabled="!nextButtonValid"
            >
              다음
            </button>
            <!-- 다음 버튼 active일 경우 -->
            <!-- <button id="next_button" class="full_button bg_dark_black white semi_bold">다음</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::v-deep .vc-header {
  margin-bottom: 20px;
}

.next_button {
  width: 100%;
  height: 50px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
  margin-bottom: 100px;
}

.next_button:disabled {
  width: 100%;
  height: 50px;
  background-color: #b4b4b4;
  color: white;
  border-radius: 4px;
}

.help_text_wrap {
  margin-top: 20px;
}
</style>
