<template></template>

<script setup>
import { onMounted } from "vue";
import Cookies from "js-cookie";
import { useUserStore } from "@/stores/userStore";
import router from "@/router";

const userStore = useUserStore();
const urlParams = new URLSearchParams(window.location.search);

function parsePathFromUrl(urlString) {
  try {
    const url = new URL(urlString);
    const path = url.pathname;
    return path;
  } catch (error) {
    console.error("유효하지 않은 URL입니다:", error);
    return null;
  }
}

onMounted(async () => {
  const sharedData = Cookies.get("zguhadaCookie");

  if (sharedData) {
    try {
      let formattedData = sharedData.replace(/([a-zA-Z0-9_]+):/g, '"$1":');
      formattedData = formattedData.replace(/:(\w[\w\.\-]+)/g, ':"$1"');

      const data = JSON.parse(formattedData);
      const access = data.access;
      const refresh = data.refresh;

      await userStore.provideToken(access, refresh);
      const redirect = parsePathFromUrl(urlParams.get("redirect"));
      if (redirect) {
        router.replace(redirect);
      } else {
        router.replace("/");
      }
    } catch (error) {
      console.log("JSON 파싱 오류:", error);
      router.replace("/");
    }
  } else {
    console.log("zguhadaCookie 쿠키가 없습니다.");
    userStore.clearToken();
    router.replace("/");
  }
});
</script>
