<script setup>
const showAlert = defineModel("showAlert", {
  type: Boolean,
  required: true,
});

const emit = defineEmits("closeAlert");

function onClickCloseButton() {
  emit("closeAlert");
}
</script>

<template>
  <v-dialog
    v-model="showAlert"
    class="alert_wrap"
    @click:outside="onClickCloseButton"
    overlay-opacity="3"
  >
    <v-card class="alert_box" elevation="0">
      <button @click="onClickCloseButton" class="close_button">
        <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
      </button>
      <v-container class="pa-0 ma-0" style="overflow-y: unset">
        <div class="flex flex_col align_center justify_center pb-2">
          <span class="title_content text_18 bold blue_midnight">
            지정된 장소에 배출해 주세요.
          </span>
          <span class="description_content text_14 mid gray1 center">
            주택 종류에 따라 배출 가능한 장소가 달라요. 꼭 배출 가능한 장소에
            배출해 주세요.
          </span>
          <v-row
            class="image_container flex flex_row align_center justify_space_between"
          >
            <v-col
              class="image_wrap flex flex_col align_center justify_center pt-0"
            >
              <img src="@/assets/image/apartment.svg" />
              <span class="text_14 blue_midnight bold center"> 공통주택 </span>
              <div class="image_divider"></div>
              <span class="blue_grey text_12 center">
                분리수거장 등 <br />
                단지 내 지정 장소
              </span>
            </v-col>
            <v-col
              class="image_wrap flex flex_col align_center justify_center pt-0"
            >
              <img src="@/assets/image/house.svg" />
              <span class="text_14 blue_midnight bold center"> 단독주택 </span>
              <div class="image_divider"></div>
              <span class="blue_grey text_12 center">
                집 대문 앞 등 <br />
                차량 진입이 가능한 곳
              </span>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dialog-center .v-overlay__content {
  /* width: auto !important;
  max-width: none !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.close_button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.close_button img {
  width: 20px;
  height: 20px;
}
.alert_background {
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.alert_box {
  position: relative;
  border-radius: 8px !important;
  max-width: 320px;
  min-width: 320px;
  padding: 64px 20px 50px 20px;
  background: white;
}

.title_content {
  padding-left: 6px;
  padding-right: 6px;
}

.description_content {
  padding: 10px 20px 40px 20px;
  word-wrap: break-word;
}

.okay_button {
  color: white;
  height: 40px;
  width: 100%;
}

.image_container {
  width: 250px;
}

.image_container .image_wrap {
  margin-bottom: 0;
  padding-bottom: 0;
}

.image_container .image_wrap img {
  width: 93px;
}

.image_container .image_wrap .image_divider {
  height: 1px;
  width: 100%;
  background: #e7ecf4;
  margin-top: 6px;
  margin-bottom: 6px;
}

@media screen and (max-width: 992px) {
  .alert_box {
    max-width: none;
    min-width: none;
  }
}
</style>
