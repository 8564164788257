import { defineStore } from "pinia";

export const useCoffeeStore = defineStore({
  id: "Coffee",
  state: () => ({
    cafe: null,
    onLoading: false,
  }),
  actions: {
    setCafe(data) {
      this.cafe = data
    },
    setLoading(data) {
      console.log('set loading ', data)
      this.onLoading = data
    }
  },
});
