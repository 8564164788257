<script setup>
import {useRouter} from "vue-router";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {storeToRefs} from "pinia";
import Map from "@/components/coffee/Map.vue";
import HeroCounter from "@/components/coffee/HeroCounter.vue";
import CoffeeGraphic from "@/assets/image/coffee/graphic-coffee.png"
import NumberTitle from "@/views/coffee/NumberTitle.vue";
import CheckTitle from "@/views/coffee/CheckTitle.vue";
import Exclamation from "@/components/Exclamation.vue";
import QNABlock from "@/components/coffee/QNABlock.vue";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {formatDate} from "../../utils/utils.js";
import {dateParser, timeParser} from "../../utils/coffee.js";
import IndexContentNoCafe from "@/views/coffee/partials/IndexContentNoCafe.vue";
import InfoNoCafePopup from "@/views/coffee/partials/InfoNoCafePopup.vue";
import IndexContentCafe from "@/views/coffee/partials/IndexContentCafe.vue";
import InfoPopup from "@/views/coffee/partials/InfoPopup.vue";

const router = useRouter()
const coffeeStore = useCoffeeStore()

const {cafe} = storeToRefs(coffeeStore)

onMounted(async () => {
  document.getElementById('coffeeMainLayout').classList.add('pt-0')
  document.getElementById('coffeeMainLayout').classList.remove('pt-[60px]')
  document.getElementById('coffeeSubLayout').classList.remove('px-5')
})

onBeforeUnmount(() => {
  document.getElementById('coffeeMainLayout').classList.add('pt-[60px]')
  document.getElementById('coffeeMainLayout').classList.remove('pt-0')
  document.getElementById('coffeeSubLayout').classList.add('px-5')
})

const region = computed(() => {
  return cafe.value?.data?.region
})

const showOpenPopup = ref(false)

const closeInfoPopup = () => showOpenPopup.value = false
const openInfoPopup = () => showOpenPopup.value = true
</script>


<template>
  <div class="main-box">
    <!-- 상단 히어로 영역 시작 -->
    <div class="hero-box">
      <div class="graphic">
        <img :src="CoffeeGraphic" alt="커피 이미지" style="height: 154px"/>
      </div>
      <!-- 타이틀 영역 시작 -->
      <div class="cf-head-2 pt-[92px] mb-8 text-white">
        <template v-if="!region">
          <!-- 등록이 없는 경우-->
          앱으로 간편하게<br>수거 신청하면 끝!
        </template>
        <template v-else-if="region?.tomorrow_collection?.can_request === true">
          <!-- 내일이 수거일일 경우-->
          <template v-if="region?.next_collection?.request_count < region?.next_collection?.capacity">
            오늘 {{ timeParser(region.dispose_time) }}까지<br>수거 신청 가능합니다.
          </template>
          <template v-else>
            오늘<br>수거 신청이<br>마감되었습니다.
          </template>
        </template>
        <template v-else>
          <!-- 내일이 수거일이 아닐 경우-->
          <template v-if="Array.isArray(region?.tomorrow_collection?.restricted_reason_message)">
            <div v-for="msg in region?.tomorrow_collection?.restricted_reason_message">
              {{ msg }}
            </div>
          </template>
          <template v-else>
            {{ region?.tomorrow_collection?.restricted_reason_message }}
          </template>
        </template>
      </div>
      <!-- 타이틀 영역 끝 -->
      <!-- 카운터 영역 시작-->
      <div class="w-full">
        <template v-if="!region">
          <!-- 등록이 없는 경우-->
        </template>
        <template v-else-if="region?.next_collection?.is_tomorrow">
          <div class="flex gap-x-5">
            <!-- 내일이 수거일일 경우-->
            <template v-if="region?.next_collection?.request_count < region?.next_collection?.capacity">
              <!-- 민원 등의 사유로 초과 신청을 받더라도 화면단에선 capacity를 최대값으로 표시-->
              <HeroCounter :count="Math.min(region?.next_collection?.request_count, region?.next_collection?.capacity)"
                           label="접수"/>
              <HeroCounter :count="region?.next_collection?.capacity" label="신청 가능 잔여 수"/>
            </template>
            <template v-else>
              <div class="cf-title-4 text-white">
                {{ dateParser(region?.next_collection?.can_request_date) }} 오전 0시부터 신청 가능합니다.
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="cf-title-4 text-white">
            {{ dateParser(region?.next_collection.can_request_date) }} 오전 0시부터 신청 가능합니다.
          </div>
        </template>
      </div>
      <!-- 카운터 영역 끝-->
    </div>
    <!-- 상단 히어로 영역 끝 -->
    <!-- 다음 수거일 표시 영역 시작-->
    <div v-if="region" class="next-collection-block flex gap-x-[4px] align-start justify-start">
      <div class="exclamation-mark-white mt-1">
        <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
              fill="white"/>
        </svg>
      </div>
      <div class="cf-title-4 text-white">
        다음 수거일 {{ dateParser(region?.next_collection.date) }}
      </div>
    </div>
    <!-- 다음 수거일 표시 영역 끝-->
    <!-- 컨텐츠 영역 시작 -->
    <div class="content-box">
      <div class="mb-9">
        <div class="flex items-center justify-between mb-1">
          <div class="cf-head-4 cf-text-gray-900">커피박 수거 신청하기</div>
          <button class="use-info-btn" type="button" @click="openInfoPopup">이용 안내</button>
        </div>
        <div class="cf-title-4 cf-text-gray-700 mb-5">커피박 버리지말고 무상 수거 신청하세요.</div>
        <router-link :to="{name: cafe?.type === 'cafe' ? 'coffee-request-collection' : 'coffee-request-register'}"
                     class="secondary-btn cf-head-6 mb-7 cf-text-primary w-full">
          {{ cafe?.type === 'cafe' ? '수거  신청' : '업체 등록' }}
        </router-link>
      </div>
      <div v-if="cafe.type">
        <IndexContentCafe :cafe="cafe"/>
      </div>
      <div v-else>
        <IndexContentNoCafe :cafe="cafe"/>
      </div>
    </div>
    <!-- 컨텐츠 영역 끝 -->
  </div>
  <template v-if="cafe.type">
    <InfoPopup :cafe="cafe" :open="showOpenPopup" @close="closeInfoPopup"/>
  </template>
  <template v-else>
    <InfoNoCafePopup :cafe="cafe" :open="showOpenPopup" @close="closeInfoPopup"/>
  </template>
</template>

<style scoped>

.use-info-btn {
  color: #3182FF;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  text-decoration-line: underline;
}

.main-box {
  width: 100%;
  overflow-y: auto;
}

.hero-box {
  width: 100%;
  height: 290px;
  padding-left: 20px;
  padding-right: 20px;
  background: radial-gradient(188.14% 174.71% at 32.93% 16.74%, #3182FF 0%, #C6EF7E 100%);
}

.graphic {
  position: absolute;
  top: 80px;
  right: 0;
}


.content-box {
  width: 100%;
  background: #FFF;
  padding: 36px 20px 36px 20px;
}

.acc-box {
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--CF-Gray-200, #EFEFEF);
  background: var(--CF-Gray-160, #F5F5F5);
}

.method-token {
  display: flex;
  height: 40px;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 999px;
  background: #fff;
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  border: 1px solid #DFDFDF;
}

.method-token.active {
  background: #111;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  border: none;
}

.content-title {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
}

.content-label-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.content-label-number {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background: #111;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.content-label {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.content-description {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.exclamation-mark-2 {
  width: 18px;
  height: 18px;
  border-radius: 999px;
  font-family: NanumSquare, sans-serif;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.exclamation-text-2 {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.exclamation-underbar {
  width: 100%;
  height: 10px;
  background: #B4FB39;
}

.list-descriptions > li {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  text-indent: -10px;
}


.list-descriptions > li:before {
  content: "- ";
}

.image-placeholder {
  aspect-ratio: 1/1;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F5F5F5;
}

.list-descriptions-emp {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.question-wrapper {
  display: flex;
  gap: 4px;
  align-items: start;
}

.qmark {
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.question {
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 4px;
}

.answer-wrapper {
  display: flex;
  gap: 4px;
  align-items: start;
}

.amark {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.answer {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.next-collection-block {
  width: 100%;
  height: 32px;
  padding: 5px 20px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: var(--CF-Primary, #3182FF);
}
</style>