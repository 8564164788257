<script setup>
import {ref, watch} from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: false
  },
  title: {
    type: String,
    default: '알림',
    required: false
  },
  body: {
    type: [String, Array],
    required: true
  },
})

const emits = defineEmits(['close'])

</script>

<template>
  <div v-if="show" class="wrapper z-60" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <div class="outer-modal">
      <div class="inner-modal-upper">
        <div class="modal-title">{{ props.title }}</div>
        <div class="modal-body">
          <template v-if="Array.isArray(props.body)">
            <p v-for="body in props.body">{{body}}</p>
          </template>
          <template v-else>
            {{props.body}}
          </template>
        </div>
      </div>
      <div class="inner-modal-lower">
        <button class="alert-primary-btn w-full" type="button" @click="emits('close')">확인</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alert-primary-btn {
  display: flex;
  height: 44px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3182FF;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.modal-title {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
  margin-bottom: 8px;
}

.modal-body {
  color: #555;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.48);
  overflow: hidden;
  inset: 0;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.outer-modal {
  width: 316px;
}

.inner-modal-upper {
  border-radius: 16px 16px 0 0;
  background: white;
  padding: 24px 16px 0 16px;
}

.inner-modal-lower {
  border-radius: 0 0 16px 16px;
  background: white;
  padding: 24px 16px 24px 16px;
}
</style>