<script setup>
import {ref, watch} from "vue";
import { v4 as uuidv4 } from "uuid";



const props = defineProps({
  isChecked: {
    type: Boolean,
    default: false,
    required: false
  },
  label: {
    type: String,
    required: true
  },
  labelStyle: {
    type: String,
    required: false,
    default: 'round'
  }
})

const uid = ref(uuidv4())
const emits = defineEmits(['update:modelValue'])


const toggle = () => {
  emits('update:modelValue', !props.isChecked)
}

</script>

<template>
  <input :id="`check-${uid}`" :value="props.isChecked" class="sr-only" type="checkbox">
  <div class="flex items-center gap-x-[8px]" @click.stop="toggle">

    <div :class="[props.isChecked ? 'checkbox-input checked':'checkbox-input', labelStyle]">
      <svg fill="none" height="8" viewBox="0 0 10 8" width="10" xmlns="http://www.w3.org/2000/svg">
        <path :fill="props.isChecked ? 'white':'#DFDFDF'"
              clip-rule="evenodd"
              d="M9.78366 0.241753C10.0721 0.564091 10.0721 1.0867 9.78366 1.40904L4.10184 7.75825C3.81338 8.08058 3.34571 8.08058 3.05725 7.75825L0.216342 4.58364C-0.0721138 4.26131 -0.0721138 3.73869 0.216342 3.41636C0.504797 3.09402 0.972476 3.09402 1.26093 3.41636L3.57955 6.00732L8.73907 0.241753C9.02752 -0.0805844 9.4952 -0.0805844 9.78366 0.241753Z"
              fill-rule="evenodd"/>
      </svg>
    </div>
    <label class="label" :for="`check-${uid}`">
      <slot>{{ props.label }}</slot>
    </label>
  </div>
</template>

<style scoped>
.checkbox-input {
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #DFDFDF;
}

.checkbox-input.round {
  border-radius: 999px;
}
.checkbox-input.square {
  border-radius: 4px;
}

.checkbox-input.checked {
  background: #3182FF;
  border: none;
}

.label {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  user-select: none;
}
</style>