<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import {
  getProductCategoryList,
  getProductStandardList,
  getProductCategoryListSearch,
} from "@/api/request";
import { useRequestStore } from "@/stores/requestStore";
import { useLayoutStore } from "@/stores/layoutStore";
import { sendErrorLogToSentry } from "@/utils/utils";

const requestStore = useRequestStore();
const layoutStore = useLayoutStore();

const props = defineProps({
  showPopup: Boolean,
});

const showSearchPopup = computed(() => props.showPopup);

const emit = defineEmits(["hidePopup"]);

const totalMainCategoryList = ref([]);
const mainCategoryList = ref([]);
const totalSubCategoryList = ref([]);
const subCategoryList = ref([]);
const totalItemCategoryList = ref([]);
const itemCategoryList = ref([]);
const itemStandardList = ref([]);

const selectedMainCategory = ref(null);
const selectedSubCategory = ref(null);
const selectedItemCategory = ref(null);
const selectedItemStandard = ref(null);

const searchItemList = ref([]);
const showSearchListPanel = ref(false);

const depthHeaderList = ref(["대분류", "중분류", "상세분류", "규격 및 종류"]);

const selectedTotalQuantity = computed(() => {
  if (selectedItemStandardList.value.length > 0) {
    var totalQuantity = 0;
    selectedItemStandardList.value.forEach((element) => {
      totalQuantity += element.quantity;
    });

    return totalQuantity;
  } else {
    return 0;
  }
});

const selectedTotalPrice = computed(() => {
  if (selectedItemStandardList.value.length > 0) {
    var totalPrice = 0;
    selectedItemStandardList.value.forEach((element) => {
      totalPrice += element.quantity * element.price;
    });

    return totalPrice;
  } else {
    return 0;
  }
});

const searchTextField = ref("");

/* data set
{
    id: int,
    naem: string,
    categoryName: string,
    price: int,
    quantity: int,
    isSelected: boolean
}
*/
const selectedItemStandardList = ref([]);

const disableAddButton = computed(() => {
  return selectedItemStandardList.value.length === 0;
});

function onClickSearchCategory() {
  const searchText = searchTextField.value;
  if (searchText !== "" && searchText !== null) {
    requestGetProductCategoryListSearch(searchText);
  } else {
    layoutStore.showErrorPopup("공백은 검색할 수 없습니다.");
  }
}

// function

function onClickCloseButton() {
  emit("hidePopup");
}

function onClickMainCategory(item) {
  selectedMainCategory.value = item;

  selectedSubCategory.value = null;
  selectedItemCategory.value = null;
  itemCategoryList.value = [];
  itemStandardList.value = [];
  selectedItemStandard.value = null;

  subCategoryList.value = totalSubCategoryList.value.filter((element) => {
    return element.parentId === item.id;
  });
}

function onClickSubCategory(item) {
  selectedSubCategory.value = item;

  selectedItemCategory.value = null;
  itemCategoryList.value = [];
  itemStandardList.value = [];

  itemCategoryList.value = totalItemCategoryList.value.filter((element) => {
    return element.parentId === item.id;
  });
}

function onClickItemCategory(item) {
  selectedItemCategory.value = item;

  requestGetProductStandardList(item.id, item.name);
}

function onClickStandardCategory(item) {
  selectedItemStandard.value = item;

  const isIncludes = selectedItemStandardList.value.some(
    (element) => element.id === item.id
  );

  if (isIncludes) {
    alert("이미 추가된 품목 입니다.");
  } else {
    selectedItemStandardList.value.push(item);
  }
}

function onClickRemoveItem(index) {
  selectedItemStandardList.value.splice(index, 1);
}

function onClickRemoveSelectedAll() {
  if (confirm("선택된 전체 품목들을 삭제하겠습니까?")) {
    selectedItemStandardList.value = [];
  }
}

function onClickChangeQuantityButton(isPlus, itemId) {
  selectedItemStandardList.value.forEach((element) => {
    if (element.id === itemId) {
      if (isPlus) {
        element.quantity++;
      } else {
        if (element.quantity > 1) {
          element.quantity--;
        }
      }
    }
  });
}

function onClickSelectItemButton(itemId, isSelected) {
  selectedItemStandardList.value.forEach((element) => {
    if (element.id === itemId) {
      element.isSelected = isSelected;
    }
  });
}

function onClickAddItemButton() {
  if (selectedItemStandardList.value.length > 0) {
    if (confirm("선택한 항목들을 추가 하시겠습니까?")) {
      requestStore.addItemList(selectedItemStandardList.value);
      onClickCloseButton();
    }
  } else {
    alert("추가할 항목을 체크박스를 눌러 선택하셔야합니다.");
  }
}

function resetSelectedDatas() {
  mainCategoryList.value = [];
  subCategoryList.value = [];
  itemCategoryList.value = [];
  itemStandardList.value = [];

  selectedItemCategory.value = null;
  selectedSubCategory.value = null;
  selectedMainCategory.value = null;
  selectedItemStandard.value = null;
}

function onClickSearchItem(searchItem) {
  showSearchListPanel.value = false;

  if (searchItem[0] !== null) {
    onClickMainCategory(searchItem[0]);
  }

  if (searchItem[1] !== null) {
    onClickSubCategory(searchItem[1]);
  }

  if (searchItem[2] !== null) {
    onClickItemCategory(searchItem[2]);
  }
}

function focusSearchInput() {
  showSearchListPanel.value = searchItemList.value.length > 0;
}

function unfocusSearchInput() {
  setTimeout(() => {
    showSearchListPanel.value = false;
  }, 200);
}

// api request

async function requestGetProductCategoryList() {
  const govId = requestStore.govId;

  try {
    const response = await getProductCategoryList(govId);

    resetSelectedDatas();

    // 이거는 전체 데이터에 대한 정보임으로 해당 api에서만 초기화 처리해야함
    totalSubCategoryList.value = [];
    totalItemCategoryList.value = [];

    response.category_list.forEach((element) => {
      if (element.level === 1) {
        mainCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      } else if (element.level === 2) {
        totalSubCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      } else if (element.level === 3) {
        totalItemCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      }
    });
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestGetProductStandardList(categoryId, categoryName) {
  const govId = requestStore.govId;
  itemStandardList.value = [];

  try {
    const response = await getProductStandardList(govId, categoryId);

    response.product_standard_list.forEach((element) => {
      itemStandardList.value.push({
        id: element.id,
        name: element.name,
        categoryName: categoryName,
        price: element.price,
        quantity: 1,
        isSelected: false,
      });
    });
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestGetProductCategoryListSearch(name) {
  const govId = requestStore.govId;
  try {
    const response = await getProductCategoryListSearch(govId, name);

    const list = response.category_list;

    const searchItemFilter = list
      .filter((item) => item.level === 3)
      .map((item) => findItemPath(item, list));

    console.log(searchItemFilter);

    searchItemList.value = searchItemFilter;

    showSearchListPanel.value = list.length > 0;
  } catch (error) {
    console.log("somethingis Error,  ", error);
  }
}

function findItemPath(item, dataList) {
  let path = [item.name];
  let itemList = [item];

  while (item.parent_id !== null) {
    item = dataList.find((parentItem) => parentItem.id === item.parent_id);

    if (item) {
      path.unshift(item.name);
      itemList.unshift(item);
    }
  }

  return {
    itemPath: path.join(" > "),
    itemList: itemList,
  };
}

// life cycle
onMounted(() => {
  requestGetProductCategoryList();
});
</script>

<template>
  <v-dialog
    id="serach_modal"
    v-model="showSearchPopup"
    class="dialog_center"
    style="width: 1000px"
  >
    <v-card class="modal_wrap">
      <div class="modal_head">
        <p class="subtitle_5 color_secondary left">품목 선택</p>
        <button @click="onClickCloseButton" class="close_button">
          <img src="../../assets/icon/ic_x.png" alt="닫기" />
        </button>
      </div>
      <div class="title_divider"></div>
      <div style="height: calc(100% - 79px)">
        <div class="modal_body justify_start align_start">
          <!-- 좌측 품목검색 -->
          <div class="product_search_wrap">
            <!-- 검색창 -->
            <div
              class="search_input input_wrap2 body_2 flex flex_row justify_center align_center"
            >
              <input
                class="input grow"
                type="text"
                name="searchValue"
                id="searchValue"
                placeholder="배출하실 품목을 입력해 주세요."
                v-model="searchTextField"
                @keyup.enter="onClickSearchCategory"
                @blur="unfocusSearchInput"
                @focus="focusSearchInput"
              />
              <button
                id="search"
                class="bg_primary flex justify_center align_center"
                @click="onClickSearchCategory"
              >
                <img src="../../assets/icon/ic_search.png" alt="검색버튼" />
              </button>
            </div>
            <div class="search_list" v-show="showSearchListPanel">
              <div
                class="search_item body_2 color_secondary"
                v-for="(item, idx) in searchItemList"
                @click="() => onClickSearchItem(item.itemList)"
              >
                <span class="body_2 color_secondary"> {{ item.itemPath }}</span>
              </div>
            </div>
            <!-- 품목 depth -->
            <div class="product_depth_header flex flex_row">
              <div
                v-for="(item, index) in depthHeaderList"
                class="depth_header flex flex_row"
              >
                <div class="depth_title body_2 color_secondary">{{ item }}</div>
                <img
                  v-if="index !== depthHeaderList.length - 1"
                  src="@/assets/icon/ic_arrow_right_gray.svg"
                />
              </div>
            </div>
            <div class="product_depth_wrap grow">
              <div id="depth1" class="product_depth">
                <div class="depth_list grow">
                  <div
                    v-for="(item, idx) in mainCategoryList"
                    class="depth_item color_secondary left"
                    :class="[
                      {
                        selected:
                          selectedMainCategory !== null &&
                          selectedMainCategory.id === item.id,
                      },
                    ]"
                    @click="() => onClickMainCategory(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div id="depth2" class="product_depth">
                <div class="depth_list grow">
                  <div
                    v-for="(item, idx) in subCategoryList"
                    class="depth_item color_secondary left"
                    :class="[
                      {
                        selected:
                          selectedSubCategory !== null &&
                          selectedSubCategory.id === item.id,
                      },
                    ]"
                    @click="() => onClickSubCategory(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div id="depth3" class="product_depth">
                <div class="depth_list grow">
                  <div
                    v-for="(item, index) in itemCategoryList"
                    class="depth_item color_secondary left"
                    :class="{
                      selected:
                        selectedItemCategory !== null &&
                        selectedItemCategory.id === item.id,
                    }"
                    @click="() => onClickItemCategory(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div id="depth4" class="product_depth">
                <div class="depth_list grow">
                  <div
                    v-for="(item, index) in itemStandardList"
                    class="depth_item body_2 color_secondary left"
                    :class="{
                      selected:
                        selectedItemStandard !== null &&
                        selectedItemStandard.id === item.id,
                    }"
                    @click="() => onClickStandardCategory(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 우측 선택한 품목 -->
          <div class="selected_wrap">
            <!-- 선택한 품목 리스트 -->
            <div class="selected_list_wrap">
              <div class="whole_cancel_wrap flex justify_start align_center">
                <button
                  @click="onClickRemoveSelectedAll"
                  id="whole_cancel"
                  class="start g300 label_2"
                  :disabled="selectedItemStandardList.length === 0"
                >
                  전체 삭제
                </button>
              </div>
              <div class="selected_list">
                <template v-for="(item, idx) in selectedItemStandardList">
                  <div class="selected_item flex justify_start align_center">
                    <div class="item_info grow flex flex_col align_start">
                      <button
                        @click="() => onClickRemoveItem(idx)"
                        class="close_button"
                      >
                        <img
                          src="@/assets/icon/ic_x_blue_grey.svg"
                          alt="닫기"
                        />
                      </button>
                      <div class="title_wrap mb-5">
                        <p class="caption_1 color_secondary left mb-1">
                          {{ item.categoryName }}
                        </p>
                        <p class="body_3 color_caption left">
                          {{ item.name }}
                        </p>
                      </div>
                      <div class="flex justify_space_between align_center">
                        <div class="number_input_wrap align_center">
                          <button
                            class="minus"
                            @click="
                              () => onClickChangeQuantityButton(false, item.id)
                            "
                          >
                            <img src="@/assets/icon/ic_minus.svg" alt="-" />
                          </button>
                          <input
                            class="count caption_1 color_secondary"
                            min="0"
                            name="count"
                            type="number"
                            v-model="item.quantity"
                            disabled
                          />
                          <button
                            class="plus"
                            @click="
                              () => onClickChangeQuantityButton(true, item.id)
                            "
                          >
                            <img src="@/assets/icon/ic_plus.svg" alt="+" />
                          </button>
                        </div>
                        <p class="caption_1 color_secondary right">
                          <span class="caption_1 color_primary">{{
                            $formatNumber(item.price)
                          }}</span>
                          원
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div
                v-if="selectedItemStandardList.length === 0"
                class="flex flex_col justify_center align_center"
                style="top: 40%; left: 50%; transform: translate(-50%, -50%)"
              >
                <p class="caption_1 color_placeholder">
                  선택된 품목이 없습니다.
                </p>
              </div>
            </div>
            <!-- 합계 -->
            <div class="total_wrap flex flex_col align_start">
              <div
                class="flex flex_row justify_space_between align_center"
                style="width: 100%"
              >
                <p class="subtitle_7 color_secondary left">
                  총
                  <span class="subtitle_5 color_primary">
                    &nbsp;{{ selectedTotalQuantity }} </span
                  >개
                </p>
                <p class="subtitle_7 color_secondary right">
                  <span class="subtitle_5 color_primary">
                    {{ $formatNumber(selectedTotalPrice) }}
                  </span>
                  원
                </p>
              </div>
              <button
                @click="onClickAddItemButton"
                :disabled="disableAddButton"
                class="next_button subtitle_7"
              >
                추가
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
#serach_modal .title_divider {
  margin: 0 30px;
  width: calc(100% - 60px);
}
.scroll-disable {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
  touch-action: none;
}

/* 모달 */
.modal_wrap {
  border-radius: 8px !important;
  z-index: 99;
  min-width: 1000px;
  max-width: 1000px;
  height: 644px;
}
.modal_box {
  width: 90%;
  height: 90%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
}
.modal_head {
  width: 100%;
  padding: 30px 30px 20px;
  position: sticky;
  top: 0;
  left: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.modal_head .close_button {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal_body {
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* 모달 스크롤 */
.modal_wrap *::-webkit-scrollbar {
}
.modal_wrap *::-webkit-scrollbar-thumb {
  background: #dfe5ef;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  border-left: 6px solid #fff;
  border-right: 6px solid #fff;
  border-radius: 10px;
  /* border: 6px solid #fff;
  border-radius: 10rem; */
}
.modal_wrap *::-webkit-scrollbar-track {
  background: transparent;
}

/*!* 미니 PC 이하: 모달 공통 *!*/
/*@media screen and (max-width: 1080px) {*/
/*  .modal_box .modal_head {*/
/*    height: 72px;*/
/*    padding: 0px 25px;*/
/*  }*/
/*  .modal_head .close_button {*/
/*    width: 20px;*/
/*    height: 20px;*/
/*  }*/

/*  .modal_box .modal_body {*/
/*    height: calc(100% - 72px);*/
/*  }*/

/*  !* 모달 스크롤 *!*/
/*  .modal_wrap *::-webkit-scrollbar {*/
/*    width: 14px;*/
/*  }*/
/*  .modal_wrap *::-webkit-scrollbar-thumb {*/
/*    border-left: 4px solid #fff;*/
/*    border-right: 4px solid #fff;*/
/*  }*/
/*}*/

/* --------------------------- */
/* 품목검색 modal, 품목검색 modal 2 */
#serach_modal .modal_box {
  max-width: 1100px;
  max-height: 682px;
}
#serach_modal .modal_body {
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 2.4fr 1fr;
  /* box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33; */
}
/* 좌측 품목검색 */
.product_search_wrap {
  height: 100%;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.product_search_wrap .search_input {
  padding: 0 2px;
  margin-bottom: 13px;
  gap: 0px;
}
#serach_modal .search_input input {
  padding-right: 54px;
}
#serach_modal .search_input button#search {
  position: absolute;
  top: 0;
  right: 2px;
  width: 40px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
#serach_modal .search_input button#search img {
  width: 21px;
  height: auto;
}

#serach_modal .search_list {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  border: 1px #c1c8d4 solid;
  border-radius: 4px;
  background-color: white;
  z-index: 50;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 30px;
  max-height: calc(100% - 90px);
  overflow: scroll;
}

#serach_modal .search_list .search_item {
  height: 41px;
  text-align: left;
  padding: 10px;
}

#serach_modal .search_list .search_item.selected {
  text-align: left;
  margin: 2px 2px 0 2px;
  background: #1e64ff;
  opacity: 0.08;
}
#serach_modal .search_list .search_item:hover {
  background: rgba(30, 100, 255, 0.08);
  border-radius: 2px;
}

.product_search_wrap .product_depth_wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 0.82fr 0.82fr 0.82fr 1fr);
  flex: 1;
}
.product_depth_wrap .product_depth {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 162px;
}

.product_depth_header .depth_header {
  width: 100%;
  gap: 5px;
  height: 37px;
  margin-bottom: 6px;
}

.depth_header .depth_title {
  width: 143px;
  height: 37px;
  border: 1px #e7ecf4 solid;
  border-radius: 999px;
  text-align: center;
  padding: 8px;
}

.depth_header img {
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  text-align: center;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: auto;
}

#depth4.product_depth .depth_title {
  width: 100%;
}

.product_depth .depth_list {
  width: 100%;
  overflow-y: scroll;
  border-right: 1px solid #e7ecf4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 0;
}
#depth4.product_depth .depth_list {
  border-right: none;
}
.product_depth .depth_list .divide {
  width: 100%;
  height: 1px;
  background-color: #e7ecf4;
  flex-shrink: 0;
}
.product_depth .depth_list .depth_item {
  width: 100%;
  min-height: 28px;
  word-break: keep-all;
  color: #000;
  padding: 20px 10px;
  cursor: pointer;
  flex-shrink: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.product_depth .depth_list .depth_item:hover {
  background-color: #f9f9f9;
}
.product_depth .depth_list .depth_item.selected {
  background-color: #1e64ff14;
  color: #1e64ff;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

/* 우측 선택한 품목 */
.selected_wrap {
  overflow: scroll;
}
.selected_wrap {
  height: 100%;
  /* width: 256px; */
  border-left: 1px solid #dfe5ef;
}
.selected_wrap .selected_list_wrap {
  width: 100%;
  height: calc(100% - 138px);
  overflow-y: auto;
  /* padding: 0px 10px 0px 15px; */
  padding-left: 15px;
  padding-right: 30px;
}
.selected_list_wrap .whole_cancel_wrap {
  width: 100%;
  padding: 12px 0px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
}
.selected_list_wrap .selected_list {
  /* height: calc(100%); */
  padding-bottom: 13px;
  overflow-y: auto;
}
.selected_list .selected_item {
  position: relative;
  width: 100%;
  height: 136px;
  padding: 10px;
  border: 1px solid #e7ecf4;
  border-radius: 4px;
  /* gap: 16px; */
  margin-bottom: 10px;
}
.selected_list .selected_item .chk_box {
  padding: 0px;
  margin: 0px;
  width: 24px;
  height: 24px;
}
.selected_list .selected_item .chk_box .on {
  border-radius: 4px;
}
.selected_list .selected_item .item_info {
  height: 100%;
}
.selected_list .selected_item .item_info > div {
  width: 100%;
}
.selected_list .selected_item .item_info .text_12 {
  margin-top: 2px;
}
.selected_list .selected_item .close_button {
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
  margin-left: auto;
}
.selected_list .selected_item .close_button:hover {
  opacity: 0.8;
}

.selected_wrap .total_wrap {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 138px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 12px 24px -4px #919eab1f, 0px 0px 2px 0px #919eab33;
  padding: 30px 30px;
}

/* --------------------------- */
/* 품목검색 modal 2 */

.product_serach_wrap .product_info_box .product_info_box_title {
  margin-bottom: 4px;
}

.selected_info_wrap .img_amount_guide {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}
.amount_unit > span {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
}

/*!* 미니 PC 이하 : 품목검색 modal, 품목검색 modal 2 *!*/
/*@media screen and (max-width: 1080px) {*/
/*  .selected_list .selected_item {*/
/*    padding: 10px 20px;*/
/*  }*/
/*  .selected_list .selected_item .item_info > div {*/
/*    flex-direction: column-reverse;*/
/*    align-items: flex-start;*/
/*    justify-content: flex-start;*/
/*    margin-top: 2px;*/
/*  }*/
/*  .selected_list .selected_item .item_info .number_input_wrap {*/
/*    margin-top: 4px;*/
/*    height: 26px;*/
/*  }*/
/*}*/

#serach_modal .next_button {
  margin-top: 10px;
  height: 40px;
}
</style>
