<script setup>
import IndexContentNoCafe from "@/views/coffee/partials/IndexContentNoCafe.vue";
import Map from "@/components/coffee/Map.vue";
import CheckTitle from "@/views/coffee/CheckTitle.vue";
import QNABlock from "@/components/coffee/QNABlock.vue";
import Exclamation from "@/components/Exclamation.vue";
import NumberTitle from "@/views/coffee/NumberTitle.vue";

const props = defineProps({open: {type: Boolean}, cafe: {type: Object}})
const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}
</script>

<template>
  <div v-if="props.open" class="w-full h-screen flex fixed top-0 left-0 items-start justify-center z-50">
    <div class="bg-white px-5 pt-6 w-full h-[100dvh] max-w-md overflow-y-auto">
      <button class="mb-6" type="button" @click="close">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M3.20277 3.20277C3.47314 2.93241 3.91148 2.93241 4.18184 3.20277L12 11.0209L19.8182 3.20277C20.0885 2.93241 20.5269 2.93241 20.7972 3.20277C21.0676 3.47314 21.0676 3.91148 20.7972 4.18184L12.9791 12L20.7972 19.8182C21.0676 20.0885 21.0676 20.5269 20.7972 20.7972C20.5269 21.0676 20.0885 21.0676 19.8182 20.7972L12 12.9791L4.18184 20.7972C3.91148 21.0676 3.47314 21.0676 3.20277 20.7972C2.93241 20.5269 2.93241 20.0885 3.20277 19.8182L11.0209 12L3.20277 4.18184C2.93241 3.91148 2.93241 3.47314 3.20277 3.20277Z"
                fill="#111111"
                fill-rule="evenodd"/>
        </svg>
      </button>
      <div class="cf-head-3 mb-6">
        커피박 수거 이용 안내
      </div>
      <div class="cf-head-4 text-black mb-3">이용방법</div>
      <div class="mb-9">
        <NumberTitle class="mb-2">
          <template #number>1</template>
          <template #title>배출 업체 등록</template>
        </NumberTitle>
        <div class="cf-body-2 cf-text-gray-900">
          커피찌꺼기(커피박) 수거는 지구하다와 협약이 이루어진 지자체 지역 내 사업장을 대상으로 합니다. 업체 정보 등록 및 승인 완료 후 무상 수거 이용 가능합니다.
        </div>
      </div>
      <div class="mb-9">
        <NumberTitle class="mb-2">
          <template #number>2</template>
          <template #title>수거 가방에 커피찌꺼기(커피박) 담기</template>
        </NumberTitle>
        <div class="cf-body-2 cf-text-gray-900">
          커피박을 봉투 째 전용 수거 가방에 담아주세요. 전용 수거 가방을 받지 못하셨을 경우, 내용물이 보이는 투명 봉투에 담아주세요.
        </div>
      </div>
      <div class="mb-9">
        <div class="mb-2">

          <div class="absolute bottom-0 left-0 w-full h-[10px]"
               style="width:300px; background: var(--CF-Highlight, #B4FB39);"></div>
          <div class="flex items-center justify-start gap-x-2">
            <div class="circle">
              <div class="cf-title-4 text-white">
                !
              </div>
            </div>
            <div class="cf-title-4 cf-text-gray-900">
              업체 등록 완료 후 전용 수거 가방을 드립니다.
            </div>
          </div>
        </div>
        <div class="cf-body-2 cf-text-gray-900">
          <ul class="cf-ul">
            <li class="cf-body-2 cf-text-gray-900">
              업체 등록 완료 후 커피박 수거시 전용 수거 가방을 드릴 예정입니다.(제작 일정에 따라 배포 시기는 변경될 수 있어요.)
            </li>
            <li class="cf-body-2 cf-text-gray-900">
              커피박 외에 다른 이물질이 들어가지 않게 주의해주세요.
            </li>
            <li class="cf-body-2 cf-text-gray-900">
              수거가방 또는 포장 봉투가 파손되어 내용물이 쏟아진 경우 수거가 어려워 재신청이 필요합니다.
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-9">
        <!-- TODO 기획 확인 후 재수정-->
        <NumberTitle class="mb-2">
          <template #number>3</template>
          <template #title>배출 전 수거 신청하기</template>
        </NumberTitle>
        <div class="cf-body-2 cf-text-gray-900 mb-2">
          <ul class="cf-ul">
            <li class="cf-body-2 cf-text-gray-900">
              배출 신청 일시 및 수거 요일은 지역마다 다릅니다.
            </li>
            <li class="cf-body-2 cf-text-gray-900">
              업체 등록 후 지역별 조건을 확인하세요.
            </li>
          </ul>
        </div>
        <div class="acc-box">
          <div class="mb-5">
            <CheckTitle>
              <template #title>수거 신청은 하루 전 00시 ~ {{ props.cafe.data.region.dispose_time.slice(0, 5) }} 사이 가능</template>
              <template #body></template>
            </CheckTitle>
          </div>
          <div>
            <CheckTitle>
              <template #title>수거는 {{ props.cafe.data.region.week_days.label.join(', ') }}에 진행</template>
              <template #body>토요일, 일요일, 공휴일에는 수거를 진행하지 않습니다.</template>
            </CheckTitle>
          </div>
        </div>
      </div>
      <div class="mb-9">
        <NumberTitle class="mb-2">
          <template #number>4</template>
          <template #title>배출 방법</template>
        </NumberTitle>
        <div class="cf-body-2 cf-text-gray-900 mb-1">
          수거일에 맞춰 등록한 배출 장소에 커피박을 배출해 주세요. (매장 내부, 매장 앞 등등)
        </div>
        <Exclamation>
          기사님이 방문해 약속된 장소에서 커피박 수거 후 전용 가방을 그 장소에 두고 옵니다. 잊지 말고 꼭 회수 부탁드립니다.
        </Exclamation>
      </div>
      <div class="mb-9">
        <NumberTitle class="mb-2">
          <template #number>5</template>
          <template #title>수거 완료</template>
        </NumberTitle>
        <div class="cf-body-2 cf-text-gray-900 mb-1">
          수거 완료시 알림톡(문자)이 발송됩니다.
        </div>
      </div>
      <div class="cf-head-4 text-black mb-3">FAQ</div>
      <QNABlock>
        <template #q>수거 비용은 얼마인가요?</template>
        <template #a>지구하다는 지자체와의 협약을 통해 무상으로 수거를 해드리고 있습니다.</template>
      </QNABlock>
    </div>
  </div>
</template>

<style scoped>

.circle {
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: black;
}

.acc-box {
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--CF-Gray-200, #EFEFEF);
  background: var(--CF-Gray-160, #F5F5F5);
}
</style>