export const bankList = [
  { id: "002", name: "KDB산업은행" },
  { id: "003", name: "IBK기업은행" },
  { id: "004", name: "KB국민은행" },
  { id: "007", name: "수협은행" },
  { id: "011", name: "NH농협은행" },
  { id: "012", name: "농협중앙회(단위농축협)" },
  { id: "020", name: "우리은행" },
  { id: "023", name: "SC제일은행" },
  { id: "027", name: "한국씨티은행" },
  { id: "031", name: "대구은행" },
  { id: "032", name: "부산은행" },
  { id: "034", name: "광주은행" },
  { id: "035", name: "제주은행" },
  { id: "037", name: "전북은행" },
  { id: "039", name: "경남은행" },
  { id: "045", name: "새마을금고중앙회" },
  { id: "048", name: "신협중앙회" },
  { id: "050", name: "저축은행중앙회" },
  { id: "064", name: "산림조합중앙회" },
  { id: "071", name: "우체국" },
  { id: "081", name: "하나은행" },
  { id: "088", name: "신한은행" },
  { id: "089", name: "케이뱅크" },
  { id: "090", name: "카카오뱅크" },
  { id: "092", name: "토스뱅크" },
  { id: "218", name: "KB증권" },
  { id: "238", name: "미래에셋대우" },
  { id: "240", name: "삼성증권" },
  { id: "243", name: "한국투자증권" },
  { id: "247", name: "NH투자증권" },
  { id: "261", name: "교보증권" },
  { id: "262", name: "하이투자증권" },
  { id: "263", name: "현대차증권" },
  { id: "264", name: "키움증권" },
  { id: "265", name: "이베스트투자증권" },
  { id: "266", name: "SK증권" },
  { id: "267", name: "대신증권" },
  { id: "269", name: "한화투자증권" },
  { id: "271", name: "토스증권" },
  { id: "278", name: "신한금융투자" },
  { id: "279", name: "DB금융투자" },
  { id: "280", name: "유진투자증권" },
  { id: "287", name: "메리츠증권" },
];
