<script setup>
import IndexContentNoCafe from "@/views/coffee/partials/IndexContentNoCafe.vue";

const props = defineProps({open: {type: Boolean}, cafe: {type: Object}})
const emits = defineEmits(['close'])

const close = () => {
  emits('close')
}
</script>

<template>
  <div v-if="props.open" class="w-full h-screen flex fixed top-0 left-0 items-start justify-center z-50">
    <div class="bg-white px-5 pt-6 w-full h-[100dvh] max-w-md overflow-y-auto">
      <button type="button" @click="close" class="mb-6">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd" d="M3.20277 3.20277C3.47314 2.93241 3.91148 2.93241 4.18184 3.20277L12 11.0209L19.8182 3.20277C20.0885 2.93241 20.5269 2.93241 20.7972 3.20277C21.0676 3.47314 21.0676 3.91148 20.7972 4.18184L12.9791 12L20.7972 19.8182C21.0676 20.0885 21.0676 20.5269 20.7972 20.7972C20.5269 21.0676 20.0885 21.0676 19.8182 20.7972L12 12.9791L4.18184 20.7972C3.91148 21.0676 3.47314 21.0676 3.20277 20.7972C2.93241 20.5269 2.93241 20.0885 3.20277 19.8182L11.0209 12L3.20277 4.18184C2.93241 3.91148 2.93241 3.47314 3.20277 3.20277Z"
              fill="#111111"
              fill-rule="evenodd"/>
      </svg>
      </button>
      <div class="cf-head-3 mb-6">
        커피박 수거 이용 안내
      </div>
      <IndexContentNoCafe :cafe="props.cafe"/>
    </div>
  </div>
</template>

<style scoped>

</style>