<script setup>
import { ref, onMounted } from "vue";
import { useRequestStore } from "@/stores/requestStore";
import BlwSelectPaymentPopup from "@/components/Popup/BlwSelectPaymentPopup.vue";
import { onBeforeRouteLeave } from "vue-router";
import { useLayoutStore } from "@/stores/layoutStore";

const requestStore = useRequestStore();
const layoutStore = useLayoutStore();

const showPaymentPopup = ref(false);

const hidePaymentPopup = () => {
  showPaymentPopup.value = false;
};

function getItemString() {
  const list = requestStore.itemList;

  if (list.length > 1) {
    return `${list[0].categoryName} 외 ${list.length - 1}건`;
  } else {
    return list[0].categoryName;
  }
}

function getPrice() {
  const list = requestStore.itemList;
  let price = 0;

  for (const item of list) {
    price += item.price * item.quantity;
  }

  return price;
}

function clickRequestBtn() {
  showPaymentPopup.value = true;
}

// onBeforeRouteLeave((to, from, next) => {
//   if (layoutStore.isShowConfrimPopup === true) {
//     next(false);
//   }

//   if (from.path === "/blw-check-info" && showPaymentPopup.value == true) {
//     layoutStore.showConfirmPopup({
//       message: "결제창을 나가시겠습니까?",
//       description:
//         "결제창을 나가시면 결제가 정상적으로 종료되지 않습니다.\n나가시겠습니까?",
//       okayAction: () => {
//         layoutStore.closeConfirmPopup();
//         hidePaymentPopup();
//         next(false);
//       },
//       cancelAction: () => {
//         layoutStore.closeConfirmPopup();
//         next(false);
//       },
//     });
//   } else {
//     next();
//   }
// });
</script>

<template>
  <blw-select-payment-popup
    :show-popup="showPaymentPopup"
    @hide-popup="hidePaymentPopup">
  </blw-select-payment-popup>
  <div id="check_info">
    <div class="page_container flex flex_col justify_start align_center">
      <div class="single-content-page-wrap">
        <p class="caption_1 color_secondary">대형 생활 폐기물</p>
        <p class="subtitle_3 g900" style="margin-bottom: 60px">
          배출 정보 확인
        </p>
        <p class="subtitle_7 color_secondary" style="margin-bottom: 40px">
          입력하신 정보를 확인해 주세요.
        </p>

        <div class="input_row" style="margin-bottom: 30px">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label class="input_label label_2">배출일</label>
              <input
                class="input body_2"
                type="text"
                name="date"
                id="date"
                :value="requestStore.disposeDate"
                readonly />
            </div>
          </div>
        </div>
        <div class="input_row" style="margin-bottom: 30px">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label class="input_label label_2">배출 장소</label>
              <input
                class="input body_2"
                type="text"
                name="location"
                id="location"
                :value="requestStore.address"
                style="margin-bottom: 4px"
                readonly />
              <input
                class="input body_2"
                type="text"
                name="locationDetail"
                id="locationDetail"
                :value="requestStore.addressDetail"
                readonly />
            </div>
          </div>
        </div>
        <div class="input_row" style="margin-bottom: 46px">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label class="input_label label_2">배출 품목</label>
              <input
                class="input body_2"
                type="text"
                name="item"
                id="item"
                :value="getItemString()"
                readonly />
            </div>
          </div>
        </div>

        <div
          class="flex flex_row justify_space_between align_center"
          style="margin-bottom: 60px">
          <span class="subtitle_7 color_secondary">총 합계 금액</span>
          <div>
            <span class="subtitle_5 color_primary">{{
              $formatNumber(getPrice())
            }}</span>
            <span class="subtitle_7 color_secondary" style="margin-left: 6px"
              >원</span
            >
          </div>
        </div>

        <button class="request_btn" @click="clickRequestBtn">신청하기</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.single-content-page-wrap {
  padding-top: 60px;
}

.request_btn {
  width: 100%;
  height: 50px;
  font-size: 18px;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  background-color: #1e64ff;
  margin-bottom: 100px;
}
</style>
