<script setup>
import { computed } from "vue";

const props = defineProps({
  showPopup: Boolean,
});

const emit = defineEmits(["hidePopup"]);

const showPopup = computed(() => props.showPopup);

const onClickClosePopup = () => {
  emit("hidePopup");
};
</script>

<template>
  <v-dialog v-model="showPopup" class="dialog_center" style="width: 320px">
    <v-card class="modal_wrap" elevation="0">
      <div class="modal_head">
        <button @click="onClickClosePopup" class="close_button">
          <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
        </button>
      </div>
      <v-container class="pa-0 ma-0">
        <div class="modal_box flex flex_col justify_center align_center center">
          <p class="subtitle_5 color_secondary">지구하다 APP 다운로드</p>
          <p class="body_2 color_caption">
            휴대폰의 카메라 기능을 실행해 QR 코드를<br />스캔하면 앱을
            다운로드할 수 있어요!
          </p>
          <img src="@/assets/image/img_qrcode.svg" />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.modal_wrap {
  border-radius: 8px !important;
  z-index: 99;
  min-width: 320px;
  max-width: 320px;
}

.modal_head {
  padding: 20px 20px 0px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  background: #fff;
  z-index: 200;
}

.modal_head .close_button {
  width: 20px;
  height: 20px;
}

.modal_box {
  padding: 20px;
  gap: 10px;
}

.modal_box img {
  width: 100px;
  height: 100px;
  margin: 20px 0 30px;
}
</style>
