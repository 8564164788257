<script setup>
import {extractNumbers, validatePhoneNumber} from "@/utils/utils.js";
import {getCurrentInstance, ref, watch, computed, nextTick} from "vue";
import {checkSimpleAuthNo, requestSimpleAuth} from "@/api/commons.js";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";


const props = defineProps({
  contact: {
    type: String,
    required: false,
    default: ''
  }
})

const emits = defineEmits(['setContact'])

// data ref
const contactInputValue = ref(props.contact)
const authCode = ref('')
const authRemainSeconds = ref(180)
const {proxy} = getCurrentInstance();
const timer = ref(null)
const authRemainSecondsMin = computed(() => {
  return Math.floor(authRemainSeconds.value / 60)
})
const authRemainSecondsSec = computed(() => {
  let digits = authRemainSeconds.value % 60
  return digits.toString().padEnd(2, '0')
})

// template ref
const contactRef = ref(null)
const authCodeRef = ref(null)
const isAuthCodeFocused = ref(false)

const setContact = (event) => {
  contactInputValue.value = extractNumbers(event.target.value)
}


const authData = ref(null)
const authFailed = ref(false)
// const authFailed = ref(true)
const requestAuthCode = async () => {
  // 인증번호 요청
  if (validatePhoneNumber(contactInputValue.value) === false) {
    showError()
    contactRef.value.focus()
    return
  }
  authData.value = await requestSimpleAuth(contactInputValue.value)
}

const setAuthCode = (event) => {
  let value = extractNumbers(event.target.value).substring(0, 6)
  authCode.value = value
  event.target.value = value
}

const resetAuth = () => {
  authFailed.value = false
  if (timer.value) {
    clearInterval(timer.value)
  }
  authData.value = null
  authRemainSeconds.value = 180
}

watch(authData, (newValue, oldValue) => {
  // if (newValue?.invalidate_at === undefined) return
  if (newValue?.id !== oldValue?.id && newValue?.invalidate_at) {
    let current = proxy.$dayjs()
    let invalidatedAtDayjs = proxy.$dayjs(newValue.invalidate_at)
    authRemainSeconds.value = invalidatedAtDayjs.diff(current, 'second')
    timer.value = setInterval(() => {
      authRemainSeconds.value -= 1
      if (authRemainSeconds.value <= 0) {
        resetAuth()
      }
    }, 1000)
  }
}, {deep: true})

const showError = () => {
  authFailed.value = true
}

const alertIsShown = ref(false)
const alertTitle = ref('')
const alertBody = ref('')

const showAlert = (title, body) => {
  alertTitle.value = title
  alertBody.value = body
  alertIsShown.value = true
}

const closeAlert = () => {
  alertIsShown.value = false
  nextTick(() => {
    alertTitle.value = ''
    alertBody.value = ''
  })
}

const showSmsInfo = () => {
  showAlert(
      '인증번호 메시지를 받지 못하셨나요?',
        '1660-3114번으로 문자가 발송되며,\n' +
      '휴대전화의 차단 메세지함을 확인하거나\n' +
      '가입한 통신사로 문자 차단 서비스를\n' +
      '이용하고 있는지 확인해주세요.'
  )
}

const validateAuthCode = async () => {
  authFailed.value = false
  if (extractNumbers(authCode.value).length !== 6) {
    showError()
    authCodeRef.value.focus()
    return
  }
  let result = await checkSimpleAuthNo(authData.value.id, authCode.value)
  if (result.success === true) {
    authData.value = result.data
    if (timer.value) {
      clearInterval(timer.value)
    }
    emits('setContact', contactInputValue.value)
  } else {
    showError()
  }
}

const authNoInputVisible = computed(() => {
  return authData.value !== null && authData?.value?.auth_at === null
  // return true
})

const cellphoneVisibility = computed(() => {
  return authData.value !== null && authData?.value?.auth_at === null || authData?.value?.auth_at
})
/*
인증번호 메시지를 받지 못하셨나요?
02-6907-1042번으로 문자가 발송되며, 휴대전화의 차단 메세지함을 확인하거나 가입한 통신사로 문자 차단 서비스를 이용하고 있는지 확인해 주세요.​​
*/

</script>

<template>
  <div class="mb-[36px]">
    <!--휴대전화 입력 인풋 시작-->
    <div class="mb-[4px]">
      <div class="mb-[8px] flex justify-between items-end">
        <label class="input-header mb-0" for="contact">휴대전화번호</label>
        <div class="input-header-help select-none cursor-pointer" @click="showSmsInfo">인증번호 문제 안내</div>
      </div>
      <div class="flex w-full gap-x-[8px]">
        <input id="contact" ref="contactRef" :readonly="cellphoneVisibility"
               class="input" inputmode="numeric" name="contact"
               pattern="[0-9]*" placeholder="휴대전화번호 입력" type="text" @input="setContact"/>
        <template v-if="authData === null">
          <button class="light-btn" type="button" @click="requestAuthCode">인증</button>
        </template>
        <template v-else-if="authData?.auth_at">
          <button class="light-btn" disabled type="button">인증됨</button>
        </template>
        <template v-else>
          <button class="light-btn" type="button" @click="resetAuth">재인증</button>
        </template>
      </div>
    </div>
    <!--휴대전화 입력 인풋 끝-->
    <!--인증번호 인풋 시작-->
    <div v-if="authNoInputVisible">
      <label class="sr-only" for="authCode">인증번호</label>
      <div class="flex w-full gap-x-[8px]">
        <div class="flex w-full">
          <input id="authCode" ref="authCodeRef" :readonly="authData?.auth_at" :value="authCode"
                 autocomplete="off" class="input auth-no-input flex-grow" inputmode="numeric" name="authCode"
                 pattern="[0-9]*" placeholder="인증번호 입력" type="text" @blur="isAuthCodeFocused = false"
                 @focus="isAuthCodeFocused = true" @input="setAuthCode"/>
          <div :class="isAuthCodeFocused ? 'auth-no-timer-focus':''" class="auth-no-timer">
            <div class="w-full">{{ authRemainSecondsMin }}:{{ authRemainSecondsSec }} 남음</div>
          </div>
        </div>
        <template v-if="authData?.auth_at">
          <button class="light-btn" disabled type="button">인증완료</button>
        </template>
        <template v-else>
          <button class="light-btn mt-[4px]" type="button" :disabled="authCode.length !== 6" @click="validateAuthCode">확인</button>
        </template>
      </div>
      <template v-if="authFailed">
        <div class="error-text">인증번호를 확인해주세요.</div>
      </template>
    </div>
    <!--인증번호 인풋 끝-->
  </div>
  <CoffeeAlert :body="alertBody" :show="alertIsShown" :title="alertTitle" @close="closeAlert"/>
</template>

<style scoped>
.auth-no-input {
  border-right: 0;
  border-radius: 8px 0 0 8px;
  flex-grow: 1;
}

.auth-no-input:focus {
  outline: none;
  border-right: 0;
}

.auth-no-timer {
  color: #111;
  text-align: right;
  font-family: NanumSquare, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  height: 52px;
  padding: 12px;
  padding-left: 4px;
  border-radius: 0 8px 8px 0;
  border: 1px solid #DFDFDF;
  border-left: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  user-select: none;
  white-space: nowrap;
}

.auth-no-timer-focus {
  outline: none;
  border: 1px solid #3182FF;
  border-left: 0;
}

.error-text {
  padding-left: 12px;
  color: #F82719;
  font-family: NanumSquare, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
</style>