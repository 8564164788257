<script setup>

import "../assets/tailwind.css"
import { useEventListener } from "@vueuse/core";
import { useRoute } from "vue-router";
import { onMounted, watch } from "vue";
import { getCafe } from "@/api/coffee.js";
import { useCoffeeStore } from "@/stores/coffeeStore.js";
import Footer from "@/components/coffee/Footer.vue";
import LoadingSpinner from "@/components/coffee/LoadingSpinner.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore.js";
import { useLayoutStore } from "@/stores/layoutStore";
const coffeeStore = useCoffeeStore()
const { onLoading } = storeToRefs(coffeeStore)
const route = useRoute()
const userStore = useUserStore()
const layoutStore = useLayoutStore()
import router from "@/router/index";
import { ref } from "vue";
import { onBeforeUnmount } from "vue";

const { access } = storeToRefs(userStore)
const tokenTimer = ref(null);


const loadCafe = async () => {
  coffeeStore.setLoading(true)
  await getCafe()
  coffeeStore.setLoading(false)
}

watch(route, async (newRoute, newAccess) => {
  await loadCafe()
})

// access 토큰이 provider 로 부터 수신되면 cafe 정보를 가져옵니다.
watch(access, async (newAccess, oldAccess) => {
  if (tokenTimer.value !== null) {
    clearTimeout(tokenTimer.value)
    await loadCafe()
  }
})

// mount 시점으로 token provider 로부터 10초간 대기 후 없을경우 에러 처리
onMounted(async () => {
  // ios 웹뷰에서 실행될 때 onMount 이전에 provideZguhadaToken 이벤트가 발생하지 않는 경우가 있음
  // access 값이 존재할때까지 기다리도록 함.
  coffeeStore.setLoading(true)
  if (access.value !== null) {
    await loadCafe()
  } else {
    tokenTimer.value = setTimeout(() => {
      if (access.value === null) {
        coffeeStore.setLoading(false)
        layoutStore.showErrorPopup("토큰 수신 시간이 만료되었습니다. 고객센터에 문의해주세요.");
        router.push("/");
      }
    }, 10000)
  }

})

// unmount 시점에 timer clear
onBeforeUnmount(() => {
  if (tokenTimer.value !== null) {
    clearTimeout(tokenTimer.value)
  }
})

// TODO 이거 작동하는거 맞아?
// App.vue 에서 호출하는 코드여서 따로 작성 불필요합니다.
// useEventListener(window, 'provideZguhadaToken', provideZguhadaToken);

// function provideZguhadaToken(event) { // token verify check
//   userStore.provideToken(
//       event.detail.access,
//       event.detail.refresh,
//   )
// }
</script>

<template>
  <LoadingSpinner />
  <div id="coffeeMainLayout"
    :class="['flex', 'w-full', 'items-center', 'justify-center', 'pt-[60px]', route.meta.showFooter === true ? 'pb-[99px]' : '']">
    <div id="coffeeSubLayout" class="w-full max-w-md px-5">
      <router-view />

    </div>
  </div>
  <Footer v-if="route.meta.showFooter === true" />
</template>

<style scoped></style>