import {getCafe} from "@/api/coffee.js";
import router from "@/router/index.js";
import dayjs from "dayjs";

const navigateTo = async (routeName) => {
    let cafeData = await getCafe()
    // return {success: true, callback: async () => {await router.push({name: routeName})}}
    if (routeName === 'coffee-request-collection') {
        console.log(cafeData)
        if (cafeData?.type !== 'cafe') {
            return {
                success: false,
                message: '업체 정보 등록 후에 수거신청이 가능합니다.',
                callback: async () => {
                    await router.push({name: 'coffee-request-register'})
                    // await navigateTo('coffee-request-register')
                }
            }
        } else {
            return {
                success: true,
                callback: async () => {
                    await router.push({name: routeName})
                }
            }
        }
    } else if (routeName === 'coffee-request-register') {
        if (cafeData?.type === 'cafe') {
            return {
                success: false,
                message: [`이미 [${cafeData.data.name}]가 등록되어 있습니다.`, '배출 신청 화면으로 이동합니다.'],
                callback: async () => {await router.push({name: 'coffee-request-collection'})}
            }
        } else {
            return {success: true, callback: async () => {await router.push({name: routeName})}}
        }
    } else if (routeName === 'close') {
        // TODO 창 닫는 로직
        return {success: true, callback: async () => {console.log('close callback')}}
    } else {
        return {success: true, callback: async () => {await router.push({name: routeName})}}
    }
}

const dateParser = (date) => {
    let dates = date.split('-').map(d => parseInt(d))
    return `${dates[0]}년 ${dates[1]}월 ${dates[2]}일`
}

const timeParser = (time) => {
    let times = time.split(':')
    let hour = parseInt(times[0])
    let ampm = hour > 12 ? '오후' : '오전'
    hour = hour > 12 ? hour - 12 : hour
    let minute = parseInt(times[1])
    return minute === 0 ? `${ampm} ${hour}시` : `${ampm} ${hour}시 ${minute}분`
}

const serverDatetimeParser = (datetime) => {
    let dd = dayjs(datetime)
    return `${dd.format('YYYY년 M월 D일')} ${dd.format('H시 m분')}`
}
const workingHourParser = (week) => {
    if (week === null) {return '휴무'}
    return `${timeParser(week.open_time)} ~ ${timeParser(week.close_time)}`
}
export {
    navigateTo, workingHourParser, timeParser, dateParser, serverDatetimeParser
}