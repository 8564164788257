<script setup>
import { ref, computed, onMounted } from "vue";
import BlwItemSearchPopup from "@/components/Popup/BlwItemSearchPopup.vue";
import { useRequestStore } from "@/stores/requestStore";
import BlwSelectPaymentPopup from "@/components/Popup/BlwSelectPaymentPopup.vue";
import BlwSideNavgiation from "@/components/BlwSideNavigation.vue";
import { getImageUploadUrl, putImageUpload } from "@/api/request";
import { sendErrorLogToSentry } from "@/utils/utils";

const MAX_IMAGE_COUNT = 8;

const requestStore = useRequestStore();

const showItemSearchPopup = ref(false);
const showPaymentPopup = ref(false);

/*
  {
    imageUid: response.uid,
    imageUrl: url link,
    file: file,
    extension: extension,
  }
*/
const disposeImageList = ref([]);
const disposeImageCount = computed(() => disposeImageList.value.length);

const isValidRequest = computed(() => {
  return requestStore.itemList.length > 0;
});

const hideItemSearchPopup = () => {
  showItemSearchPopup.value = false;
};

const hidePaymentPopup = () => {
  showPaymentPopup.value = false;
};

const navigatorItemTitleField = computed(() => {
  return requestStore.getNavigatorItemTitle();
});

// function

function onClickAddItem() {
  showItemSearchPopup.value = true;
}

function onClickRequestButton() {
  requestStore.imageList = disposeImageList.value;
  showPaymentPopup.value = true;
}

function onClickRemoveItem(targetItemId) {
  requestStore.removeItemFormList(targetItemId);
}

function onClickChangeQuantityButton(isPlus, itemId) {
  requestStore.changeQuantityButton(isPlus, itemId);
}

function onClickPreviousButton() {
  // router.go(-1);
  history.back();
}

// image 관련

function onClickDeleteImage(index) {
  if (confirm("해당 사진을 삭제하겠습니까?")) {
    disposeImageList.value.splice(index, 1);
  }
}

function onClickAddImage(event) {
  if (disposeImageList.value.length + 1 > MAX_IMAGE_COUNT) {
    alert("이미지는 최대 8장 까지 업로드 가능합니다.");
    return;
  }

  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    createImagePreview(file);
  }
}

async function createImagePreview(file) {
  const reader = new FileReader();
  reader.onload = async (e) => {
    const extension = file.name.split(".").pop().toLowerCase();
    try {
      const response = await requestGetImageUploadUrl(extension, file);

      disposeImageList.value.push({
        imageUid: response.uid,
        imageUrl: response.download_url,
        file: file,
        extension: extension,
      });
    } catch (error) {
      alert(
        `이미지 업로드 중 오류가 발생했습니다. 이미지를 다시 업로드해주세요.\n${error.message}`
      );
      sendErrorLogToSentry(error);
      throw error;
    }
  };
  reader.readAsDataURL(file);
}

function setPrevData() {
  if (requestStore.imageList.length > 0) {
    disposeImageList.value = requestStore.imageList;
  }
}

// api request
async function requestGetImageUploadUrl(extension, file) {
  try {
    const response = await getImageUploadUrl(extension);

    const imageUid = response.uid;
    const upload_url = response.upload_url;

    const uploadResponse = await putImageUpload(upload_url, file);

    return response;
  } catch (error) {
    sendErrorLogToSentry(error);
    throw error;
  }
}
</script>

<template>
  <blw-item-search-popup
    :showPopup="showItemSearchPopup"
    @hide-popup="hideItemSearchPopup"></blw-item-search-popup>
  <blw-select-payment-popup
    :show-popup="showPaymentPopup"
    @hide-popup="hidePaymentPopup">
  </blw-select-payment-popup>
  <div id="pc_Service_03">
    <!-- <div class="service_head bg_white">
      <div
        class="service_head_wrap flex flex_row justify_space_between align_center"
      >
        <div class="service_head_left flex justify_start align_center">
          <label class="service_switch">
            <span class="first text_18 blue_grey semi_bold left"
              >대형 생활 폐기물</span
            >
            <input role="switch" type="checkbox" />
            <span class="text_18 blue_grey_light semi_bold left"
              >공사장 생활 폐기물</span
            >
          </label>
        </div>
        <div class="service_head_right flex justify_end align_center">
          <button
            class="service_button text_18 flex justify_center align_center"
          >
            방문 수거
          </button>
          <button
            class="service_button active text_18 flex justify_center align_center"
          >
            배출 신고
          </button>
        </div>
      </div>
    </div> -->

    <!-- 본문 -->
    <div class="page_container flex flex_col justify_start align_center">
      <div class="page_wrap">
        <!-- flex flex_col justify_start align_end -->
        <div class="content_layout flex flex_row justify_end align_start">
          <!-- 좌측 레이어 -->
          <div class="left_wrap">
            <p class="subtitle_7 color_secondary left page_sub_title">
              대형 생활 폐기물
            </p>
            <p class="head_2 left page_title">배출신고</p>
            <!-- step -->
            <div class="tag_wrap flex flex_row justify_start align_center">
              <span class="tag body_3 flex justify_center align_center"
                >배출일 선택</span
              >
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">" />
              <span class="tag body_3 flex justify_center align_center"
                >배출정보 입력</span
              >
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">" />
              <span class="tag active body_3 flex justify_center align_center"
                >배출품목 선택</span
              >
            </div>

            <p class="mb-10 subtitle_5 color_secondary left">
              폐기물 배출 품목을 선택해 주세요.
            </p>

            <!-- 배출 품목 list -->
            <p class="sub_title label_2 color_secondary left">배출 품목</p>
            <div class="flex flex_col product_list">
              <div
                v-for="item in requestStore.itemList"
                class="product flex flex_row justify_space_between align_end">
                <div class="grow" style="max-width: 100%">
                  <div class="flex flex_row" style="margin-bottom: 6px">
                    <v-spacer />
                    <button
                      @click="() => onClickRemoveItem(item.id)"
                      class="close_button">
                      <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
                    </button>
                  </div>

                  <p
                    class="product_title subtitle_5 color_secondary ellipsis left">
                    {{ item.categoryName }}
                  </p>
                  <p class="body_2 color_caption left ellipsis">
                    {{ item.name }}
                  </p>
                  <div class="product_item_box_01">
                    <div class="number_input_wrap align_center">
                      <button
                        class="minus"
                        @click="
                          () => onClickChangeQuantityButton(false, item.id)
                        ">
                        <img src="@/assets/icon/ic_minus.svg" alt="-" />
                      </button>
                      <input
                        class="count caption_1 color_secondary"
                        min="0"
                        name="count"
                        type="number"
                        v-model="item.quantity"
                        disabled />
                      <button
                        class="plus"
                        @click="
                          () => onClickChangeQuantityButton(true, item.id)
                        ">
                        <img src="@/assets/icon/ic_plus.svg" alt="+" />
                      </button>
                    </div>
                    <p class="subtitle_5 color_secondary right">
                      {{ $formatNumber(item.price) }}
                      <span class="subtitle_7 color_secondary"> 원 </span>
                    </p>
                  </div>
                </div>

                <div class="flex flex_col justify_end align_end"></div>
              </div>
            </div>

            <!-- 배출 품목 추가하기 -->
            <div
              class="area_add_product flex flex_row justify_center align_center cusor_point"
              @click="onClickAddItem">
              <img src="@/assets/icon/ic_small_add.svg" alt="+" />
              <span class="subtitle_7 color_caption center"
                >배출 품목 추가하기</span
              >
            </div>

            <!-- 배출 품목 이미지 -->
            <div class="sub_title label_2 color_secondary left">
              배출 품목 이미지&nbsp;({{ disposeImageCount }}/{{
                MAX_IMAGE_COUNT
              }})
            </div>

            <div class="image_container">
              <!-- 이미지 추가 버튼 -->
              <input
                id="upload-image"
                type="file"
                ref="fileInput"
                @change="onClickAddImage"
                accept="image/*"
                hidden />
              <label for="upload-image">
                <div
                  class="area_add_image flex flex_col justify_center align_center"
                  style="cursor: pointer">
                  <img src="@/assets/icon/ic_camera.svg" alt="이미지 추가" />
                  <span class="subtitle_7 color_caption center"
                    >이미지 추가</span
                  >
                </div>
              </label>

              <!-- 이미지 칸 -->
              <template v-for="(imageItem, idx) in disposeImageList">
                <div class="image_item_container">
                  <img
                    class="image_item"
                    :src="imageItem.imageUrl"
                    alt="이미지" />
                  <div class="image_item_dim"></div>
                  <button
                    class="delete_button"
                    @click="() => onClickDeleteImage(idx)">
                    <img
                      class="x_icon"
                      src="@/assets/icon/ic_delete.svg"
                      alt="닫기" />
                  </button>
                </div>
              </template>
            </div>
          </div>
          <!-- 우측 레이어 -->
          <blw-side-navgiation></blw-side-navgiation>
        </div>

        <!-- 하단 버튼 -->
        <div class="content_layout flex flex_row justify_end align_start">
          <div class="left_wrap">
            <div class="flex justify_center align_center">
              <button
                id="next_button"
                class="prev_button label_1 g200"
                @click="onClickPreviousButton">
                이전
              </button>
              <button
                @click="onClickRequestButton"
                :disabled="!isValidRequest"
                class="next_button label_1">
                신청하기
              </button>
            </div>
          </div>
          <div class="empty_wrap" style="height: 0; max-height: 0"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.left_wrap {
  margin-bottom: 6ex;
}
#pc_Service_03 .product_list {
  width: 100%;
  gap: 20px;
}
#pc_Service_03 .product_list .product {
  position: relative;
  width: 100%;
  height: 168px;
  border: 1px solid #e7ecf4;
  border-radius: 8px;
  padding: 20px;
}
.product_list .product .product_title {
  margin-bottom: 6px;
}

.product_list .product .img_wrap {
  margin-top: 10px;
  gap: 6px;
}
.product .img_wrap .img_box {
  width: 52px;
  height: 52px;
  border: 1px solid #7c8fac;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.product .img_wrap .img_box img {
  width: 20px;
  height: 20px;
}
.product .img_wrap .img_box:hover {
  background-color: #f9f9f9;
}

/* 배출신고_pc_Service_03 */
/* .product_list .product .number_input_wrap {
  margin-bottom: 18px;
} */
/* 방문신고_pc_Service_03 */
.product_list .product .product_badge {
  margin-bottom: 18px;
  width: 75px;
  height: 30px;
  border: 1px solid #c1c8d4;
  border-radius: 4px;
}

.product_list .product .close_button {
  width: 20px;
  height: 20px;
}
.product_list .product .close_button:hover {
  opacity: 0.8;
}

.sub_title {
  padding-bottom: 6px;
}

#pc_Service_03 .area_add_product {
  width: 100%;
  min-height: 50px;
  background-color: #f3f6fa;
  border-radius: 4px;
  border: 1px #c1c8d4 solid;
  gap: 3px;
  margin-bottom: 30px;
  margin-top: 20px;
}
#pc_Service_03 .area_add_product img {
  width: 21px;
  height: 21px;
}
/* .image-item {
  width: 106px;
  height: 106px;;
} */

#pc_Service_03 .area_add_image {
  min-height: 106px;
  min-width: 106px;
  border-radius: 4px;
  background-color: #f3f6fa;
  gap: 4px;
  border: 1px #c1c8d4 solid;
}
#pc_Service_03 .image_item_container {
  min-height: 106px;
  min-width: 106px;
  max-height: 106px;
  max-width: 106px;
}
#pc_Service_03 .image_item_container .image_item {
  border-radius: 4px;
  object-fit: contain;
}

#pc_Service_03 .image_item_container .image_item_dim {
  width: 106px;
  height: 106px;
  border-radius: 4px;
  background-color: black;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
}

.image_item_container .delete_button {
  position: absolute;
  top: 6px;
  right: 6px;
}

.image_item_container .delete_button:hover {
  opacity: 0.8;
}

#pc_Service_03 .area_add_image img {
  width: 21px;
  height: 21px;
}

#pc_Service_03 .image_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
  gap: 10px;
}

#pc_Service_03 .product_item_box_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* 미니 PC 이하 */
@media screen and (max-width: 1080px) {
  #pc_Service_03 .product_list {
    gap: 14px;
  }
  #pc_Service_03 .product_list .product {
    height: 160px;
    padding: 20px 16px 26px 20px;
  }
  .product_list .product .img_wrap {
    gap: 4px;
  }
  .product .img_wrap .img_box {
    width: 46px;
    height: 46px;
  }
  .product .img_wrap .img_box img {
    width: 16px;
    height: 16px;
  }

  .product_list .product .number_input_wrap,
  .product_list .product .product_badge {
    margin-bottom: 12px;
  }

  #pc_Service_03 .area_add_product {
    margin-top: 14px;
    min-height: 160px;
  }
}

.next_button {
  width: 100%;
  color: white;
  background-color: #1e64ff;
  border-radius: 4px;
  height: 50px;
  margin-left: 10px;
}
.next_button:disabled {
  width: 100%;
  color: #7a7a7a;
  background-color: #ededed;
  border-radius: 4px;
  height: 50px;
  margin-left: 10px;
}

.scroll-disable {
  overflow: hidden;
}
</style>
