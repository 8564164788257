<script setup>
import {ref, computed, onBeforeMount, watch, Transition, onMounted, onUnmounted} from 'vue'
import {checkDisposeAvailable, getCafe} from "@/api/coffee.js";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import DropzoneInput from "@/components/coffee/DropzoneInput.vue";
import CustomCheck from "@/components/coffee/CustomCheck.vue";
import router from "@/router/index.js";
import RequestCollectionStep1View from "@/views/coffee/RequestCollectionStep1View.vue";
import RequestCollectionStep2View from "@/views/coffee/RequestCollectionStep2View.vue";
import {TransitionChild} from "@headlessui/vue";
import axiosServices from "@/utils/axios/axios.js";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {onBeforeRouteLeave} from "vue-router";
import {store} from "core-js/internals/reflect-metadata.js";
import {storeToRefs} from "pinia";

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})


const steps = ['step1', 'step2']
const step = ref(steps[0])

const bagCount = ref(0)
const etcBag = ref('')
const etcRequest = ref('')

const showModal = ref(false)

const alertIsShown = ref(false)
const alertBody = ref('')
const alertCb = ref(null)

const showAlert = (body, cb) => {
  alertBody.value = body
  alertIsShown.value = true
  alertCb.value = cb ? cb : null
}

const closeAlert = async () => {
  alertBody.value = ''
  alertIsShown.value = false
  if (alertCb.value) {
    await alertCb.value()
  }
}

const goNext = () => {
  const index = steps.indexOf(step.value)
  if (index < steps.length - 1) {
    step.value = steps[index + 1]
  }
}

const goPrev = () => {
  const index = steps.indexOf(step.value)
  if (index > 0) {
    step.value = steps[index - 1]
  }
}

const availableData = ref(null)
onBeforeMount(async () => {
  // 배출 가능여부 조회
  const cafeData = await getCafe()
  availableData.value = cafeData.data
  const cafe = cafeData.data
  console.log('availableData.value')
  console.log(availableData.value)
  if (cafe.region?.tomorrow_collection?.can_request === false) {
    let message = cafe.region?.tomorrow_collection?.restricted_reason_message
    showAlert(Array.isArray(message) ? message.join(' ') : message, () => {
      router.replace({name: 'coffee-index'})
    })
  } else {
    if (cafe.region?.capacity <= cafe.region?.request_count) {
    showAlert('수거 신청 가능량을 초과했습니다.', () => {
      router.replace({name: 'coffee-index'})
    })
    }
  }
})

// onBeforeRouteLeave((to, from, next) => {
//   if (step.value === steps[1]) {
//     showAlert('수거신청을 완료하시겠습니까?', () => {
//       next()
//     })
//   } else {
//     next()
//   }
// })

const mainContainer = ref(null)
const progressContainer = ref(null)
const handleScroll = () => {
  let rect = mainContainer.value.getBoundingClientRect()
  let mainContainerTopBase = 92 - 24  // 68
  progressContainer.value.style.height = `${Math.max(68 - (92 - rect.top), 32)}px`
}

</script>

<template>
  <div ref="progressContainer" class="progress-container w-full max-w-md z-20">
    <div :class="['progress', step, 'z-10']"></div>
    <div class="progress-base"></div>
  </div>
  <div ref="mainContainer" class="main-request-container">
    <RequestCollectionStep1View v-if="step === steps[0]"
                                :bag-count-value="bagCount"
                                :etc-bag-value="etcBag"
                                :etc-request-value="etcRequest"
                                @goNext="goNext"
                                @update:bagCount="value => bagCount = value"
                                @update:etcBag="value => etcBag = value"
                                @update:etcRequest="value => etcRequest = value"/>
    <RequestCollectionStep2View v-if="step === steps[1]"
                                :available-data-value="availableData"
                                :bag-count-value="bagCount"
                                :etc-bag-value="etcBag"
                                :etc-request-value="etcRequest"
                                @goPrev="goPrev"/>
  </div>
  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="closeAlert"/>
</template>

<style scoped>

.request-alert-wrapper {
  max-width: 408px;
  border-radius: 28px 28px 0 0;
  background: #FFF;
  padding: 32px 20px 20px 20px;
}


.progress-base {
  position: absolute;
  background: #EFEFEF;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
}

.progress {
  position: absolute;
  background-color: #3182FF;
  bottom: 0;
  left: 0;
  height: 8px;
  border-radius: 0 999px 999px 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.progress.step1 {
  width: 50%;
}

.progress.step2 {
  width: 50%
}

.progress.step3 {
  width: 100%;
  border-radius: 0;
}

.wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.48);
  overflow: hidden;
  inset: 0;
  justify-content: center;
  align-items: center;
}

.paging-enter-active, .paging-leave-active {
  transition: transform 500ms ease;
}

.paging-enter-from {
  transform: translateX(100%);
}

.paging-leave-to {
  transform: translateX(-100%);
}


.main-request-container {
  margin-top: 32px;
  margin-bottom: 20px;
}

.progress-container {
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  height: 68px;
  top: 0;
  margin-left: -20px;
  margin-right: -20px;
  background-color: white;
  transition: 100ms;
}

.progress-container.squeeze {
  height: 32px;
}
</style>