<script setup>

import {computed, onBeforeMount, ref} from "vue";
import CoffeeConfirm from "@/components/coffee/CoffeeConfirm.vue";
import {useRoute, useRouter} from "vue-router";
import {cancelDispose, disposeDetail} from "@/api/coffee.js";
import {dateParser, serverDatetimeParser, timeParser} from "@/utils/coffee.js";
import Exclamation from "@/components/Exclamation.vue";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";

const statusTypes = ['pending', 'completed', 'failed', 'canceled', 'forceCanceled']
const statusType = ref('pending')
const showCancelConfirm = ref(false)

const router = useRouter()

const alertIsShown = ref(false)
const alertBody = ref('')
const alertCb = ref(null)

const showAlert = (body, cb) => {
  alertBody.value = body
  alertIsShown.value = true
  alertCb.value = cb ? cb : null
}

const closeAlert = async () => {
  alertBody.value = ''
  alertIsShown.value = false
  if (alertCb.value) {
    await alertCb.value()
  }
}



const cancelCollection = async () => {
  showCancelConfirm.value = false
  try {
    await cancelDispose(collection.value.id)
    showAlert('취소되었습니다.', () => {router.go(0)})
  } catch (e) {
    showAlert(e.message, () => {router.go(0)})
  }
}
/*
    REQUESTED = 'rq', '요청됨'
    CANCEL = 'cc', '취소'
    COMPLETE = 'cp', '완료'
    FAILED = 'fi', '실패'
    REJECTED = 'rj', '거부됨'
* */
const route = useRoute()
const collection = ref(null)
const statusDisplay = computed(() => {
  if (!collection.value) {
    return null
  }
  let status = collection.value.status
  return {
    rq: '수거 예정',
    cc: '취소',
    cp: '수거 완료',
    fi: '수거 실패',
    rj: '강제 취소'
  }[status]
})

const statusClass = computed(() => {
  if (!collection.value) {
    return null
  }
  let status = collection.value.status
  return {
    rq: 'pending',
    cc: 'canceled',
    cp: 'completed',
    fi: 'failed',
    rj: 'forceCanceled'
  }[status]
})

onBeforeMount(async () => {
  // 신청 상세 조회
  let collectionId = route.params.id
  try {
    collection.value = await disposeDetail(collectionId)
  } catch (e) {
    alert(e)
  }
})
</script>

<template>
  <div class="mb-6">
    <router-link class="w-6 h-6" :to="{name: 'coffee-list-collection'}">
      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.5817 2.23708L6.25547 11.3969C6.09167 11.5578 6 11.7736 6 11.9983C6 12.223 6.09167 12.4387 6.25547 12.5996L15.5817 21.7629C15.658 21.838 15.7492 21.8976 15.85 21.9383C15.9508 21.979 16.059 22 16.1684 22C16.2778 22 16.3861 21.979 16.4868 21.9383C16.5876 21.8976 16.6788 21.838 16.7551 21.7629C16.9121 21.6091 17 21.4025 17 21.1874C17 20.9723 16.9121 20.7658 16.7551 20.6119L7.98502 11.9983L16.7551 3.38637C16.9116 3.23259 16.9991 3.0264 16.9991 2.81172C16.9991 2.59705 16.9116 2.39086 16.7551 2.23708C16.6788 2.16205 16.5876 2.10242 16.4868 2.0617C16.3861 2.02098 16.2778 2 16.1684 2C16.059 2 15.9508 2.02098 15.85 2.0617C15.7492 2.10242 15.658 2.16205 15.5817 2.23708Z"
            fill="#111111"/>
      </svg>
    </router-link>
  </div>
  <div v-if="collection">
    <div class="page-title mb-2">신청 상세</div>
    <div class="page-sub-title mb-12">
      <template v-if="collection.status === 'rq'">
        {{ dateParser(collection.dispose_date) }} {{ timeParser(collection.region.collection_start_time) }} ~
        {{ timeParser(collection.region.collection_end_time) }} 사이 수거 예정입니다.
      </template>
      <template v-if="collection.status === 'cc'">
        수거 신청이 취소되었습니다.
      </template>
      <template v-if="collection.status === 'cp'">
        {{ serverDatetimeParser(collection.processed_at) }}에 수거 완료되었습니다.
      </template>
      <template v-if="collection.status === 'fi'">
        [{{ collection.process_reason }}] 사유로 수거에 실패하였습니다.
      </template>
      <template v-if="collection.status === 'rj'">
        [{{ collection.process_reason }}] 사유로 수거가 강제 취소되었습니다.
      </template>
    </div>
    <!--정보 컨테이너 시작-->
    <div class="info-container mb-6">

      <div class="flex justify-end">
        <div :class="['dispose-status', statusClass]">
          {{ statusDisplay }}
        </div>
      </div>
      <div class="container-header mb-2">수거 번호</div>
      <div>
        <div class="item-body">{{ collection.serial }}</div>
      </div>
      <div class="divider"/>
      <div class="container-header mb-2">수거 신청 일시</div>
      <div>
        <div class="item-body">{{ serverDatetimeParser(collection.registered_at) }}</div>
      </div>
      <div class="divider"/>
      <div class="container-header mb-2">수거 예정일</div>
      <div>
        <div class="item-body mb-2">
          {{ dateParser(collection.dispose_date) }} {{ timeParser(collection.region.collection_start_time) }} ~
          {{ timeParser(collection.region.collection_end_time) }}
        </div>
        <Exclamation>매장 방문 배출 업체는 영업시간 내 수거 기사가 미방문시, 매장 앞에 배출 부탁드립니다.</Exclamation>
      </div>
      <div class="divider"/>
      <div class="container-header mb-2">업체 정보 및 배출 방법</div>
      <div class="mb-9">
        <div class="item-header">주소</div>
        <div class="item-body">{{ collection.address }} {{ collection.address_detail }}</div>
      </div>
      <div>
        <div class="item-header">배출 방법</div>
        <div class="item-body mb-2">{{ collection.address_dispose }}</div>
        <Exclamation>
          업체 주소 및 배출 장소 변경이 필요할 경우 <span style="font-weight: 700">고객센터(1660-3114)</span>로 문의 부탁드립니다.
        </Exclamation>
      </div>
      <div class="divider"/>
      <div class="container-header mb-2">배출량</div>
      <div class="mb-9">
        <div class="item-header">수거 가방</div>
        <div class="item-body">{{ collection.bag_count }}</div>
      </div>
      <div class="mb-9">
        <div class="item-header">기타 배출 용기</div>
        <div class="item-body">{{ collection.etc_bag ? collection.etc_bag : '-' }}</div>
      </div>
      <div>
        <div class="item-header">기타 요청사항</div>
        <div class="item-body">{{ collection.etc_request ? collection.etc_request : '-' }}</div>
      </div>
      <div class="divider"/>
      <div class="container-header mb-2">야간(오후 6시 이후) 수거 알림 수신 여부</div>
      <div>
        <div class="item-body">{{ collection.night_notification ? '동의' : '미동의' }}</div>
      </div>
    </div>
  </div>

  <div class="mb-5">
    <div class="w-full">
      <button v-if="collection.status === 'rq'" class="light-btn gray w-full mb-9" @click="showCancelConfirm=true">수거 신청
        취소
      </button>
      <router-link :to="{name: 'coffee-list-collection'}" class="list-primary-btn w-full">목록</router-link>
    </div>
  </div>
  <CoffeeConfirm :show="showCancelConfirm" body="수거 신청을 취소하시겠습니까?" @close="() => {showCancelConfirm = false}"
                 @confirm="cancelCollection"/>

  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="closeAlert"/>
</template>

<style scoped>

.list-primary-btn {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #3182FF;
  color: #3182FF;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.list-primary-btn:focus {
  outline: none;
}

.bottom-border {
  border-bottom: 1px solid #EFEFEF;
}

.light-btn.gray {
  border-radius: 8px;
  background: #F5F5F5;
  color: #B7B7B7;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.light-btn.gray:focus {
  border: none;
}


.container-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.page-sub-title {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.info-container {
  width: 100%;
  padding: 32px 16px;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
}

.item-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  margin-bottom: 8px;
}

.item-body {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.item-image {
  border-radius: 8px;
}

.sheet-sub-title {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.divider {
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EFEFEF;
}
</style>