<script setup>
import { ref, computed, onMounted } from 'vue';
import {  
  validatePassword,
  sendErrorLogToSentry,
} from "@/utils/utils";
import {
  CommonString,
} from "@/assets/global/property"
import CommonAlert from '@/components/Popup/CommonAlert.vue';
import {  postPasswordChange } from "@/api/request";
import { useLayoutStore } from '@/stores/layoutStore';
import router from "@/router";
import { useRoute } from "vue-router";
import { useUserStore } from '@/stores/userStore';

// icon
import passwordShowIcon from "@/assets/icon/ic_small_view.svg";
import passwordHiddenIcon from "@/assets/icon/ic_small_hidden.svg";

// route
const route = useRoute();

const phoneAuthId = route.params.auth_id; 

const layoutStore = useLayoutStore();
const userStore = useUserStore();

// 인증번호 alert
const isShowAlert = ref(false);
const showAlert = () => {
  isShowAlert.value = true;
};
const closeAlert = () => {
  isShowAlert.value = false;
};
const onClickPopupOkayButton = () => {
  isShowAlert.value = false;
};

// input field
const passwordField = ref("");
const passwordCheckField = ref("");
const passwordShowField = ref(false);
const passwordCheckShowField = ref(false);

const isPasswordError = ref(false);
const passwordCheckError = ref(false);

const isNextButtonValid = computed(() => {
  return !isPasswordError.value && 
      !passwordCheckError.value && 
      passwordField.value.length > 0 && 
      passwordCheckField.value.length > 0; 
});

// MARK: event

function onClickPasswordShowButton() {
  passwordShowField.value = !passwordShowField.value;
}

function onClickPasswordCheckShowButton() {
  passwordCheckShowField.value = !passwordCheckShowField.value;
}

function onChangePasswordField() {
  passwordField.value = passwordField.value.replace(/\s+/g, '');

  isPasswordError.value = !validatePassword(passwordField.value);
  if (passwordCheckField.value.length > 0) {
    passwordCheckError.value = passwordField.value !== passwordCheckField.value;
  }
  
}

function onChangePasswordCheckField(event) {  
  passwordCheckField.value = passwordCheckField.value.replace(/\s+/g, '');

  const password = passwordField.value;
  const passwordCheck = passwordCheckField.value;

  passwordCheckError.value = password !== passwordCheck;
}

function onClickChangePasswordButton() {  
  const password = passwordField.value;
  
  requestPostPasswordChange(phoneAuthId, password);
}

// api request
async function requestPostPasswordChange(phone_auth_id, password) {
  try {
    const response = await postPasswordChange({
      phone_auth_id: phone_auth_id,
      password: password,      
    })

    layoutStore.showInfoPopup(
      CommonString.PasswordChangeSuccessMessage, 
      CommonString.PasswordChangeSuccessDescription,
      () => userStore.logout(),
    );
  } catch (error) {
   
    sendErrorLogToSentry(error);
  }
}

// life-cycle
onMounted(() => {
  if (phoneAuthId == null) {
    layoutStore.showErrorPopup('휴대폰 인증을 먼저해야합니다.');
  }
})
</script>

<template>
  <common-alert
    id="authCodeInfoAlert"
    :is-show="isShowAlert"
    :title="CommonString.AuthCodeInfoTitle"
    :description="CommonString.AuthCodeInfoDescription"
    @okayAction="onClickPopupOkayButton"    
    @closeAlert="closeAlert"
  >
  </common-alert> 
  <div>
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start">
        <p class="head_2 left page_title">비밀번호 재설정</p>        

        <div class="input_row">
          <div class="input_wrap2 password_input flex flex_row justify_start align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="password" class="label_2 mb-1">새 비밀번호</label>
              <input
                class="input body_2"
                :class="{ is_error: isPasswordError }"
                v-model="passwordField"
                :type="passwordShowField ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder="비밀번호 영문, 숫자, 특수문자 중 2가지를 포함한 8~20자 입력"
                @input="onChangePasswordField" />
              <button
                class="view_password"
                @click="onClickPasswordShowButton()">
                <img
                  :src="
                    passwordShowField ? passwordShowIcon : passwordHiddenIcon
                  "
                  alt="새 비밀번호 보기" />
              </button>
              <p
                class="error_message body_4 color_warning left"
                style="margin-top: 2px"
                v-show="isPasswordError">
                {{ CommonString.PasswordErrorMessage }}
              </p>
            </div>
          </div>
        </div>

        <div class="input_row">
          <div class="input_wrap2 password_input flex flex_row justify_start align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="password_check" class="label_2 mb-1">새 비밀번호 확인</label>
              <input
                class="input body_2"
                :class="{ is_error: passwordCheckError }"
                v-model="passwordCheckField"
                :type="passwordCheckShowField ? 'text' : 'password'"
                name="password_check"
                id="password_check"
                placeholder="새 비밀번호와 동일하게 입력"
                @input="onChangePasswordCheckField" />
              <button
                class="view_password"
                @click="onClickPasswordCheckShowButton()">
                <img
                  :src="
                    passwordCheckShowField ? passwordShowIcon : passwordHiddenIcon
                  "
                  alt="새 비밀번호 확인 보기" />
              </button>
              <p
                class="error_message body_4 color_warning left"
                style="margin-top: 2px"
                v-show="passwordCheckError">
                {{ CommonString.PasswordCheckErrorMessage }}
              </p>
            </div>
          </div>
        </div>

        <!-- 변경 버튼 -->
        <div class="button_wrap flex flex_col justify_center align_center">
          <button
            class="next_button label_1"
            @click="onClickChangePasswordButton"
            :disabled="!isNextButtonValid"
          >
            변경
          </button>
        </div>

        <div class="login_issue_box flex flex_row align_center justify_center">
          <img src="@/assets/icon/icon_question.svg" />
          <span class="caption_1 color_caption">
            비밀번호 변경 시 로그인 후 이용 부탁드립니다.
          </span>
        </div>

      </div>            
    </div>    
  </div>
</template>

<style scoped>
.single-content-page-wrap .page_title {
  margin-bottom: 96px;
}

.input_row {
  width: 100%;
  margin-bottom: 30px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.input-field {
  width: 100%;
  padding-right: 50px;
}
.input-timer-label {
  position: absolute;
  right: 10px;
  background-color: transparent;
  /* pointer-events: none; */
}

.button_wrap {
  width: 100%;
  padding-top: 30px;
  gap: 10px;
}

.next_button {
  width: 100%;
  height: 50px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}
.next_button:disabled {
  width: 100%;
  height: 50px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}

.login_issue_box {
  margin-top: 28px;
  width: 100%;
}

.login_issue_box img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}

@media screen and (max-width: 992px) {
  .head_2 {
    font-size: 28px;
    line-height: 40px;
  }

  .single-content-page-wrap .page_title {
    margin-bottom: 60px;
  }

  .next_button {
    width: 100%;
    margin-bottom: 38px;
  }

  .login_issue_box {
    margin-top: 0;
    margin-bottom: 44px;
    width: 100%;
  }
}
</style>