<script setup>
import { computed, ref, watch } from "vue";
import { putCellPhoneAuth, postCellphoneAuth, postRegister2 } from "@/api/auth";
import { useUserStore } from "@/stores/userStore";
import {
  openServiceTerms,
  openMarketingTerms,
  openPrivacyTerms,
} from "@/utils/outsideLink";
import { validatePassword } from "@/utils/utils";
import router from "@/router";

// icon
import passwordShowIcon from "@/assets/icon/ic_small_view.svg";
import passwordHiddenIcon from "@/assets/icon/ic_small_hidden.svg";
import checkPrimary from "@/assets/icon/ic_check_primary.svg";
import checkGray from "@/assets/icon/ic_check_gray.svg";

const userStore = useUserStore();

const { phoneAuthId } = history.state;

const NAME_MAX_LENGTH = 20;

// 회원가입 성공 팝업
const showSignupSucessAlert = ref(false);

const signupAlertOkayAction = () => {
  showSignupSucessAlert.value = false;
  router.push("/");
};

const signupAlertCloseAction = () => {
  showSignupSucessAlert.value = false;
  router.push("/");
};

const nameField = ref("");
const passwordField = ref("");
const passwordShowField = ref(false);
const passwordCheckField = ref("");
const passwordCheckShowField = ref(false);

const isValidPassword = ref(true);
const isValidPasswordCheck = ref(true);
const isValidName = computed(() => {
  return nameField.value.length > 0;
});

const isValidRegister = computed(() => {
  return (
    isValidPassword.value &&
    passwordField.value.length > 0 &&
    passwordCheckField.value.length > 0 &&
    isValidPassword.value &&
    isValidPasswordCheck.value &&
    isValidName.value &&
    (phoneAuthId !== null || phoneAuthId !== undefined) &&
    ageAgreeField.value &&
    serviceAgreeField.value &&
    privacyAgreeField.value
  );
});

const loginBtnDisabled = ref(false)

const allAgreeField = ref(false);
const ageAgreeField = ref(false);
const serviceAgreeField = ref(false);
const privacyAgreeField = ref(false);
const marketingAgreeField = ref(false);

const pushAgreeField = ref(false);
const smsAgreeField = ref(false);

// function

function onClickPasswordShowButton() {
  passwordShowField.value = !passwordShowField.value;
}

function onClickPasswordCheckShowButton() {
  passwordCheckShowField.value = !passwordCheckShowField.value;
}

function onChangeAllAgree() {
  serviceAgreeField.value = allAgreeField.value;
  privacyAgreeField.value = allAgreeField.value;
  smsAgreeField.value = allAgreeField.value;
  marketingAgreeField.value = allAgreeField.value;
  pushAgreeField.value = allAgreeField.value;
  ageAgreeField.value = allAgreeField.value;
}

function onChangeAgree() {
  smsAgreeField.value = marketingAgreeField.value;
  pushAgreeField.value = marketingAgreeField.value;

  setAllAgreeField();
}

function setAllAgreeField() {
  if (
    ageAgreeField.value &&
    serviceAgreeField.value &&
    privacyAgreeField.value &&
    marketingAgreeField.value
  ) {
    allAgreeField.value = true;
  } else {
    allAgreeField.value = false;
  }
}

function onChangePushAgree() {
  pushAgreeField.value = !pushAgreeField.value;
  setMarketingAgreeValue();
  setAllAgreeField();
}

function onChangeSmsAgree() {
  smsAgreeField.value = !smsAgreeField.value;
  setMarketingAgreeValue();
  setAllAgreeField();
}

function setMarketingAgreeValue() {
  marketingAgreeField.value = smsAgreeField.value && smsAgreeField.value;
}

async function onClickSignupButton() {
  if (isValidRegister && !loginBtnDisabled.value) {
    loginBtnDisabled.value = true

    const name = nameField.value;
    const password = passwordField.value;
    const authId = phoneAuthId;
    const serviceAgree = serviceAgreeField.value;
    const personalAgree = privacyAgreeField.value;
    const pushAgree = pushAgreeField.value;
    const smsAgree = smsAgreeField.value;
    const ageAgree = ageAgreeField.value;

    try {
      await userStore.regiserUser(
        authId,
        name,
        password,
        serviceAgree,
        personalAgree,
        pushAgree,
        smsAgree,
        ageAgree
      );

      showSignupSucessAlert.value = true;
    } catch (error) {
      alert(error.response.data.message);
    } finally {
      loginBtnDisabled.value = false
    }
  } else {
    alert("유효하지 않은 입력값이 있습니다. 확인해주세요.");
    loginBtnDisabled.value = false
  }
}

function onChangePasswordField() {
  passwordField.value = passwordField.value.replace(/\s+/g, '');
  isValidPassword.value = validatePassword(passwordField.value);
  isValidPasswordCheck.value = passwordField.value === passwordCheckField.value;
}

function onChangeCheckPasswordField() {
  passwordCheckField.value = passwordCheckField.value.replace(/\s+/g, '');
  
  isValidPasswordCheck.value = passwordField.value === passwordCheckField.value;
  isValidPassword.value = validatePassword(passwordField.value);
}

// request api

// life-cycle
watch(nameField, (newValue) => {
  const regex = /[^a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;

  nameField.value = newValue.replace(regex, "");
});
</script>

<template>
  <div id="join_step2">
    <common-alert
      id="signup_sucess_alert"
      :is-show="showSignupSucessAlert"
      :title="`${userStore.loginUserName} 님, 반가워요!`"
      description="지구하다와 함께 올바른 폐기문화를 만들어요."
      @okayAction="signupAlertOkayAction"
      @closeAlert="signupAlertCloseAction"
    >
    </common-alert>
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start"
      >
        <p class="head_2 left page_title">
          회원가입
        </p>
        <p class="subtitle_6 color_secondary left">
          만나서 반가워요 :)
          <span class="subtitle_5">회원 정보</span>를 입력해 주세요.
        </p>

        <!-- 이름 -->
        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="name" class="label_2 mb-1">이름</label>
              <input
                class="input body_2"
                v-model="nameField"
                type="text"
                name="name"
                id="name"
                placeholder="이름을 입력하세요."
                :maxlength="NAME_MAX_LENGTH"
              />
            </div>
          </div>
        </div>
        <!-- 비밀번호 -->
        <div class="input_row">
          <div
            class="input_wrap2 password_input flex flex_row justify_center align_end"
          >
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="password" class="label_2 mb-1">비밀번호</label>
              <input
                class="input body_2"
                :class="
                  passwordField.length === 0
                    ? ''
                    : isValidPassword
                    ? 'is_valid'
                    : 'is_error'
                "
                v-model="passwordField"
                :type="passwordShowField ? 'text' : 'password'"
                name="password"
                id="password"
                placeholder="비밀번호 8~20자(영문, 숫자, 특수문자 중 2가지 포함)"
                @input="onChangePasswordField"
              />
              <button
                class="view_password"
                @click="onClickPasswordShowButton()"
              >
                <img
                  :src="
                    passwordShowField ? passwordShowIcon : passwordHiddenIcon
                  "
                  alt="비밀번호 보기"
                />
              </button>
              <p
                class="error_message body_4 left"
                :class="isValidPassword ? 'color_primary' : 'color_warning'"
                v-show="passwordField.length > 0"
              >
                {{
                  isValidPassword
                    ? "사용 가능한 비밀번호입니다."
                    : "영문, 숫자, 특수문자 중 2가지를 포함한 8~20자 이내로 입력해주세요."
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- 비밀번호 확인 -->
        <div class="input_row">
          <div
            class="input_wrap2 password_input flex flex_row justify_center align_end"
          >
            <div
              class="grow flex flex_col justify_start align_stretch"
              style="position: relative"
            >
              <label for="password_check" class="label_2 mb-1">
                비밀번호 확인
              </label>
              <input
                class="input body_2"
                :class="
                  passwordCheckField.length === 0
                    ? ''
                    : isValidPasswordCheck
                    ? 'is_valid'
                    : 'is_error'
                "
                v-model="passwordCheckField"
                :type="passwordCheckShowField ? 'text' : 'password'"
                name="password_check"
                id="password_check"
                placeholder="비밀번호 8~20자(영문, 숫자, 특수문자 중 2가지 포함)"
                @input="onChangeCheckPasswordField"
              />
              <button
                class="view_password"
                @click="onClickPasswordCheckShowButton()"
              >
                <img
                  :src="
                    passwordCheckShowField
                      ? passwordShowIcon
                      : passwordHiddenIcon
                  "
                  alt="비밀번호 보기"
                />
              </button>
              <p
                class="error_message body_4 left"
                :class="
                  isValidPasswordCheck ? 'color_primary' : 'color_warning'
                "
                v-show="passwordCheckField.length > 0"
              >
                {{
                  isValidPasswordCheck
                    ? "비밀번호가 일치합니다."
                    : "비밀번호가 일치하지 않습니다."
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="input_wrap2" style="margin-top: 30px">
          <label for="all" class="chk_box all_check" style="margin-bottom: 0px">
            <input
              type="checkbox"
              id="all"
              v-model="allAgreeField"
              @change="onChangeAllAgree"
            />
            <span class="on"></span>
            <span class="subtitle_7 color_secondary"
              >이용약관 전체 동의하기</span
            >
          </label>
          <span class="body_3 g200" style="margin-left: 26px">
            서비스 이용을 위해 아래 약관에 모두 동의합니다.
          </span>
        </div>
        <div class="title_divider" style="margin-bottom: 20px"></div>

        <!-- 필수 동의 항목 -->
        <div class="input_row check_box_row">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <!-- 14세 이상 약관 -->
              <div class="flex flex_row mb-4">
                <label for="age" class="chk_box">
                  <input
                    type="checkbox"
                    id="age"
                    v-model="ageAgreeField"
                    @change="onChangeAgree"
                  />
                  <span class="on"></span>
                </label>
                <span class="subtitle_7 color_secondary flex align_center">
                  (필수) 만 14세 이상입니다.
                </span>
              </div>
              <!-- 서비스 약관 -->
              <div class="flex flex_row mb-4">
                <label for="service" class="chk_box">
                  <input
                    type="checkbox"
                    id="service"
                    v-model="serviceAgreeField"
                    @change="onChangeAgree"
                  />
                  <span class="on"></span>
                </label>
                <div class="cusor_point" @click="openServiceTerms">
                  <span
                    class="subtitle_7 color_secondary bold flex align_center"
                  >
                    (필수) 서비스 이용 약관 동의
                    <img
                      class="tag_arrow_right"
                      src="@/assets/icon/ic_arrow_right_gray.svg"
                      alt=">"
                    />
                  </span>
                </div>
              </div>
              <!-- 개인정보 수집 약관 -->
              <div class="flex flex_row mb-4">
                <label for="privacy" class="chk_box">
                  <input
                    type="checkbox"
                    id="privacy"
                    v-model="privacyAgreeField"
                    @change="onChangeAgree"
                  />
                  <span class="on"></span>
                </label>
                <div class="cusor_point" @click="openPrivacyTerms">
                  <span class="subtitle_7 color_secondary flex align_center">
                    (필수) 개인정보 수집 및 이용 동의
                    <img
                      class="tag_arrow_right"
                      src="@/assets/icon/ic_arrow_right_gray.svg"
                      alt=">"
                    />
                  </span>
                </div>
              </div>
              <!-- 마케팅 약관 -->
              <div class="flex flex_row mb-4">
                <label for="marketing" class="chk_box">
                  <input
                    type="checkbox"
                    id="marketing"
                    v-model="marketingAgreeField"
                    @change="onChangeAgree"
                  />
                  <span class="on"></span>
                </label>
                <div>
                  <span
                    @click="openMarketingTerms"
                    class="cusor_point subtitle_7 color_secondary flex align_center"
                    style="margin-bottom: 10px"
                  >
                    (선택) 마케팅 수신 동의
                    <img
                      class="tag_arrow_right"
                      src="@/assets/icon/ic_arrow_right_gray.svg"
                      alt=">"
                    />
                  </span>
                  <div class="flex flex_col align_start">
                    <div
                      @click="onChangePushAgree"
                      class="check_sub_item cusor_point flex flex_row align_center justify_center"
                      style="margin-bottom: 6px;"
                    >
                      <img :src="pushAgreeField ? checkPrimary : checkGray" />
                      <span class="body_2 g200 only_pc_block"> 앱 내 푸시 알림 </span>
                      <span class="body_3 g200 only_mo_block"> 앱 내 푸시 알림 </span>
                    </div>
                    <div
                      @click="onChangeSmsAgree"
                      class="check_sub_item cusor_point flex flex_row align_center justify_center"
                    >
                      <img :src="smsAgreeField ? checkPrimary : checkGray" />
                      <span class="body_2 g200 only_pc_block"> 문자 메세지 발송 </span>
                      <span class="body_3 g200 only_mo_block"> 문자 메세지 발송 </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button_wrap flex flex_col justify_center align_center">
          <button
            @click="onClickSignupButton"
            :disabled="!isValidRegister || loginBtnDisabled"
            class="next_button label_1"
          >
            시작하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

#join_step2 .subtitle_6 {
  margin-bottom: 60px;
}
#join_step2 .input_row {
  width: 100%;
  margin-bottom: 30px;
}
#join_step2 .button_wrap {
  width: 100%;
  padding-top: 30px;
}

/* join_step1 */
#join_step2 .next_button {
  width: 100%;
  border-radius: 4px;
  background-color: #1e64ff;
  color: white;
  height: 50px;
}
#join_step2 .next_button:disabled {
  width: 100%;
  border-radius: 4px;
  background-color: #ededed;
  color: #7a7a7a;
  height: 50px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  width: 100%;
  padding-right: 50px;
}

.input-timer-label {
  position: absolute;
  right: 10px;
  background-color: transparent;
  pointer-events: none;
  color: #e21f71;
}

.check_box_row .input_label {
  margin-bottom: 18px;
}
.check_box_row .chk_box:last-child {
  margin-bottom: 0px;
}

/* CHECK BOX */
.chk_box.all_check {
  margin-bottom: 20px;
}
.all_check .on {
  width: 16px;
  height: 16px;
  top: 4px;
}
.all_check .on::after {
  left: 5px;
  top: 1px;
}

.check_sub_item img {
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

@media screen and (max-width: 992px) {
  #join_step2 .subtitle_6 {
    margin-bottom: 28px;
  }

  .head_2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  .subtitle_5, 
  .subtitle_6 {
    font-size: 14px;
    line-height: 26px;
  }

  #join_step2 .button_wrap {
     margin-bottom: 50px;
  }
}
</style>
