import axiosServices from "@/utils/axios/quickQuoteAxios"
import axios from "axios"

const POST = "POST"

/* request 예시, request 없음
{
  "service_type": 2,
  "registered_at": "yyyy-mm-dd hh:mm:ss",
  "name": "김지구",
  "cellphone": "01012345678",
  "memo": "내용 확인 후 견적 부탁드립니다.",
  "recaptcha": "recaptcha vertify token"
}
*/

// 빠른 견적 문의 
const postQuickQuote = async (
  name,
  cellphone,
  memo,
  recaptcha
) => {
  const now = Date()

  const body = {
    service_type: 2,
    registered_at: getDate(),
    name: name,
    cellphone: cellphone,
    memo: memo,
    recaptcha: recaptcha
  }
  
  const response = await axiosServices({
    method: POST,
    data: body
  })

  return response.data
}

// logic function
function getDate() {
  const date = new Date()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()

  month = twoNumString(month)
  day = twoNumString(day)
  hour = twoNumString(hour)
  minute = twoNumString(minute)
  second = twoNumString(second)

  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`
}

function twoNumString(num) {
  return num >= 10 ? num : '0' + num
}

export {
  postQuickQuote
}