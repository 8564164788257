<script setup>
import {defineProps, defineEmits} from 'vue'

const props = defineProps({
  togglerValue: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emits = defineEmits(['update:togglerValue'])

const toggle = () => {
  emits('update:togglerValue', !props.togglerValue)
}
</script>

<template>
  <div :class="props.togglerValue ? 'wrapper active' : 'wrapper'" @click.stop="toggle">
    <div :class="['toggler', props.togglerValue ? 'active' : 'inactive']">
      <template v-if="props.togglerValue">
        <svg fill="none" height="8" viewBox="0 0 10 8" width="10" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M9.78366 0.241753C10.0721 0.564091 10.0721 1.0867 9.78366 1.40904L4.10184 7.75825C3.81338 8.08058 3.34571 8.08058 3.05725 7.75825L0.216342 4.58364C-0.0721138 4.26131 -0.0721138 3.73869 0.216342 3.41636C0.504797 3.09402 0.972476 3.09402 1.26093 3.41636L3.57955 6.00732L8.73907 0.241753C9.02752 -0.0805844 9.4952 -0.0805844 9.78366 0.241753Z"
                fill="#555555"
                fill-rule="evenodd"/>
        </svg>
      </template>
      <template v-else>
        <svg fill="none" height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd"
                d="M0.234265 0.234365C0.546656 -0.0780824 1.05319 -0.0781272 1.36564 0.234265L4.00047 2.86863L6.63426 0.234365C6.94666 -0.0780824 7.45319 -0.0781273 7.76564 0.234265C8.07808 0.546656 8.07813 1.05319 7.76574 1.36564L5.13184 4L7.76574 6.63437C8.07813 6.94681 8.07808 7.45334 7.76564 7.76574C7.45319 8.07813 6.94666 8.07808 6.63426 7.76564L4.00047 5.13137L1.36564 7.76574C1.05319 8.07813 0.546656 8.07808 0.234265 7.76564C-0.0781272 7.45319 -0.0780825 6.94666 0.234365 6.63427L2.8691 4L0.234365 1.36574C-0.0780825 1.05334 -0.0781272 0.546812 0.234265 0.234365Z"
                fill="#555555"
                fill-rule="evenodd"/>
        </svg>
      </template>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  width: 44px;
  height: 24px;
  padding: 2px;
  gap: 10px;
  border-radius: 999px;
  background: #DFDFDF;
  transition: background-color 300ms;
  user-select: none;
  cursor: pointer;
}

.wrapper.active {
  background: #3182FF;
}

.toggler {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background: #FFF;
  transition: transform 100ms;
}

.toggler.active {
  transform: translateX(20px);
}

.toggler.inactive {
  transform: translateX(0);
}
</style>