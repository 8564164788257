<script setup>
import router from '@/router';
import { useRequestStore } from '@/stores/requestStore';

const requestStore = useRequestStore()

const { isCardPayment, requestId, depositDeadLine } = history.state

async function onClickCopyBtn() {
  const accountNumber = requestStore.bankInfo.accountNumber;

  try {
    await copyToClipboard(accountNumber);
    alert("계좌가 복사되었습니다.");
  } catch (err) {
    alert(`복사에 실패했습니다. ${err}`);
    console.error(err);
  }
}

async function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
        
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";
        
    document.body.prepend(textArea);
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (error) {
        console.error(error);
    } finally {
        textArea.remove();
    }
  }
}

function onClickHistoryBtn() {
  requestStore.resetAllData()
  router.push('/my/history')
}

function onClickMainBtn() {
  requestStore.resetAllData()
  router.push('/')
}

</script>

<template>
  <div id="mo_complete">
    <div class="page_container">
      <div class="single-content-page-wrap flex flex_col justify_start align_center">
        <p class="subtitle_1" style="margin-bottom: 20px;">배출신고</p>
        <p 
          class="subtitle_6 color_caption" 
          style="margin-bottom: 60px;">
          신고가 접수되었습니다.
        </p>
        <img
          class="done_img"
          src="@/assets/image/img_success_logo1.svg"
          alt="배출신고 완료"/>
        <p 
          v-if="isCardPayment"
          class="subtitle_6 color_secondary"
          style="margin-bottom: 4px;">
          배출번호
        </p>
        <div class="deadline_box">
          {{ isCardPayment ? requestId : depositDeadLine }}
        </div>
        <p
          v-if="isCardPayment"
          class="card_text body_2 color_secondary">
          위 배출번호를 모든 배출 품목에 잘 보이도록 크게<br>유성펜으로 적거나 종이에 적어 부착해 주세요.
        </p>
        <p
          v-else
          class="account_text body_2 color_secondary">
          위 기한까지 입금을 완료해 주세요.<br>결제 완료 후 서비스가 진행됩니다.
        </p>
        <div
          v-if="!isCardPayment"
          class="account_info_box flex flex_col justify_start align_start">
          <div class="row">
            <span class="subtitle_7 color_secondary">입금 금액</span>
            <p class="subtitle_5 color_primary right">
              {{ $formatNumber(requestStore.getTotalPrice()) }}
              <span class="subtitle_7 color_secondary"> &nbsp;원 </span>
            </p>
          </div>
          <div class="divider"></div>
          <div class="row" style="margin-bottom: 6px;">
            <span class="subtitle_7 color_secondary">예금주</span>
            <span class="body_2 color_secondary">
              {{ requestStore.bankInfo.accountHolder }}
            </span>
          </div>
          <div class="row" style="margin-bottom: 6px;">
            <span class="subtitle_7 color_secondary">입금은행</span>
            <span class="body_2 color_secondary">
              {{ requestStore.bankInfo.bankDisplay }}
            </span>
          </div>
          <div class="row" style="margin-bottom: 10px;">
            <span class="subtitle_7 color_secondary">계좌 번호</span>
            <span class="body_2 color_secondary">
              {{ requestStore.bankInfo.accountNumber }}
            </span>
          </div>
          <button 
            @click="onClickCopyBtn"
            class="copy_btn">
            계좌번호 복사하기
          </button>
        </div>
        <button 
          @click="onClickHistoryBtn"
          class="history_btn">
          신청내역 확인
        </button>
        <button 
          @click="onClickMainBtn"
          class="home_btn">
          메인으로
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.done_img {
  width: 120px;
  height: 250px;
  margin-bottom: 40px;
}

.deadline_box {
  width: 100%;
  height: 68px;
  background-color: rgba(192, 200, 212, 0.2);
  color: #1E64FF;
  border-radius: 4px;
  font-size: 28px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.card_text {
  text-align: center;
  margin-bottom: 60px;
}

.account_text {
  text-align: center;
  margin-bottom: 40px;
}

.account_info_box {
  border: 1px solid #E7ECF4;
  border-radius: 4px;
  padding: 20px 14px;
  width: 100%;
  margin-bottom: 60px;
}

.row {
  display: flex;
  -webkit-box-pack: justify;
  -moz-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.divider {
  margin: 16px 0px;
  height: 1px;
  background-color: #E7ECF4;
  width: 100%;
}

.copy_btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #132B4F;
  font-size: 14px;
  font-weight: 700;
}

.history_btn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background-color: #1E64FF;
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.home_btn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #B4B4B4;
  background-color: white;
  color: #B4B4B4;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 100px;
}
</style>