<script setup>

</script>

<template>
<div>
  <div class="qa-line mb-2">
    <div class="cf-head-6" style="color: var(--CF-Primary)">Q.</div>
    <div class="cf-title-4" style="color: var(--CF-Primary)"><slot name="q">질문</slot></div>
  </div>
  <div class="qa-line">
    <div class="cf-head-6" style="color: var(--CF-Gray-900)">A.</div>
    <div class="cf-body-2" style="color: var(--CF-Gray-900)"><slot name="a">답변</slot></div>
  </div>
</div>
</template>

<style>

.qa-line {
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
}
</style>