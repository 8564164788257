<script setup>

import CustomCheck from "@/components/coffee/CustomCheck.vue";
import {onMounted, ref, watch} from "vue";

const props = defineProps({
  label: String,
  timeValue: [null, Array],
  isChecked: Boolean,
  isAll: {
    type: Boolean,
    default: false
  }
  // value: Boolean,
  // disabled: Boolean,
  // onChange: Function,
});

const emits = defineEmits([
    'apply:All', 'update:checkedValue', 'update:timeValue'
])

const time = ref(null);

watch(() => props.timeValue, (n, o) => {
  time.value = n;
}, {deep: true})

watch(time, (n, o) => {
   emits('update:timeValue', n);
}, {deep: true})

onMounted(() => {
  time.value = props.timeValue;
})

const timeClick = (event) => {
  event.target.showPicker()
}

const setOpenValue = (event) => {
  if (time.value) {
    time.value[0] = event.target.value
  } else {
    time.value = [event.target.value, '']
  }
}
const setCloseValue = (event) => {
  if (time.value) {
    time.value[1] = event.target.value
  } else {
    time.value = ['', event.target.value]
  }
}

const apply = ref(false)
const applyBtn = () => {
  if (apply.value === false) {
    apply.value = true
    emits('apply:All', time.value)
    apply.value = false
  }
}
</script>

<template>
  <div>
    <div :class="[props.isAll ? 'mb-[3px]' : 'mb-[8px]']" class="flex items-center justify-between">
      <div>
        <CustomCheck :isChecked="props.isChecked" :label="props.label" labelStyle="square"
                     @update:modelValue="value => emits('update:checkedValue', value)"/>
      </div>
      <button v-if="props.isAll" class="apply-btn" @click="applyBtn">
        선택적용
      </button>
    </div>
    <div class="input-wrapper flex items-center justify-center">
      <div class="input-grow-box">
        <input class="time-input" :disabled="props.timeValue === null" :value="time ? time[0] : ''" @input="setOpenValue" type="time" @focus.prevent="timeClick" @click.prevent="timeClick"/>
      </div>
      <div class="" :class="[props.timeValue !== null ? 'tilde' : 'tilde disabled']">~</div>
      <div class="input-grow-box">
        <input class="time-input" :disabled="props.timeValue === null" :value="time ? time[1] : ''" @input="setCloseValue" type="time" @focus.prevent="timeClick" @click.prevent="timeClick"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.apply-btn {
  display: flex;
  width: 79px;
  height: 32px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  border: 1px solid #3182FF;
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.input-wrapper {
  display: flex;
  width: 100%;
  height: 52px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #DFDFDF;
}
.tilde {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  user-select: none;
}
.tilde.disabled {
  color: #B7B7B7
}
.time-input {
  display: flex;
width: 100%;
  height: 52px;
  justify-content: left;
  align-items: center;
  gap: 12px;
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  cursor: pointer;
  background: transparent;
}
.time-input:disabled {
  color: #B7B7B7;
  cursor: not-allowed;
}

.time-input::placeholder {
  color: #111;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.time-input:focus {
  outline: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  display: none;
}

.input-grow-box {
  flex-grow: 1;
}

</style>