<script setup>
import router from "@/router";
import { onMounted, ref } from "vue";

const ROUTE_TIME_COUNT = 3;

const timerCount = ref(ROUTE_TIME_COUNT);
var timer = null;

function startTimer() {
  if (timer !== null) {
    clearInterval(timer);
  }

  timer = setInterval(() => {
    timerCount.value--;

    if (timerCount.value < 1) timerStop();
  }, 1000);
}

function timerStop() {
  if (timer !== null) {
    clearInterval(timer);
    router.replace("/");
  }
}

onMounted(() => {
  startTimer();
});
</script>

<template>
  <div class="one_page flex flex_col justify_center align_center">
    <span class="subtitle_1">
      <span class="subtitle_1 color_primary">페이지</span> 를 찾을 수 없습니다.
    </span>
    <span class="subtitle_4 color_caption" style="margin-top: 20px">
      {{ timerCount }}초 후 메인페이지로 이동합니다.
    </span>
    <img src="@/assets/image/img_notfound.svg" />
  </div>
</template>

<style scoped>
.one_page {
  height: 100vh;
}
.one_page img {
  margin-top: 60px;
  height: 251px;
}

@media screen and (max-width: 992px) {
  .subtitle_1 {
    font-size: 28px;
    line-height: 40px;
  }
  .one_page img {
    margin-top: 40px;
    height: 171px;
  }
}
</style>
