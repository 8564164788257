<script setup>

import {formatBrn, formatPhoneNumber} from "@/utils/utils.js";
import {toRef} from "vue";
import {workingHourParser} from "../../utils/coffee.js";

const props = defineProps({
  registerData: {
    type: Object,
    required: true
  }
})

const registerData = toRef(props, 'registerData')
const weekList = [
  {kor: '월요일', eng: 'mon'},
  {kor: '화요일', eng: 'tue'},
  {kor: '수요일', eng: 'wed'},
  {kor: '목요일', eng: 'thu'},
  {kor: '금요일', eng: 'fri'},
  {kor: '토요일', eng: 'sat'},
  {kor: '일요일', eng: 'sun'},
]
</script>

<template>
  <div class="pt-[12px] mb-[20px]">
    <div class="page-title mb-[8px]">승인 대기중</div>
    <div class="mb-[48px]">
      <ul>
        <li>이용 가능 지역 내 소재한 사업장만 등록 가능합니다.</li>
        <li>영업일 기준 3일 이내 등록하신 휴대전화번호로 승인 결과를 안내 드릴 예정입니다.</li>
        <li>업체 정보 변경은 <span style="font-weight: 800">[더보기 > 서비스문의]</span>로 문의 부탁드립니다.</li>
      </ul>
    </div>

    <!--정보 컨테이너 시작-->
    <div class="info-container mb-9">
      <div class="container-header mb-2">업체 정보</div>
      <div class="mb-4">
        <div class="item-header">업체명</div>
        <div class="item-body">{{ registerData.name }}</div>
      </div>
      <div class="mb-4">
        <div class="item-header">대표자명</div>
        <div class="item-body">{{ registerData.rep_name }}</div>
      </div>
      <div class="mb-4">
        <div class="item-header">사업자등록번호</div>
        <div class="item-body">{{ formatBrn(registerData.brn) }}</div>
      </div>
      <div class="mb-4">
        <div class="item-header">업체 주소</div>
        <div class="item-body">{{ registerData.address }} {{ registerData.address_detail }}</div>
      </div>
      <div class="mb-4">
        <div class="item-header">휴대전화번호</div>
        <div class="item-body">{{ formatPhoneNumber(registerData.cellphone) }}</div>
      </div>
      <div>
        <div class="item-header">업체 전경사진</div>
        <img :src="registerData.photo.file" alt="업체 전경사진" class="item-image"></img>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">영업 시간</div>
      <div v-for="week in weekList" class="mb-4">
        <div class="item-header">{{ week.kor }}</div>
        <div class="item-body">
          {{ workingHourParser(registerData[`week_${week.eng}`]) }}
        </div>
      </div>
      <div>
        <div class="item-header">휴무일</div>
        <div class="item-body">{{ registerData.working_hour_etc ? registerData.working_hour_etc : '없음' }}</div>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">배출 방법 및 예상 배출량</div>
<!--      <div class="mb-4">-->
      <div>
        <div class="item-header">배출 방법</div>
        <div class="item-body mb-2">{{ registerData.address_dispose }}</div>
        <img :src="registerData.dispose_photo.file" alt="배출 위치 사진" class="item-image"></img>
      </div>
      <!-- 20240626 홍찬의 : 양천구 주무관 요청으로 삭제-->
<!--      <div>-->
<!--        <div class="item-header">예상 배출량</div>-->
<!--        <div class="item-body">{{ registerData.estimated_weight }}Kg/월</div>-->
<!--      </div>-->
      <div class="divider"></div>
      <div class="container-header mb-2">야간(오후 6시 이후) 수거 알림 수신 여부</div>
      <div>
        <div class="item-body">{{ night_agree ? '동의' : '미동의' }}</div>
      </div>
      <div class="divider"></div>
      <div class="container-header mb-2">약관 동의 여부</div>
      <div>
        <div class="item-body">
          개인정보 수집・이용 및 제 3자 제공 동의 완료
        </div>
      </div>
    </div>
    <div class="w-ful l">
      <router-link :to="{name: 'coffee-index'}" class="blank-btn">메인으로</router-link>
    </div>
  </div>
</template>

<style scoped>
.container-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.info-container {
  width: 100%;
  padding: 32px 16px;
  border-radius: 20px;
  border: 1px solid #DFDFDF;
}

.item-header {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  margin-bottom: 8px;
}

.item-body {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.item-image {
  border-radius: 8px;
}

.divider {
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #EFEFEF;
}
</style>