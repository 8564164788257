<script setup>
import {computed, onBeforeMount, onBeforeUnmount, onMounted, ref} from "vue";
import {checkDisposeAvailable} from "@/api/coffee.js";
import {useRouter} from "vue-router";

const availableData = ref(null)
const message = ref('')
const router = useRouter()

onMounted(async () => {
  document.getElementById('coffeeMainLayout').classList.add('pt-0')
  document.getElementById('coffeeMainLayout').classList.remove('pt-[60px]')
})

onBeforeUnmount(() => {
  document.getElementById('coffeeMainLayout').classList.add('pt-[60px]')
  document.getElementById('coffeeMainLayout').classList.remove('pt-0')
})

onBeforeMount(async () => {
  // 배출 가능여부 조회
  try {
    availableData.value = await checkDisposeAvailable()
    if (availableData.value.is_restricted === false) {
      await router.replace({name: 'coffee-list-collection'})
    }
    message.value = availableData.value.restricted_reason
  } catch (error) {
    message.value = '배출 가능 여부를 조회하는 중 오류가 발생했습니다.'
  }
})

const title = computed(() => {
  return {
    'already_requested': '이미 배출 신청이 완료되었습니다.',
    'weekday': '내일은 수거 가능 요일이 아닙니다.',
    'not_in_time': '배출 신고 시작 시간 전입니다.',
    'overdue': '배출 신고 마감 시간이 지났습니다.',
    'restricted_date': '배출이 제한되는 날짜입니다.',
    'quota_exceeded': '배출 수량이 초과되었습니다.',
    "registered_date": "카페 승인 당일은 배출 요청이 불가능합니다.",
  }[availableData.value.restricted_code]
})
</script>
<template>
  <!--<div>request error</div>-->
  <!--  <div>{{message}}</div>-->
  <!--  <div>{{availableData}}</div>-->
  <div class="circle"></div>
<!--  {{availableData}}-->
<!--  {{availableData.restricted_code}}-->

  <div class="mt-[56vh]">
    <div class="cf-head-3 cf-text-gray-900 mb-3">{{title}}</div>
<!--    <div class="cf-body-2 cf-text-gray-700">신청일 기준 일요일 ~ 목요일 오전 12시 ~ 오후 9시까지 수거 신청 가능합니다. </div>-->
  </div>
</template>

<style scoped>
.circle {
  position: absolute;
  top: 9vh;
  right: -44px;
  width: 280px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 280px;
  background: var(--CF-Secondary, #DFEDFF);
  filter: blur(38px);
}
</style>