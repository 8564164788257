<script setup>
import {ref, watch} from "vue";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emits = defineEmits(['close'])

</script>

<template>
  <Transition name="alert">

  <div v-if="show" class="wrapper" @wheel.prevent @touchmove.prevent @scroll.prevent>
    <div class="w-full max-w-md">
      <div class="inner-modal-upper">
        <div class="modal-title mb-2">
          <slot name="title">제목</slot>
        </div>
        <div class="modal-body">
          <slot name="body">본문</slot>
        </div>
      </div>
      <div class="inner-modal-lower">
        <button class="alert-primary-btn w-full" type="button" @click="emits('close')">확인</button>
      </div>
    </div>
  </div>
  </Transition>
</template>

<style scoped>
.alert-primary-btn {
  display: flex;
  height: 44px;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #3182FF;
  color: #fff;
  text-align: center;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 157.143% */
}

.modal-title {
  text-align: left;
  margin-bottom: 8px;
  color: #111;
  /* Head3 */
  font-family: NanumSquare, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 133.333% */
}

.modal-body {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.wrapper {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.48);
  overflow: hidden;
  inset: 0;
  justify-content: center;
  align-items: end;
  z-index: 999999999;
}

.inner-modal-upper {
  border-radius: 16px 16px 0 0;
  background: white;
  padding: 32px 20px 0 20px;
}

.inner-modal-lower {
  background: white;
  padding: 24px 20px 24px 20px;
}



.alert-enter-active,
.alert-leave-active {
  transition: opacity 200ms ease;
}

.alert-enter-from,
.alert-leave-to {
  opacity: 0;
}

.alert-enter-active .request-alert-wrapper,
.alert-leave-active .request-alert-wrapper {
  transition: transform 200ms ease;
}

.alert-enter-from .request-alert-wrapper,
.alert-leave-to .request-alert-wrapper {
  transform: translateY(100%);
}

</style>