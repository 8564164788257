<script setup>
import { ref, computed, onUnmounted } from 'vue';
import {
  isNumericString,
  validatePhoneNumber,
  validateAuthCode,
  sendErrorLogToSentry,
} from "@/utils/utils";
import {
  TIMER_MAX_VALUE, 
  AUTHCODE_MAX_LENGTH, 
  TIMER_MAX_LABEL_VALUE,
  CommonString,
} from "@/assets/global/property"
import CommonAlert from '@/components/Popup/CommonAlert.vue';
import { PasswordResetType } from "@/type/passwordResetType"
import { putCellPhoneAuth, postCellphoneAuth } from "@/api/auth";
import { useLayoutStore } from '@/stores/layoutStore';
import router from "@/router";


// 비밀번호 리셋인지, 변경인지 체크하는 props,
// PasswordResetType 값으로 받음.
const { type } = history.state; 

const layoutStore = useLayoutStore();

// 인증번호 alert
const isShowAlert = ref(false);
const showAlert = () => {
  isShowAlert.value = true;
};
const closeAlert = () => {
  isShowAlert.value = false;
};
const onClickPopupOkayButton = () => {
  isShowAlert.value = false;
};

// input field
const phoneNumberField = ref("");
const authCodeField= ref("");

const phoneAuthId = ref(null);
const userId = ref(null);
const isSendAuthCode = ref(false);
const isNewUserValue = ref(false);

const isPhoneFieldValid = computed(() => {
  return validatePhoneNumber(phoneNumberField.value);
});

const isAuthCodeValid = computed(() => {
  return validateAuthCode(authCodeField.value);
});

const isNextButtonValid = computed(() => {
  return phoneAuthId.value && userId.value;
});

const isAuthcodeError = ref(false);

// timer
const timerCount = ref(TIMER_MAX_VALUE);
const timerLabelField = ref(TIMER_MAX_LABEL_VALUE);
const showTimerLabel = ref(false);
var authTimer = null;

// router
const routeToPasswordResetPage = () => {
  router.push({
    name: 'password-reset',
    params: {
      auth_id: phoneAuthId.value,
    }
  })
}

// MARK: event
function onClickSendAuthCode() {  
  requestPutAuthCode(phoneNumberField.value);
}

function onClickCheckAuthCode() {
  requestPostCellphoneAuth(phoneAuthId.value, authCodeField.value)
}

function onClickNextButton() {
  routeToPasswordResetPage();
}

// MARK: routing
function routeSignupPage() {
  router.push('/signup-register')
}

// MARK: function
function timerStart() {
  timerCount.value = TIMER_MAX_VALUE;
  timerLabelField.value = TIMER_MAX_LABEL_VALUE;
  showTimerLabel.value = true;

  if (authTimer !== null) {
    clearInterval(authTimer);
  }

  authTimer = setInterval(() => {
    timerCount.value--;
    timerLabelField.value = getTimerLabelValue();
    if (timerCount.value < 0) timerStop(true);
  }, 1000);
}

function timerStop(isEnd) {
  if (authTimer !== null) {
    clearInterval(authTimer);
    timerCount.value = 0;
    timerLabelField.value = "00:00";

    if (isEnd) {
      timerCount.value = 0;
    } else {
      timerCount.value = TIMER_MAX_VALUE;
    }
  }
}

function getTimerLabelValue() {
  let time = timerCount.value / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);

  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    secondes.toString().padStart(2, "0")
  );
}

function showNotFoundUserAlert() {
  layoutStore.showInfoPopup(
    CommonString.NotFoundUserTitle, 
    CommonString.NotFoundUserDescription, 
    () => {
      routeSignupPage();
    }
  );
}

// api request
async function requestPutAuthCode(phoneNumber) {
  try {
    const response = await putCellPhoneAuth(phoneNumber);

    // 인증번호 성공 항목 초기화 => 
    userId.value = null;
    isNewUserValue.value = false;

    phoneAuthId.value = response.id;
    isSendAuthCode.value = true;
    authCodeField.value = "";
    isAuthcodeError.value = false;

    timerStart();
  } catch (error) {
    if (error.response.data.message){
      alert(error.response.data.message);
    }
    sendErrorLogToSentry(error);
  }
}

async function requestPostCellphoneAuth(id, authNo) {
  try {
    const response = await postCellphoneAuth(id, authNo);

    const isNewUser = response.is_new_user;
    isNewUserValue.value = isNewUser;

    showTimerLabel.value = false;
    isAuthcodeError.value = false;
    timerStop(false);

    if (isNewUser) {      
      showNotFoundUserAlert();
    } else {
      userId.value = response.user_id;
    }
  } catch (error) {
    console.log(error);
    isAuthcodeError.value = true;
  }
}

// life-cycle
onUnmounted(() => {
  if (authTimer !== null) {
    clearInterval(authTimer);
  }
});
</script>

<template>
  <common-alert
    id="authCodeInfoAlert"
    :is-show="isShowAlert"
    :title="CommonString.AuthCodeInfoTitle"
    :description="CommonString.AuthCodeInfoDescription"
    @okayAction="onClickPopupOkayButton"    
    @closeAlert="closeAlert"
  >
  </common-alert> 
  <div>
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start">
        <p class="head_2 left page_title">비밀번호 재설정</p>
        <span class="page_subtitle subtitle_6 color_secondary">
          휴대전화 번호 인증 후 <span class="subtitle_5">비밀번호 재설정</span> 가능합니다.
        </span>

        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_start align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="phoneNumber" class="label_2 mb-1"
                >휴대전화 번호</label
              >
              <input
                class="input input-field body_2"
                
                v-model="phoneNumberField"
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="휴대폰 번호(-없이 입력)"
              />
            </div>
            <button
              @click="onClickSendAuthCode"
              class="action_button body_2"
              :class="isSendAuthCode ? 'action_button2' : 'action_button'"
              :disabled="!isPhoneFieldValid"
            >
              {{ isSendAuthCode ? "인증번호 재전송" : "인증번호 전송" }}
            </button>
            <p
              class="error_message body_4 color_warning left"
              v-show="!isPhoneFieldValid && phoneNumberField.length > 0"
              style="margin-top: 2px"
            >
              휴대전화 번호를 확인해 주세요.
            </p>
          </div>
        </div>

        <!-- 인증 번호 -->
        <div class="input_row" style="margin-bottom: 0">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="number" class="label_2 mb-1">인증 번호</label>
              <div class="input-container">
                <input
                  class="input input-field body_2"
                  :class="{
                    is_error:
                      (!isAuthCodeValid && authCodeField.length > 0) ||
                      isAuthcodeError ||
                      timerCount === 0,
                  }"
                  v-model="authCodeField"
                  :disabled="userId !== null"
                  inputmode="numeric"
                  name="number"
                  id="number"
                  step="0"
                  placeholder="6자리 입력"
                  :maxlength="AUTHCODE_MAX_LENGTH"
                  autocomplete="one-time-code" />
                <label
                  class="input-timer-label color_warning body_4"
                  v-show="showTimerLabel">
                  {{ timerLabelField }}</label
                >
              </div>
            </div>
            <button
              @click="onClickCheckAuthCode"
              class="action_button body_2"
              :disabled="
                !(phoneAuthId != null  && isAuthCodeValid) || timerCount === 0 || userId !== null
              ">
              확인
            </button>
          </div>
          <div
            style="height: 14px"
            v-bind:class="{
              hidden: !(
                (!isAuthCodeValid && authCodeField.length > 0) ||
                isAuthcodeError ||
                timerCount === 0
              ),
            }">
            <span
              class="body_4 color_warning left"
              v-if="timerCount === 0"
              style="margin-top: 2px">
              입력 시간이 초과되었습니다. 인증번호 전송 버튼을 다시 눌러주세요.
            </span>
            <p
              class="body_4 color_warning left"
              v-else-if="
                (!isAuthCodeValid && authCodeField.length > 0) ||
                isAuthcodeError
              "
              style="margin-top: 2px">
              인증번호를 확인해주세요.
            </p>
          </div>
        </div>
        <span
          @click="showAlert"
          class="subtitle_7 color_primary text_underline"
          style="margin: 20px 0px; cursor: pointer">
          인증번호를 받지 못하셨나요?
        </span>

        <!-- 확인 버튼 -->
        <div class="button_wrap flex flex_col justify_center align_center">
          <button
            class="next_button label_1"
            @click="onClickNextButton"
            :disabled="!isNextButtonValid"
          >
            확인
          </button>
        </div>

      </div>            
    </div>    
  </div>
</template>

<style scoped>
.single-content-page-wrap .page_subtitle {
  margin-bottom: 60px;
}

.input_row {
  width: 100%;
  margin-bottom: 30px;
}

.action_button {
  height: 40px;
  width: 110px;
  background-color: #132b4f;
  color: white;
  border-radius: 4px;
}
.action_button:disabled {
  height: 40px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  color: #7a7a7a;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}
.input-field {
  width: 100%;
  padding-right: 50px;
}
.input-timer-label {
  position: absolute;
  right: 10px;
  background-color: transparent;
  /* pointer-events: none; */
}

.button_wrap {
  width: 100%;
  padding-top: 40px;
  gap: 10px;
}

.next_button {
  width: 100%;
  height: 50px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}
.next_button:disabled {
  width: 100%;
  height: 50px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}

@media screen and (max-width: 992px) {
  .head_2 {
    font-size: 28px;
    line-height: 40px;
  }

  .single-content-page-wrap .page_subtitle {
    margin-bottom: 22px;
  }

  .page_subtitle.subtitle_6 {
    font-size: 14px;
    line-height: 28px;
  }

  .subtitle_5 {
    font-size: 14px;
    line-height: 28px;
    font-weight: 500;
  }

  .next_button {
    width: 100%;
    margin-bottom: 100px;
  }
}
</style>