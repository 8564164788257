<script setup>
import {
  openPrivacyTerms,
  openServiceTerms,
  openZguhadaBlog,
} from "@/utils/outsideLink";

function clickAppstore() {
  window.open("https://apps.apple.com/app/id6459451210", "_blank");
}

function clickPlaystore() {
  window.open(
    "https://play.google.com/store/apps/details?id=com.chunil.zguhada.platform.app.zguhada_platform",
    "_blank"
  );
}
</script>

<template>
  <footer>
    <div class="footer_wrap flex flex_row justify_space_between align_stretch">
      <div class="only_pc_block">
        <div class="mo_width_100">
          <div
            class="footer_nav_wrap footer_nav_link_list flex flex_row justify_start align_center"
          >
            <a
              @click="openServiceTerms"
              class="footer_nav body_1 color_secondary"
              >이용약관</a
            >
            <a
              @click="openPrivacyTerms"
              class="footer_nav body_1 color_secondary"
              >개인정보처리방침</a
            >
            <a class="footer_nav body_1 color_secondary">사업자정보</a>
          </div>
          <div
            class="flex flex_col justify_start align_start body_2 g300"
            style="gap: 4px"
          >
            <span>주식회사 지구하다</span>
            <div class="flex justify_start align_center">
              <span>대표 : 박상원</span>
              <span class="footer_divid"></span>
              <span>사업자등록번호 : 297-86-01887</span>
            </div>
            <span>개인정보관리책임자 : 홍찬의</span>
            <span>경기도 평택시 포승읍 도대1로 70</span>
          </div>
        </div>
      </div>

      <div class="mo_width_100 only_pc_block">
        <div
          class="footer_nav_wrap flex flex_row justify_end align_center mo_flex_left"
          style="padding: 10px 0px"
        >
          <span class="body_1 color_secondary" style="margin-right: 10px"
            >고객센터</span
          >
          <span class="body_1 color_secondary">1660-3114</span>
        </div>
        <div class="flex flex_col justify_space_between align_end mo_flex_left">
          <div>
            <p class="right mo_text_left">
              <span class="body_1 g300" style="margin-right: 14px">월- 금</span>
              <span class="body_1 g300">09:00 ~ 18:00</span>
            </p>
            <p
              class="right mo_text_left"
              style="margin-top: 2px; margin-bottom: 24px"
            >
              <span class="body_1 g300" style="margin-right: 18px"
                >점심시간</span
              >
              <span class="body_1 g300">12:00 ~ 13:00</span>
            </p>
          </div>
        </div>

        <div
          class="footer_btn_small_link_wrap flex flex_row justify_end align_end only_pc_flex"
        >
          <button @click="openZguhadaBlog">
            <img
              src="@/assets/image/img_btn_small_link_naver_blog.svg"
              alt="naver_blog"
            />
          </button>
          <button v-on:click="clickPlaystore()">
            <img
              src="@/assets/image/img_btn_small_link_playstore.svg"
              alt="playstore"
            />
          </button>
          <button v-on:click="clickAppstore()">
            <img
              src="@/assets/image/img_btn_small_link_appstore.svg"
              alt="appstore"
            />
          </button>
        </div>
      </div>

      <!-- mobile -->
      <div class="mo_width_100 only_mo_block footer_nav_wrap">
        <div
          class="footer_btn_small_link_wrap flex flex_row justify_start align_start"
        >
          <button @click="openZguhadaBlog">
            <img
              src="@/assets/image/img_btn_small_link_naver_blog.svg"
              alt="naver_blog"
            />
          </button>
          <button v-on:click="clickPlaystore()">
            <img
              src="@/assets/image/img_btn_small_link_playstore.svg"
              alt="playstore"
            />
          </button>
          <button v-on:click="clickAppstore()">
            <img
              src="@/assets/image/img_btn_small_link_appstore.svg"
              alt="appstore"
            />
          </button>
        </div>
        <div class="flex flex_col" style="gap: 20px; margin-top: 20px">
          <span class="subtitle_7 color_secondary"> 고객센터 1660-3114 </span>
          <div
            class="flex flex_col justify_space_between align_end mo_flex_left"
          >
            <div>
              <p class="right mo_text_left">
                <span class="subtitle_7 g300" style="margin-right: 10px"
                  >월- 금</span
                >
                <span class="subtitle_7 g300">09:00 ~ 18:00</span>
              </p>
              <p class="right mo_text_left" style="margin-top: 6px">
                <span class="subtitle_7 g300" style="margin-right: 10px"
                  >점심시간</span
                >
                <span class="subtitle_7 g300">12:00 ~ 13:00</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="mo_divide"></div>

          <div class="flex flex_row justify_start align_center">
            <div
              class="footer_nav_wrap footer_nav_link_list flex flex_row justify_start align_center"
            >
              <a
                @click="openServiceTerms"
                class="footer_nav subtitle_7 color_secondary"
                >이용약관</a
              >
              <a
                @click="openPrivacyTerms"
                class="footer_nav subtitle_7 color_secondary"
                >개인정보처리방침</a
              >
              <a class="footer_nav subtitle_7 color_secondary">사업자정보</a>
            </div>
          </div>
          <div
            class="company-info flex flex_col justify_start align_start left"
          >
            <span>주식회사 지구하다</span>
            <div class="left flex justify_start align_center body_3 g300">
              <span class="body_3 g300 left">대표 : 박상원</span>
              <span class="footer_divid"></span>
              <span class="body_3 g300 left"
                >사업자등록번호 : 297-86-01887</span
              >
            </div>
            <span>개인정보관리책임자 : 홍찬의</span>
            <span>경기도 평택시 포승읍 도대1로 70</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.company-info {
  color: #7a7a7a;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  gap: 4px;
}
</style>
