const openPrivacyTerms = () => {
  const url =
    "https://sugerbot.notion.site/2023-12-01-868d1c7127c64e2bb5b0bdda2cf0a5da";
  window.open(url);
};

const openCancelAndRefundTerms = () => {
  const url =
    "https://sugerbot.notion.site/863fb71b659b45cf87c1d2237e5895f4?pvs=4";
  window.open(url);
};

const openServiceTerms = () => {
  const url =
    "https://sugerbot.notion.site/2023-12-01-960427a58ddb481fa49700317ed02fea";
  window.open(url);
};

const openMarketingTerms = () => {
  const url = "https://sugerbot.notion.site/6b4e218bcca54e7f856f70deb0cafba8";
  window.open(url);
};

const openZguhadaBlog = () => {
  const url = "https://blog.naver.com/zguhada";
  window.open(url);
};

export {
  openPrivacyTerms,
  openCancelAndRefundTerms,
  openServiceTerms,
  openMarketingTerms,
  openZguhadaBlog,
};
