<script setup>
import { computed, ref } from "vue";
import { formatDate } from "@/utils/utils";
import { useRequestStore } from "@/stores/requestStore";
import router from "@/router";

const requestStore = useRequestStore();

const props = defineProps({});

const { isCardPayment, requestId, depositDeadLine } = history.state;

const today = computed(() => {
  return formatDate(new Date());
});

// function

function routeMain() {
  router.push("/");
}

function routeHistory() {
  router.push("/my/history");
}

function clearRequestData() {
  requestStore.resetAllData();
}

async function onClickCopyAccountNumber() {
  const accountNumber = requestStore.bankInfo.accountNumber;

  try {
    await window.navigator.clipboard.writeText(accountNumber);
    alert("계좌가 복사되었습니다.");
  } catch (err) {
    alert("복사에 실패했습니다.");
    console.error(err);
  }
}

function onClickRouteMainButton() {
  clearRequestData();
  routeMain();
}

function onClickRouteHistoryButton() {
  clearRequestData();
  routeHistory();
}
</script>

<template>
  <div id="pc_Service_05">
    <template v-if="isCardPayment">
      <!-- 배출신고 완료(신용/체크카드) -->
      <div class="page_container flex flex_col justify_start align_center">
        <div class="single-content-page-wrap">
          <div class="flex flex_col justify_center align_center">
            <p class="head_2 center">배출신고</p>
            <p class="sub_title subtitle_6 color_caption center">
              신고가 접수되었습니다.
            </p>
            <img
              class="done_img"
              src="@/assets/image/img_success_logo1.svg"
              alt="배출신고 완료"
            />
            <div class="date_wrap">
              <p
                class="subtitle_6 color_secondary center"
                style="margin-bottom: 4px"
              >
                배출번호
              </p>
              <div
                class="date_box flex justify_center align_center center subtitle_1 color_primary"
              >
                {{ requestId }}
              </div>
              <p class="body_2 color_secondary center">
                위 배출번호를 모든 배출 품목에 잘 보이도록 크게 <br />
                유성펜으로 적거나 종이에 적어 부착해 주세요.
              </p>
            </div>

            <div class="button_wrap flex flex_col justify_center align_center">
              <button
                @click="onClickRouteHistoryButton"
                id="go_to_detail"
                class="label_1"
              >
                신청내역 확인
              </button>
              <button
                @click="onClickRouteMainButton"
                id="go_to_main"
                class="label_1"
              >
                메인으로
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- 배출신고 완료(계좌이체) -->
      <div class="page_container flex flex_col justify_start align_center">
        <div class="single_page_wrap">
          <div class="flex flex_col justify_center align_center">
            <p class="head_2 center">배출신고</p>
            <p class="sub_title subtitle_6 color_caption center">
              신고가 접수되었습니다.
            </p>
            <img
              class="done_img"
              src="@/assets/image/img_success_logo1.svg"
              alt="배출신고 완료"
            />
            <div class="date_wrap">
              <div
                class="date_box flex justify_center align_center subtitle_1 color_primary center"
              >
                {{ depositDeadLine }}
              </div>
              <p class="body_2 color_secondary center">
                위 기한까지 입금을 완료해 주세요. 결제 완료 후 서비스가
                진행됩니다.
              </p>
            </div>
            <div class="info_container">
              <div class="info_wrap flex justify_space_between align_center">
                <p class="subtitle_5 color_secondary left">입금금액</p>
                <p class="subtitle_5 color_primary right">
                  {{ $formatNumber(requestStore.getTotalPrice()) }}
                  <span class="subtitle_7 color_secondary"> &nbsp;원 </span>
                </p>
              </div>
              <div class="divide"></div>

              <div
                class="info_wrap flex flex_col justify_space_between align_start"
              >
                <div
                  class="payment_info flex flex_col justify_start align_start"
                >
                  <div class="flex justify_space_between align_center">
                    <p class="subtitle_7 color_secondary left">예금주</p>
                    <p class="body_2 color_secondary right">
                      {{ requestStore.bankInfo.accountHolder }}
                    </p>
                  </div>
                  <div class="flex justify_space_between align_center">
                    <p class="subtitle_7 color_secondary left">입금은행</p>
                    <p class="body_2 color_secondary right">
                      {{ requestStore.bankInfo.bankDisplay }}
                    </p>
                  </div>
                  <div class="flex justify_space_between align_center">
                    <p class="subtitle_7 color_secondary left">계좌 번호</p>
                    <p class="body_2 color_secondary right">
                      {{ requestStore.bankInfo.accountNumber }}
                    </p>
                  </div>
                </div>
                <button
                  @click="onClickCopyAccountNumber"
                  class="copy_button subtitle_7"
                >
                  계좌번호 복사하기
                </button>
              </div>
            </div>

            <div class="button_wrap flex flex_col justify_center align_center">
              <button
                @click="onClickRouteHistoryButton"
                id="go_to_detail"
                class="label_1"
              >
                신청내역 확인
              </button>
              <button
                @click="onClickRouteMainButton"
                id="go_to_main"
                class="label_1"
              >
                메인으로
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
#pc_Service_05 .sub_title {
  margin-top: 20px;
  margin-bottom: 50px;
}
#pc_Service_05 .done_img {
  width: 320px;
  height: 320px;
}

#pc_Service_05 .date_wrap {
  width: 100%;
  margin: 50px auto;
}
#pc_Service_05 .date_wrap .date_box {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 78px;
  background: rgba(193, 200, 212, 0.2);
  margin-bottom: 10px;
}

#pc_Service_05 .info_container .info_wrap {
  width: 100%;
}
#pc_Service_05 .divide {
  width: 100%;
  height: 1px;
  background-color: #e7ecf4;
  margin: 20px 0px;
}

#pc_Service_05 .info_container .info_wrap .payment_info {
  width: 100%;
  margin-bottom: 10px;
  gap: 6px;
}
#pc_Service_05 .info_container .info_wrap .payment_info .flex {
  width: 100%;
}

#pc_Service_05 .button_wrap {
  padding-top: 60px;
  gap: 10px;
  width: 100%;
  margin-bottom: 120px;
}
#pc_Service_05 .button_wrap button {
  width: 100%;
  border-radius: 4px;
}
#pc_Service_05 .button_wrap button#go_to_detail {
  background: #1e64ff;
  color: white;
  height: 50px;
}

#pc_Service_05 .button_wrap button#go_to_main {
  border: 1px solid #b4b4b4;
  border-radius: 4px;
  color: #b4b4b4;
  height: 50px;
}

.copy_button {
  border-radius: 4px;
  border: 1px #142b4f solid;
  width: 100%;
  height: 40px;
}

.info_container {
  width: 100%;
  border-radius: 4px;
  border: 1px #e7ecf4 solid;
  padding: 20px;
}
</style>
