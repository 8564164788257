<script setup>

</script>

<template>
  <div class="flex gap-x-[4px] align-start mb-2">
    <div class="exclamation-mark mt-1">
      <svg fill="#fff" height="8" viewBox="0 0 2 8" width="2" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.349609 8V6.85144H1.64961V8H0.349609ZM1.47856 5.82272H0.520662L0.361013 0H1.63821L1.47856 5.82272Z"
            fill="white"/>
      </svg>
    </div>
    <div class="exclamation-text">
      <slot/>
    </div>
  </div>
</template>

<style scoped>

</style>