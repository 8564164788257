<script setup>

</script>

<template>
  <div class="flex items-center justify-start gap-x-2">
    <slot name="acc"></slot>
    <div class="circle">
      <div class="cf-title-4 text-white">
        <slot name="number">1</slot>
      </div>
    </div>
    <div class="cf-title-4 cf-text-gray-900">
      <slot name="title">내용</slot>
    </div>
  </div>
</template>

<style scoped>
.circle {
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: black;
}
</style>