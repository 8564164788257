<script setup>
import { onUnmounted, ref, computed } from "vue";
import router from "@/router";
import {
  isNumericString,
  validatePhoneNumber,
  validateAuthCode,
  sendErrorLogToSentry,
} from "@/utils/utils";
import { CommonString } from "@/assets/global/property";
import { putCellPhoneAuth, postCellphoneAuth } from "@/api/auth";
import { useLayoutStore } from "@/stores/layoutStore";

const TIMER_MAX_VALUE = 60 * 3;
const TIMER_MAX_LABEL_VALUE = "03:00";
const AUTHCODE_MAX_LENGTH = 6;

const phoneNumberField = ref("");
const authCodeField = ref("");

// store
const layoutStore = useLayoutStore();

// 인증번호 alert
const isShowAlert = ref(false);
const showAlert = () => {
  isShowAlert.value = true;
};
const closeAlert = () => {
  isShowAlert.value = false;
};
const onClickPopupOkayButton = () => {
  isShowAlert.value = false;
};

// 기유저 alert
const showAlreadyUserAlert = ref(false);

const alreadyOkayAction = () => {
  showAlreadyUserAlert.value = false;
  routerLogin();
};

const alreadyCloseAction = () => {
  showAlreadyUserAlert.value = false;
};

// error alert
const isShowErrorAlert = ref(false);
const errorAlertTitle = ref("");
const onClickErrorPopupClose = () => {
  isShowErrorAlert.value = false;
};
const onClickErrorPopupOkay = () => {
  isShowErrorAlert.value = false;
};

const phoneAuthId = ref(null);
const userId = ref(null);
const isSendAuthCode = ref(false);
const isNewUserValue = ref(false);

const isAuthcodeError = ref(false);

const isPhoneFieldValid = computed(() => {
  return validatePhoneNumber(phoneNumberField.value);
});

const isAuthCodeValid = computed(() => {
  return validateAuthCode(authCodeField.value);
});

const isNextButtonValid = computed(() => {
  return isNewUserValue.value && phoneAuthId.value !== null;
});

// timer
const timerCount = ref(TIMER_MAX_VALUE);
const timerLabelField = ref(TIMER_MAX_LABEL_VALUE);
const showTimerLabel = ref(false);
var authTimer = null;

// function

function routerRegister() {
  router.push({
    name: "signup-register",
    state: {
      phoneNumber: phoneNumberField.value,
      phoneAuthId: phoneAuthId.value,
    },
  });
}

function routerLogin() {
  router.push({
    name: "login",
    state: {
      statePhoneNumber: phoneNumberField.value,
      statePhoneAuthId: phoneAuthId.value,
      stateUserId: userId.value,
      stateAuthCode: authCodeField.value,
    },
  });
}

function timerStart() {
  timerCount.value = TIMER_MAX_VALUE;
  timerLabelField.value = TIMER_MAX_LABEL_VALUE;
  showTimerLabel.value = true;

  if (authTimer !== null) {
    clearInterval(authTimer);
  }

  authTimer = setInterval(() => {
    timerCount.value--;
    timerLabelField.value = getTimerLabelValue();
    if (timerCount.value < 0) timerStop(true);
  }, 1000);
}

function timerStop(isEnd) {
  if (authTimer !== null) {
    clearInterval(authTimer);
    timerCount.value = 0;
    timerLabelField.value = "00:00";

    if (isEnd) {
      timerCount.value = 0;
    } else {
      timerCount.value = TIMER_MAX_VALUE;
    }
  }
}

function getTimerLabelValue() {
  let time = timerCount.value / 60;
  let minutes = parseInt(time);
  let secondes = Math.round((time - minutes) * 60);

  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    secondes.toString().padStart(2, "0")
  );
}

function onClickSendAuthCode() {
  const phoneNumber = phoneNumberField.value;

  if (phoneNumber === "") {
    isShowErrorAlert.value = true;
    errorAlertTitle.value = "전화번호를 입력해야합니다.";
    return;
  } else if (!isNumericString(phoneNumber)) {
    isShowErrorAlert.value = true;
    errorAlertTitle.value = "전화번호는 숫자만 입력하셔야 합니다.";
    return;
  }

  phoneAuthId.value = null;
  userId.value = null;

  requestPutAuthCode(phoneNumber);
}

function onClickCheckAuthCode() {
  const authId = phoneAuthId.value;
  const authCode = authCodeField.value;

  if (authCode.length < 6) {
    isShowErrorAlert.value = true;
    errorAlertTitle.value = "인증번호를 입력해주세요.";
    return;
  }

  requestPostCellphoneAuth(authId, authCode);
}

function onClickNextButton() {
  if (isNewUserValue) {
    routerRegister();
  } else {
    showAlreadyUserAlert.value = true;
  }
}

// api request
async function requestPutAuthCode(phoneNumber) {
  try {
    const response = await putCellPhoneAuth(phoneNumber);

    phoneAuthId.value = response.id;
    isSendAuthCode.value = true;
    authCodeField.value = "";
    isAuthcodeError.value = false;

    timerStart();
  } catch (error) {
    if (error.response.data.message){
      alert(error.response.data.message);
    }
    sendErrorLogToSentry(error);
  }
}

async function requestPostCellphoneAuth(id, authNo) {
  try {
    const response = await postCellphoneAuth(id, authNo);

    const isNewUser = response.is_new_user;
    isNewUserValue.value = isNewUser;

    showTimerLabel.value = false;
    isAuthcodeError.value = false;
    timerStop(false);

    /*
    !(phoneAuthId !== null && isAuthCodeValid) ||
                timerCount === 0 ||
                userId !== null
    */

    if (isNewUser) {
      // do nothing
    } else {
      userId.value = response.user_id;
      showAlreadyUserAlert.value = true;
    }
  } catch (error) {
    isAuthcodeError.value = true;
  }
}

// life-cycle
onUnmounted(() => {
  if (authTimer !== null) {
    clearInterval(authTimer);
  }
});
</script>

<template>
  <common-alert
    id="authCodeInfoAlert"
    :is-show="isShowAlert"
    :title="CommonString.AuthCodeInfoTitle"
    :description="CommonString.AuthCodeInfoDescription"
    @okayAction="onClickPopupOkayButton"
    @closeAlert="closeAlert"
  >
  </common-alert>
  <common-alert
    id="errorAlert"
    :is-show="isShowErrorAlert"
    :title="errorAlertTitle"
    description="​"
    @okayAction="onClickErrorPopupOkay"
    @closeAlert="onClickErrorPopupClose"
  >
  </common-alert>
  <common-alert
    id="alreadyUserAlert"
    :is-show="showAlreadyUserAlert"
    :title="CommonString.AlreadyUserTitle"
    :description="CommonString.AlreadyUserDescription"
    @okayAction="alreadyOkayAction"
    @closeAlert="alreadyCloseAction"
  >
  </common-alert>
  <div id="auth_step">
    <div class="page_container flex flex_col justify_start align_center">
      <div
        class="single-content-page-wrap flex flex_col justify_center align_start"
      >
        <p class="head_2 left page_title">회원가입</p>
        <span class="page_subtitle subtitle_6 color_secondary">
          서비스 이용을 위해 <span class="subtitle_5"> 본인 확인</span>을
          해주세요.
        </span>

        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_start align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="phoneNumber" class="label_2 mb-1"
                >휴대전화 번호</label
              >
              <input
                class="input input-field body_2"
                :class="{
                  is_error: !isPhoneFieldValid && phoneNumberField.length > 0,
                }"
                v-model="phoneNumberField"
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="휴대폰 번호(-없이 입력)"
              />
            </div>
            <button
              @click="onClickSendAuthCode"
              class="action_button body_2"
              :class="isSendAuthCode ? 'action_button2' : 'action_button'"
              :disabled="!isPhoneFieldValid"
            >
              {{ isSendAuthCode ? "인증번호 재전송" : "인증번호 전송" }}
            </button>
            <p
              class="error_message body_4 color_warning left"
              v-show="!isPhoneFieldValid && phoneNumberField.length > 0"
              style="margin-top: 2px"
            >
              휴대전화 번호를 확인해 주세요.
            </p>
          </div>
        </div>

        <div class="input_row">
          <div class="input_wrap2 flex flex_row justify_center align_end">
            <div class="grow flex flex_col justify_start align_stretch">
              <label for="number" class="label_2 mb-1">인증 번호</label>
              <div class="input-container">
                <input
                  class="input input-field body_2"
                  :class="{
                    is_error:
                      (!isAuthCodeValid && authCodeField.length > 0) ||
                      isAuthcodeError ||
                      timerCount === 0,
                  }"
                  v-model="authCodeField"
                  :disabled="userId !== null"
                  inputmode="numeric"
                  name="number"
                  id="number"
                  placeholder="6자리 입력"
                  :maxlength="AUTHCODE_MAX_LENGTH"
                  autocomplete="one-time-code"
                />
                <label
                  class="input-timer-label color_warning body_4"
                  v-show="showTimerLabel"
                >
                  {{ timerLabelField }}</label
                >
              </div>
            </div>
            <button
              @click="onClickCheckAuthCode"
              class="action_button body_2"
              :disabled="
                !(phoneAuthId !== null && isAuthCodeValid) ||
                timerCount === 0 ||
                userId !== null
              "
            >
              확인
            </button>
          </div>
          <div class="flex flex_col">
            <div
              class="flex flex_col"
              style="height: 14px"
              v-bind:class="{
                hidden: !(
                  (!isAuthCodeValid && authCodeField.length > 0) ||
                  isAuthcodeError ||
                  timerCount === 0
                ),
              }"
            >
              <span
                class="body_4 color_warning left"
                v-if="timerCount === 0"
                style="margin-top: 2px"
              >
                입력 시간이 초과되었습니다. 인증번호 전송 버튼을 다시
                눌러주세요.
              </span>
              <span
                class="body_4 color_warning left"
                v-else-if="
                  (!isAuthCodeValid && authCodeField.length > 0) ||
                  isAuthcodeError
                "
                style="margin-top: 2px"
              >
                인증번호를 확인해주세요.
              </span>
            </div>

            <span
              @click="showAlert"
              class="color_primary subtitle_7 text_underline"
              style="cursor: pointer; margin-top: 4px"
            >
              인증번호를 받지 못하셨나요?
            </span>
          </div>
        </div>

        <div class="button_wrap flex flex_col justify_center align_center">
          <button
            class="next_button label_1"
            @click="onClickNextButton"
            :disabled="!isNextButtonValid"
          >
            다음
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alert_box {
  margin-top: 60px;
}
#auth_step .input_row {
  width: 100%;
  margin-bottom: 30px;
}

#auth_step .input_row {
  width: 100%;
  margin-bottom: 30px;
}

#auth_step .button_wrap {
  width: 100%;
  padding-top: 30px;
  gap: 10px;
}

.page_subtitle {
  margin-bottom: 60px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-field {
  width: 100%;
  padding-right: 50px;
}

.input-timer-label {
  position: absolute;
  right: 10px;
  background-color: transparent;
  pointer-events: none;
  color: #e21f71;
}

.action_button {
  height: 40px;
  width: 110px;
  background-color: #132b4f;
  color: white;
  border-radius: 4px;
}
.action_button2 {
  height: 40px;
  width: 110px;
  background-color: white;
  color: #132b4f;
  border: #132b4f 1px solid;
  border-radius: 4px;
}

.action_button2:disabled {
  height: 40px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  border: none;
  color: #7a7a7a;  
}
.action_button:disabled {
  height: 40px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  color: #7a7a7a;
}

.next_button {
  width: 100%;
  height: 50px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}
.next_button:disabled {
  width: 100%;
  height: 50px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}

@media screen and (max-width: 992px) {
  .page_subtitle {
    margin-bottom: 28px;
  }

  .head_2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  .subtitle_5,
  .subtitle_6 {
    font-size: 14px;
    line-height: 26px;
  }

  #auth_step .action_button {
    width: 98px;
  }

  #auth_step .button_wrap {
    margin-bottom: 50px;
  }
}
</style>
