import axiosServices from "@/utils/axios/axios.js";
import {useCoffeeStore} from "@/stores/coffeeStore.js";

const apiUrl = import.meta.env.VITE_BACKEND_HOST

const disposeStatus = {
    'rq': '수거 예정',
    'cc': '취소',
    'cp': '수거 완료',
    'fi': '수거 실패',
    'rj': '강제 취소',
}

const disposeStatusEnum = Object.keys(disposeStatus).map((key) => {
    return {'value': key, 'label': disposeStatus[key]}
})

const getToken = async () => {
    // access token을 받아오는 API
}

const refreshToken = async () => {
    // refresh token을 받아오는 API
}

const checkToken = async () => {
    // access token 유효성을 받아오는 API
}


const getRegions = async () => {
    // 구역 조회
}

const checkRegion = async (bcode) => {
    // 구역 체크
    let config = {
        method: 'post',
        url: `${apiUrl}coffee/app/v1/region/`,
        params: {"bcode": bcode},
        validateStatus: function (status) {
            return status === 404 || status === 200
        }
    };
    const response = await axiosServices(config)
    return {success: response.status === 200, data: response.data}
}

const getCafe = async () => {
    // 로그인 사용자의 등록 배출업체를 가져온다.
    const coffeeStore = useCoffeeStore()
    coffeeStore.setLoading(true)
    let config = {
        method: 'get',
        url: `${apiUrl}coffee/app/v1/cafe/`,
    };
    const response = await axiosServices(config)
    const cafeData = response.data
    coffeeStore.setCafe(cafeData)
    coffeeStore.setLoading(false)
    return cafeData
}

const requestRegisterCafe = async (data) => {
    // 배출업체 등록 신청
    let config = {
        method: 'post',
        url: `${apiUrl}coffee/app/v1/cafe/register/`,
        data: data
    };
    const response = await axiosServices(config)
    return response.data
}

const getRequestRegister = async () => {
    // 배출업체 등록 신청 조회
    let config = {
        method: 'get',
        url: `${apiUrl}coffee/app/v1/cafe/register/`,
        validateStatus: function (status) {
            return status === 404 || status === 200
        }
    };
    const response = await axiosServices(config)
    if (response.status === 404) {
        return null
    }
    return response.data
}

const checkDisposeAvailable = async () => {
    // 배출 가능여부 조회
    let config = {
        method: 'get',
        url: `${apiUrl}coffee/app/v1/dispose/request/`,
    };
    try {
        const response = await axiosServices(config)
        return response.data
    } catch (e) {
        let errName = e?.name
        if (errName === 'AxiosError') {
            return e.response.data
        }
        throw e
    }

}

const requestDispose = async () => {
    // 배출 신청
}

const listDispose = async (status, lastId) => {
    // 배출 신청 목록 조회
    let statusQuery = status ? status : null
    let lastIdQuery = lastId ? lastId : null
    let config = {
        method: 'get',
        url: `${apiUrl}coffee/app/v1/dispose/`,
        params: {"status": statusQuery, "last_id": lastIdQuery},
    };
    try {
        const response = await axiosServices(config)
        console.log(response)
        return response.data
    } catch (e) {
        let errName = e?.name
        if (errName === 'AxiosError') {
            return e.response.data
        }
        throw e
    }
}

const disposeDetail = async (collectionId) => {
    // 배출 신청 상세 조회
    let config = {
        method: 'get',
        url: `${apiUrl}coffee/app/v1/dispose/${collectionId}/`,
    };
    try {
        const response = await axiosServices(config)
        return response.data
    } catch (e) {
        let errName = e?.name
        if (errName === 'AxiosError') {
            return e.response.data
        }
        throw e
    }
}

const cancelDispose = async (collectionId) => {
    // 배출 신청 취소
    let config = {
        method: 'delete',
        url: `${apiUrl}coffee/app/v1/dispose/${collectionId}/`,
    };
    try {
        const response = await axiosServices(config)
        return null
    } catch (e) {
        let errName = e?.name
        if (errName === 'AxiosError') {
              let errors = e.response.data.errors
              let errorString = errors.reduce((acc, cur) => {
                return acc + `${cur.detail}\n`
              }, '')
              throw new Error(errorString)
        } else {
            throw e
        }
    }
}

export {
    disposeStatus, disposeStatusEnum, getCafe, requestRegisterCafe, getRequestRegister, checkDisposeAvailable,
    checkRegion, disposeDetail, cancelDispose, listDispose
}