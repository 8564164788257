<script setup>

</script>

<template>
  <div class="flex items-start justify-start gap-x-2">
    <slot name="acc"></slot>
    <div class="circle">
      <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <g id="icon-checked" clip-path="url(#clip0_946_780)">
          <path id="Vector"
                d="M15.2679 4.73899L14.1697 5.83998C14.4056 6.51669 14.5338 7.24403 14.5338 8.00067C14.5338 11.624 11.5934 14.572 7.97941 14.572C4.36539 14.572 1.42501 11.6233 1.42501 8C1.42501 4.37665 4.36539 1.42869 7.97941 1.42869C9.70271 1.42869 11.2725 2.09941 12.4431 3.19307L13.4536 2.18C12.7593 1.52327 11.9641 1.00175 11.0852 0.628757C10.1013 0.211806 9.0563 0 7.97941 0C6.90251 0 5.8575 0.211806 4.87294 0.628757C3.92294 1.03172 3.06926 1.60853 2.33649 2.34319C1.60372 3.07785 1.0284 3.93306 0.626474 4.88619C0.211261 5.87262 0 6.92032 0 8C0 9.07968 0.211261 10.1274 0.627138 11.1145C1.02906 12.0669 1.60438 12.9228 2.33715 13.6575C3.06992 14.3921 3.92294 14.9689 4.87361 15.3719C5.8575 15.7895 6.90317 16.0007 7.98007 16.0007C9.05697 16.0007 10.102 15.7889 11.0865 15.3719C12.0365 14.9689 12.8902 14.3921 13.623 13.6575C14.3558 12.9228 14.9311 12.0676 15.333 11.1145C15.7495 10.128 15.9601 9.07968 15.9601 8C15.9601 6.92032 15.7489 5.87262 15.333 4.88552C15.3124 4.83623 15.2905 4.78761 15.2692 4.73899H15.2679Z"
                fill="#111111"/>
          <path id="Vector_2"
                d="M8.35543 10.8271C8.15413 11.029 7.82861 11.029 7.62731 10.8271L4.50026 7.69202C4.12955 7.32036 4.12955 6.71691 4.50026 6.34525C4.87096 5.9736 5.47285 5.9736 5.84355 6.34525L7.99137 8.49861L14.3783 2.09515C14.7491 1.72349 15.3509 1.72349 15.7216 2.09515C16.0924 2.46681 16.0924 3.07026 15.7216 3.44192L8.35543 10.8271Z"
                fill="#3182FF"/>
        </g>
        <defs>
          <clipPath id="clip0_946_780">
            <rect fill="white" height="16" width="16"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div>
      <div class="cf-head-6 cf-text-gray-900 text-left mb-2">
        <slot name="title">제목</slot>
      </div>
      <div class="cf-body-2 cf-text-gray-700 text-left">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped></style>