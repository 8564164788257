import { createApp } from 'vue';
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag";
import vuetify from "@/plugins/vuetify";
import "vuetify/styles";
import dayjs from "dayjs";

import { useUserStore } from "@/stores/userStore";
import { useLayoutStore } from "@/stores/layoutStore";

import authAxiosServices from "@/utils/axios/authAxios";

// calendar
// import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import VCalendar from "v-calendar";

// vue-number-format
import VueNumberFormat from "@coders-tm/vue-number-format";

//i18
import { createI18n } from "vue-i18n";

// custom popup
import CommonAlert from "@/components/Popup/CommonAlert.vue";
import CommonPopup from "@/components/Popup/CommonPopup.vue";

// css import
import "@/css/common.css";
import "@/css/common2.css";
import "@/css/footer.css";
import "@/css/checkbox.css";
import "@/css/header_popup.css";
import "@/css/service_common.css";
import "@mdi/font/css/materialdesignicons.css";

import router from "./router";
import App from "./App.vue";
import axios from "axios";

const app = createApp(App);

// dayjs
app.config.globalProperties.$dayjs = dayjs;
// const { proxy } = getCurrentInstance();

// axios setting
app.config.globalProperties.$axios = authAxiosServices;

// store setting
app.use(createPinia());

// router setting
app.use(router);

// vuetify setting
app.use(vuetify);

// app.component("VueCalendar", Calendar);
app.use(VCalendar);
// app.component("VDatePicker", DatePicker);

// number format
app.use(VueNumberFormat, {
  decimal: ".",
  separator: ",",
  precision: 3,
});

app.component("common-alert", CommonAlert);
app.component("common-popup", CommonPopup);

app.config.globalProperties.$formatNumber = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });
  return formatter.format(value);
};

// i18n setting

const i18n = createI18n({
  locale: "en",
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

Sentry.init({
  app,
  dsn: "https://5a5f99aa54c89f92a9e503758c576a1d@o4504468774715392.ingest.sentry.io/4506572876021760",
  environment: import.meta.env.MODE,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: import.meta.env.MODE !== 'dev',
  routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(
  VueGtag,{
    includes :   [
      { id: "G-LGPZRB0K1N" },
      { id: "GTM-56CGM8HW" }
    ],
  },
  router
);

app.use(i18n);

app.mount("#app");
