<script setup>

import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {computed, ref, onBeforeMount, watch} from "vue";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import dayjs from "dayjs";
import {navigateTo} from "@/utils/coffee.js";


const coffeeStore = useCoffeeStore()

const {cafe} = storeToRefs(coffeeStore)
const registerRequest = ref(null)
const disposableData = ref(null)
const router = useRouter()


onBeforeMount(async () => {
  // showAlert('업체 정보를 불러오는 중입니다.')
})


const statusTypes = ['pending', 'completed', 'failed', 'canceled', 'forceCanceled']
const statusType = ref(statusTypes[0])

const rotateStatusTypes = () => {
  statusType.value = statusTypes[(statusTypes.indexOf(statusType.value) + 1) % statusTypes.length]
}


const alertIsShown = ref(false)
const alertBody = ref('')

const showAlert = (body) => {
  alertBody.value = body
  alertIsShown.value = true
}

const closeAlert = () => {
  alertIsShown.value = false
  alertBody.value = ''
}

const disposeTime = computed(() => {
  if (cafe.value?.region?.dispose_time) {
    let now = dayjs()
    let hour = parseInt(cafe.value.region.dispose_time.split(":")[0])
    let min = parseInt(cafe.value.region.dispose_time.split(":")[1])
    if (min === 0) {
      return `${hour}시`
    } else {
      return `${hour}시 ${min}분`
    }
  } else {
    return null
  }
})
const collectDate = computed(() => {
  if (disposableData.value?.dispose_date) {
    return dayjs(disposableData.value?.dispose_date).format("YYYY년 M월 D일")
  } else {
    return null
  }
})

const menuAction = async (action_name) => {
  try {
    await navigateTo(action_name)
  } catch (e) {
    showAlert(e.toString())
  }
}
</script>

<template>
  <div class="w-full pt-[62px]">
<!--    <template v-if="cafe?.type === 'cafe'">-->
      <!--        <template v-if="'수거신청건 있음'">-->
      <!--          <div class="w-full">-->
      <!--            <div class="header-title mb-[24px]">-->
      <!--              <div class="header-title-main">-->
      <!--                {{ cafe.name }}-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div :class="['dispose-status', statusType]" @click="rotateStatusTypes">-->
      <!--              <template v-if="statusType==='pending'">-->
      <!--                수거 예정-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='completed'">-->
      <!--                수거 완료-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='failed'">-->
      <!--                수거 실패-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='canceled'">-->
      <!--                취소-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='forceCanceled'">-->
      <!--                강제 취소-->
      <!--              </template>-->
      <!--            </div>-->
      <!--            <div class="header-sub-text mb-[64px]">-->
      <!--              <template v-if="statusType==='pending'">-->
      <!--                2024년 4월 26일 오전 9시~오후 9시 사이 수거 예정입니다.-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='completed'">-->
      <!--                2024년 4월 26일 오후 6시 30분에 수거가 완료되었습니다.-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='failed'">-->
      <!--                2024년 4월 26일 오후 6시 30분에 수거기사가 방문하였으나 수거에 실패했습니다. 자세한 내용은 아래 상세 확인을 통해서 확인 가능합니다.-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='canceled'">-->
      <!--                2024년 4월 26일 오후 6시 30분 배출자의 요청으로 수거가 취소되었습니다.-->
      <!--              </template>-->
      <!--              <template v-else-if="statusType==='forceCanceled'">-->
      <!--                2024년 4월 26일 오후 6시 30분 수거가 강제 취소되었습니다. 자세한 내용은 아래 상세 확인을 통해서 확인 가능합니다.-->
      <!--              </template>-->
      <!--            </div>-->
      <!--            <div class="header-body">-->
      <!--              <button class="primary-btn primary-btn-blank" @click="navigateTo('coffee-request-register')">-->
      <!--                상세 확인-->
      <!--              </button>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <template v-else>-->
<!--      <div class="w-fll">-->
<!--        <div class="header-title mb-[66px]">-->
<!--          <div class="header-title-main">{{ cafe.name }}</div>-->
<!--        </div>-->
<!--        <div class="header-sub-text">-->
<!--          {{ disposeTime }} 전 신청하면 {{ collectDate }} 수거가 진행됩니다.-->
<!--        </div>-->
<!--        <div class="header-body">-->
<!--          <button class="primary-btn primary-btn-header" @click="menuAction('coffee-request-collection')">-->
<!--            수거 신청-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->
<!--    <template v-else-if="cafe?.type === null">-->
<!--      <div class="w-full">-->
<!--        <div class="header-title mb-10">-->
<!--          <div class="header-title-main">-->
<!--            업체 정보 등록 후-->
<!--            <div>무상 수거 신청을 시작하세요!</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="header-body">-->
<!--          <button class="primary-btn primary-btn-header" @click="navigateTo('coffee-request-register')">-->
<!--            배출 업체 등록-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->
<!--    <template v-else-if="cafe?.type === 'request'">-->
<!--      <template v-if="cafe.data.status === 'rq'">-->
<!--        <div class="w-full">-->
<!--          <div class="header-title mb-10">-->
<!--            <div class="header-title-main">-->
<!--              업체 등록 심사가 진행중입니다.-->
<!--            </div>-->
<!--            <div class="header-title-sub mt-2">-->
<!--              심사 완료 시 알림을 보내드릴게요!-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="header-body">-->
<!--            <router-link :to="{name: 'coffee-request-register'}" class="primary-btn primary-btn-blank">-->
<!--              등록 내용 확인-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-else-if="cafe.data.status === 'rj'"></template>-->
<!--    </template>-->


    <div class="header-menu">
      <div class="header-menu-item" @click="showAlert('업체 정보 변경이 필요할 경우 고객센터(1660-3114)로 문의하세요.')">
        정보 수정
      </div>
      <div class="header-menu-item" @click="showAlert('문의사항은 고객센터(1660-3114)로 문의하세요.')">
        서비스 문의
      </div>
    </div>
    <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="alertIsShown=false"/>
  </div>
</template>

<style scoped>

.header {
  border-bottom: 1px solid #F5F5F5;
}

.header-text {
  font-family: NanumSquare, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 133.333% */
  user-select: none;
  color: #111;
}

.header-title-main {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 133.333% */
}

.header-title-sub {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
}

.primary-btn-header {
  width: 100%;
  font-family: NanumSquare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
}

.primary-btn-blank {
  width: 100%;
  background-color: white;
  border: 1px solid #3182FF;
  color: #3182FF;
  font-family: NanumSquare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 28px; /* 155.556% */
}

.header-body {
  padding-bottom: 24px;
  border-bottom: 1px solid #EFEFEF;
}

.header-menu-item {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  padding: 20px 0 20px 0;
}

.header-sub-text {
  color: #555;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  min-height: 72px;
  margin-bottom: 24px;
}

</style>