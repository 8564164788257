<script setup>
import {computed} from "vue";

const props = defineProps({
  label: {type: String, required: true},
  count: {type: Number, required: false, default: 0},
})

const numbers = computed(() => {
  let arr = props.count.toString().split('')
  if (arr.length < 3) {
    return Array(3 - arr.length).fill('0').concat(arr)
  }
  return arr
})
</script>

<template>
  <div>
    <div class="cf-title-5 text-white mb-1">
      {{ props.label }}
    </div>
    <div class="counter-wrap">
      <div v-for="d in numbers" class="cf-head-5 cf-text-gray-900 digit">{{ d }}</div>
      <div class="cf-title-5 text-white">건</div>
    </div>
  </div>
</template>

<style scoped>
.counter-wrap {
  display: flex;
  align-items: center;
  justify-items: start;
  gap: 2px;
}

.digit {
  display: inline-flex;
  padding: 2px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--CF-White, #FFF);
}
</style>