<script setup>

import Map from "@/components/coffee/Map.vue";
import CheckTitle from "@/views/coffee/CheckTitle.vue";
import QNABlock from "@/components/coffee/QNABlock.vue";
import Exclamation from "@/components/Exclamation.vue";
import NumberTitle from "@/views/coffee/NumberTitle.vue";

const props = defineProps({
  cafe: {type: Object}
})
</script>

<template>
  <div class="mb-9">
    <!-- TODO 기획 확인 후 재수정-->
    <div class="">
      <div class="mb-5">
        <CheckTitle>
          <template #title>수거 신청은 하루 전 00시 ~ {{props.cafe.data.region.dispose_time.slice(0, 5)}} 사이 가능</template>
          <template #body></template>
        </CheckTitle>
      </div>
      <div>
        <CheckTitle>
          <template #title>수거는 {{props.cafe.data.region.week_days.label.join(', ')}}에 진행</template>
          <template #body>토요일, 일요일, 공휴일에는 수거를 진행하지 않습니다.</template>
        </CheckTitle>
      </div>
    </div>
  </div>
</template>

<style scoped>

.circle {
  display: flex;
  width: 18px;
  height: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: black;
}

</style>