import axios from "axios";
import { useLayoutStore } from "@/stores/layoutStore";
import { useUserStore } from "@/stores/userStore";
import * as Sentry from "@sentry/browser";

const { VITE_BACKEND_HOST } = import.meta.env;
const axiosServices = axios.create();
axiosServices.defaults.baseURL = VITE_BACKEND_HOST;

axiosServices.interceptors.request.use(
  function (config) {
    console.log("############################");
    console.log(`request url : ${config.url}`);
    console.log(`request method : ${config.method}`);
    if (config.data !== undefined) {
      console.log(`request body : `);
      console.log(config.data);
    }

    if (config.params !== undefined) {
      console.log(`request parameter : `);
      console.log(config.params);
    }

    console.log("############################");
    // 요청 전에 로딩 오버레이 띄우기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(true);

    // 요청 전에 헤더 토큰 설정
    const authStore = useUserStore();
    if (authStore.access) {
      const token = "";
      config.headers = config.headers || {};
      config.headers["Authorization"] = `Bearer ${authStore.access}`;
      config.headers["Content-Type"] = "application/json; charset=utf-8";
    }
    return config;
  },
  function (error) {
    // 에러 나면 로딩 끄기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(false);
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosServices.interceptors.response.use(
  async function (response) {
    console.log("############################");
    console.log(`response url : ${response.config.url}`);
    console.log(`response data : `);
    console.log(response.data);
    console.log("############################");
    // 응답 받으면 로딩 끄기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(false);

    return response;
  },
  async function (error) {
    // 응답 에러 시에도 로딩 끄기
    const { status, config, data } = error.response;

    if (status === 401) {
      const authStore = useUserStore();

      return authStore
        .refreshToken()
        .then((refreshResponse) => {
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${refreshResponse.access}`;

          console.log(
            `refreshResponse ${JSON.stringify(refreshResponse, null, 4)}`
          );

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
                const layoutStore = useLayoutStore();
                layoutStore.setLoading(false);
              })
              .catch((error) => {
                const layoutStore = useLayoutStore();
                layoutStore.setLoading(false);
                reject(error);
              });
          });
        })
        .catch((refreshError) => {
          console.log("get error when refresh");
          console.log(refreshError);
          const layoutStore = useLayoutStore();
          layoutStore.showErrorPopup(
            "세션 만료로 인해 로그인 화면으로 이동합니다."
          );

          layoutStore.setLoading(false);
          authStore.logout();
          return Promise.reject(refreshError);
        });
    } else {
      const layoutStore = useLayoutStore();
      layoutStore.setLoading(false);

      if (data.message) {
        layoutStore.showErrorPopup(data.message);
      } else {
        layoutStore.showErrorPopup(
          "알 수 없는 에러가 발생했습니다.\n다시 시도해주세요."
        );
      }

      Sentry.captureException(error);

      return Promise.reject(error);
    }
  }
);

export default axiosServices;
