<script setup>
import { ref, computed, onMounted } from "vue";
import { formatDate } from "@/utils/utils";
import "v-calendar/dist/style.css";
import router from "@/router";
import { useRequestStore } from "@/stores/requestStore";
import BlwSideNavgiation from "@/components/BlwSideNavigation.vue";

const requestStore = useRequestStore();

const selectedDisposeDate = ref(null);

const nextButtonValid = computed(() => {
  return selectedDisposeDate.value !== null;
});

const calendarMinDate = computed(() => {
  const today = new Date();

  const cutoffTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    15,
    30
  );

  if (today >= cutoffTime) {
    const nextDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );
    return nextDay;
  }

  return today;
});

const calendarMaxDate = computed(() => {
  const today = new Date();
  today.setDate(today.getDate() + 14);
  return today;
});

const calendarAttributes = ref([
  {
    key: "today",
    highlight: {
      color: "#1E64FF",
    },
    content: {
      style: {
        color: "white",
      },
    },
    dates: new Date(),
  },
]);

// function

function routeNextPage() {
  router.push("/blw-request02");
}

function onClickSelectDate(date) {
  if (date !== null) {
    if (!date.isDisabled) {
      selectedDisposeDate.value = formatDate(date.date);
    } else {
      selectedDisposeDate.value = null;
    }
  }
}

function onClickNextButton() {
  requestStore.setDisposeDate(selectedDisposeDate.value);
  routeNextPage();
}

function setPrevData() {
  if (requestStore.disposeDate !== null) {
    selectedDisposeDate.value = requestStore.disposeDate;
  }
}

onMounted(() => {
  setPrevData();
});
</script>

<template>
  <div id="pc_Service_01">
    <!-- switch 버튼 서브 헤더 -->
    <!-- <div class="service_head bg_white">
      <div
        class="service_head_wrap flex flex_row justify_space_between align_center"
      >
        <div class="service_head_left flex justify_start align_center">
          <label class="service_switch">
            <span class="first text_18 blue_grey semi_bold left"
              >대형 생활 폐기물</span
            >
            <input role="switch" type="checkbox" />
            <span class="text_18 blue_grey_light semi_bold left"
              >공사장 생활 폐기물</span
            >
          </label>
        </div>
        <div class="service_head_right flex justify_end align_center">
          <button
            class="service_button text_18 flex justify_center align_center"
          >
            방문 수거
          </button>
          <button
            class="service_button active text_18 flex justify_center align_center"
          >
            배출 신고
          </button>
        </div>
      </div>
    </div> -->

    <!-- 본문 -->
    <div class="page_container flex flex_col justify_start align_center">
      <div class="page_wrap">
        <!-- flex flex_col justify_start align_end -->
        <div class="content_layout flex flex_row justify_end align_start">
          <!-- 좌측 레이어 -->
          <div class="left_wrap">
            <p class="subtitle_7 color_secondary left page_sub_title">
              대형 생활 폐기물
            </p>
            <p class="head_2 left page_title">배출신고</p>
            <!-- step -->
            <div class="tag_wrap flex flex_row justify_start align_center">
              <span class="tag active body_3 flex justify_center align_center"
                >배출일 선택</span
              >
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">"
              />
              <span class="tag body_3 flex justify_center align_center"
                >배출정보 입력</span
              >
              <img
                class="tag_arrow_right"
                src="@/assets/icon/ic_arrow_right.svg"
                alt=">"
              />
              <span class="tag body_3 flex justify_center align_center"
                >배출품목 선택</span
              >
            </div>

            <p class="subtitle_5 color_secondary left mb-10">
              폐기물을 배출하실 날짜를 선택해 주세요.
            </p>
            <v-calendar
              class="mt-4 mb-4"
              :masks="{ title: 'YYYY년 MM월' }"
              :min-date="calendarMinDate"
              :max-date="calendarMaxDate"
              :attributes="calendarAttributes"
              expanded
              borderless
              mode="date"
              @dayclick="onClickSelectDate"
              nav-visibility="click"
            >
              <template #header-title="{ title }">
                <div class="body_1 color_secondary">
                  {{ title }}
                </div>
              </template>
            </v-calendar>

            <ul class="help_text_wrap body_3 g300">
              <li>
                수거 완료까지는 배출일로부터 평균 2영업일이 소요되며, 상황에
                따라 지연될 수 있어요.
              </li>
              <li>
                공동주택(아파트)의 경우는 지정된 수거요일에만 수거하고 있어요.
              </li>
            </ul>

            <div class="flex flex_col justify_center align_center">
              <button
                id="next_button"
                class="next_button label_1"
                @click="onClickNextButton"
                :disabled="!nextButtonValid"
              >
                다음
              </button>
              <!-- 다음 버튼 active일 경우 -->
              <!-- <button id="next_button" class="full_button bg_dark_black white semi_bold">다음</button> -->
            </div>
          </div>
          <!-- 우측 레이어 -->
          <blw-side-navgiation
            :disposeDate="selectedDisposeDate"
          ></blw-side-navgiation>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
::v-deep .vc-header {
  margin-bottom: 20px;
}

.left_wrap {
  max-width: 480px !important;
}
</style>
