import axiosServices from "@/utils/axios/axios.js";
import axios from "axios";
import {extractNumbers} from "@/utils/utils.js";
const apiUrl = import.meta.env.VITE_BACKEND_HOST

// protected file을 등록하기 위한 api들
const requestProtectedFilePrepare = async (filename) => {
    // protected file을 등록하기 위해 signed url을 요청한다
    let config = {
        method: 'get',
        url: `${apiUrl}common/file/protected/?filename=${filename}`
    };
    const response = await axiosServices(config)
    return response.data
}

const completeProtectedFile = async (prepare) => {
    // signed url에 업로드 성공 후 서버에 파일을 등록한다.
    // requestProtectedFilePrepare에서 받은 응답을 그대로 prepare에 넣어준다.
    let config = {
        method: 'post',
        url: `${apiUrl}common/file/protected/`,
        data: JSON.stringify(prepare),
        headers: {'Content-Type': 'application/json'}
    };
    const response = await axiosServices(config)
    return response.data
}

const uploadToS3 = async (prepare, fileObject) => {
    // S3에 파일을 업로드한다.
    const response = await axios.put(prepare.upload_url, fileObject, {headers: {"Content-Type": fileObject.type}})
    if (response.status !== 200) {
        throw new Error('Failed to upload to S3')
    }
    return prepare
}

const uploadProtectedFile = async (fileObject) => {
    const prepare = await requestProtectedFilePrepare(fileObject.name)
    await uploadToS3(prepare, fileObject)
    return await completeProtectedFile(prepare)
}

// 휴대전화번호 간편인증

const requestSimpleAuth = async (cellphone) => {
    // 인증 요청
    let config = {
        method: 'put',
        url: `${apiUrl}common/cellphoneauth/simple/`,
        data: JSON.stringify({'cellphone': extractNumbers(cellphone)}),
        headers: {'Content-Type': 'application/json'}
    };
    const response = await axiosServices(config)
    return response.data
}

const checkSimpleAuthNo = async (requestId, authNo) => {
    // 인증 요청
    let config = {
        method: 'post',
        url: `${apiUrl}common/cellphoneauth/simple/`,
        data: JSON.stringify({
            id: requestId,
            auth_no: authNo
        }),
        headers: {'Content-Type': 'application/json'},
        validateStatus: function (status) {
            return status === 200 || status === 401
        }
    };
    const response = await axiosServices(config)
    return {success: response.status === 200, data: response.data}
}

export {
    requestProtectedFilePrepare, completeProtectedFile, uploadToS3, uploadProtectedFile,
    requestSimpleAuth, checkSimpleAuthNo
}