<script setup>
import { ref, computed } from "vue";
import warningIcon from "@/assets/icon/ic_warning.svg";
import { bankList } from "@/type/paymentBankList";
import { postUserServiceCancelMb } from "@/api/request";
import { sendErrorLogToSentry } from "@/utils/utils";

const isShow = defineModel("isShow", {
  type: Boolean,
  required: true,
});

const cancelItem = defineModel("cancelItem", {
  type: Object,
  required: true,
  default: {}, // null 처리시 오류가 발생해 {} 로 null 처리 하게끔 수정
});

const accountHolderField = ref("");
const accountNumberField = ref("");
const refundBankField = ref(null);
const refundBankList = ref(bankList);

const emit = defineEmits(["closeAlert", "apiResultAction"]);

const okayButtonValdiate = computed(() => {
  return (
    accountHolderField.value !== "" &&
    accountNumberField.value !== "" &&
    refundBankField.value !== null
  );
});

function onClickCloseButton() {
  clearField();
  emit("closeAlert");
}

function onClickOkayButton() {
  if (cancelItem.value != {}) {
    requestUserServiceCancel(
      cancelItem.value.id,
      cancelItem.value.service_type
    );
  } else {
    alert("알 수 없는 오류가 발생했습니다.");
    sendErrorLogToSentry(e);
  }
}

function clearField() {
  accountHolderField.value = "";
  accountNumberField.value = "";
  refundBankField.value = null;
}

//  api request
async function requestUserServiceCancel(id, service_type) {
  try {
    const refundBank = refundBankField.value;
    const accountHolder = accountHolderField.value;
    const accountNumber = accountNumberField.value;

    const response = await postUserServiceCancelMb(
      id,
      service_type,
      refundBank,
      accountNumber,
      accountHolder
    );

    emit("closeAlert");
    emit("apiResultAction", true);
  } catch (e) {
    sendErrorLogToSentry(e);
    emit("closeAlert");
    emit("apiResultAction", false);
  }
}
</script>

<template>
  <v-dialog
    v-model="isShow"
    @click:outside="onClickCloseButton"
    class="alert_wrap"
  >
    <v-card class="alert_container pa-0 ma-0" elevation="0">
      <v-container class="alert_body">
        <div class="alert_head">
          <button @click="onClickCloseButton" class="close_button">
            <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
          </button>
        </div>
        <div class="alert_content flex flex_col justify_center align_center">
          <img :src="warningIcon" />
          <span class="subtitle_5 color_secondary center">
            환불 받으실 계좌 정보를 <br />
            입력해주세요.
          </span>
        </div>
        <div class="alert_content2">
          <!-- 예끔주 -->
          <div class="input_row">
            <div class="input_wrap2 flex flex_row justify_start align_end">
              <div class="grow flex flex_col justify_start align_stretch">
                <label for="accountHolder" class="label_2 mb-1">예금주</label>
                <input
                  class="input input-field body_2"
                  v-model="accountHolderField"
                  name="accountHolder"
                  id="accountHolder"
                  placeholder="예금주명 입력"
                />
              </div>
            </div>
          </div>
          <!-- 은행명 -->
          <div class="input_row">
            <div class="input_wrap2 flex flex_row justify_start align_end">
              <div class="grow flex flex_col justify_start align_stretch">
                <label for="phoneNumber" class="label_2 mb-1">은행명</label>
                <v-select
                  class="no-border-select"
                  hide-details="true"
                  placeholder="은행명 선택"
                  v-model="refundBankField"
                  :items="refundBankList"
                  item-value="id"
                  item-title="name"
                ></v-select>
              </div>
            </div>
          </div>
          <!-- 계쫘번호 -->
          <div class="input_row">
            <div class="input_wrap2 flex flex_row justify_start align_end">
              <div class="grow flex flex_col justify_start align_stretch">
                <label for="accountNumber" class="label_2 mb-1">계좌번호</label>
                <input
                  class="input input-field body_2"
                  v-model="accountNumberField"
                  name="accountNumber"
                  id="accountNumber"
                  placeholder="계좌번호(-없이 입력)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="info_wrap flex flex_col justify_center align_start">
          <div class="info_title flex flex_row justify_center align_center">
            <img :src="warningIcon" />
            <span class="caption_1 color_caption">주의사항</span>
          </div>
          <ul class="body_3 color_caption">
            <li>환불은 회원님 명의의 계좌로만 신청이 가능합니다.</li>
            <li>신청일로부터 7영업일 이내에 입금이 완료됩니다.</li>
            <li>
              환불 신청 완료 후 입력하신 계좌 정보는 수정이 불가능 합니다.
            </li>
          </ul>
        </div>
        <button
          @click="onClickOkayButton"
          class="okay_button subtitle_7 flex justify_center align_center"
          :disabled="!okayButtonValdiate"
        >
          확인
        </button>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.alert_head {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  background: #fff;
  z-index: 200;
}

.alert_head .close_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.alert_content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.alert_content span {
  word-wrap: break-word;
  word-break: break-all;
}

.alert_content img {
  width: 42px;
  height: 42px;
}

.alert_content span {
  margin-top: 10px;
}

.alert_content2 {
  margin-bottom: 30px;
}

.okay_button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: auto;
  color: white;
  background-color: #1e64ff;
}

.okay_button:disabled {
  color: #7a7a7a;
  background-color: #ededed;
}

/* input */
.input_row {
  width: 100%;
  margin-bottom: 20px;
}

/* 주의사항 wrap */
.info_wrap {
  margin-bottom: 30px;
}
.info_wrap .info_title {
  gap: 4px;
  margin-bottom: 6px;
}
.info_wrap .info_title img {
  width: 12px;
  height: 12px;
}

.info_wrap ul {
  padding-left: 20px;
}

/* v-select custom 스타일 */
.no-border-select :deep(.v-field__outline) {
  color: transparent;
  border-radius: 4px;
}

.no-border-select :deep(.v-field--appended) {
  border: 1px solid #c1c8d4;
  border-radius: 4px;
}

.no-border-select :deep(.v-field__field) {
  height: 40px;
}

.no-border-select :deep(.v-field__input) {
  padding: 10px 12px;
  color: black;
  font-size: 14px;
  min-height: 40px;
}

.no-border-select :deep(.v-field__input input::placeholder) {
  color: #c1c8d4;
  opacity: 1;
}

.no-border-select :deep(.v-field--variant-filled .v-field__overlay) {
  background-color: white;
}
</style>
