<script setup>
const props = defineProps({
  title: String,
  description: String,
});

const isShow = defineModel('isShow', {
  type: Boolean,
  required: true,
});

const emit = defineEmits(["closeAlert", "okayAction"]);

function onClickCloseButton() {
  emit("closeAlert");
}

function onClickOkayButton() {
  emit("okayAction");
}
</script>

<template>
  <v-dialog v-model="isShow" class="alert_wrap">
    <v-card class="alert_container pa-0 ma-0" elevation="0">
      <v-container class="alert_body">
        <div class="alert_head">
          <button @click="onClickCloseButton" class="close_button">
            <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
          </button>
        </div>
        <div class="alert_content flex flex_col justify_center align_center">
          <img src="@/assets/icon/ic_warning.svg" />
          <span
            v-show="props.title !== null"
            class="subtitle_5 color_secondary center"
          >
            {{ props.title }}
          </span>
          <span
            v-show="props.description !== null"
            class="body_2 color_caption center"
          >
            {{ props.description }}
          </span>
        </div>
        <button
          @click="onClickOkayButton"
          class="okay_button bg_primary g0 subtitle_7"
        >
          확인
        </button>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.alert_body {
  padding: 20px;
}
.alert_head {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  background: #fff;
  z-index: 200;
}

.alert_head .close_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.alert_content {
  margin-top: 10px;
  margin-bottom: 30px;
}

.alert_content img {
  width: 42px;
  height: 42px;
}

.alert_content span {
  margin-top: 10px;
}

.okay_button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
</style>
