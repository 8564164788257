<script setup>
import { ref, onMounted } from "vue";
import {
  getProductCategoryList,
  getProductStandardList,
  getProductCategoryListSearch,
} from "@/api/request";
import { useRequestStore } from "@/stores/requestStore";
import { useLayoutStore } from "@/stores/layoutStore";
import { watch } from "vue";
import { sendErrorLogToSentry } from "@/utils/utils";

const requestStore = useRequestStore();
const layoutStore = useLayoutStore();

const mainCategoryList = ref([]);
const selectedMainCategory = ref(null);

const totalSubCategoryList = ref([]);
const subCategoryList = ref([]);
const selectedSubCategory = ref(null);

const itemKindList = ref([]);
const selectedItemKind = ref(null);

const itemSizeList = ref([]);

const totalItemCategoryList = ref([]);
const selectedItemSize = ref(null);
const selectedItemSizeCount = ref(1);

const isOpenPanel = ref([]);
const isOpenSizeSheet = ref(false);
const isOpenItemList = ref(false);

const selectItemList = ref([]);
const selectItemListCount = ref(0);

const searchText = ref("");
const searchItemList = ref([]);
const isShowSearchList = ref(false);

watch(isOpenSizeSheet, (n, o) => {
  if (!n) {
    clickCloseSheet();
  }
});

watch(
  selectItemList,
  (n, o) => {
    let count = 0;
    for (let i of selectItemList.value) {
      count += i.count;
    }
    selectItemListCount.value = count;
  },
  { deep: true }
);

function clickBackBtn() {
  history.back();
}

function clickMainCategory(item) {
  if (selectedMainCategory.value != item) {
    selectedMainCategory.value = item;
    selectedSubCategory.value = null;
    itemKindList.value = [];
    isOpenPanel.value = [];

    subCategoryList.value = totalSubCategoryList.value.filter((e) => {
      return e.parentId === item.id;
    });
  }
}

function clickSubCategory(item) {
  if (selectedSubCategory.value != item) {
    selectedSubCategory.value = item;

    itemKindList.value = totalItemCategoryList.value.filter((e) => {
      return e.parentId == item.id;
    });
  }
}

function clickItemKind(item) {
  isOpenSizeSheet.value = true;
  selectedItemKind.value = item;
  requestGetProductStandardList(item.id, item.name);
}

function clickItemSize(item) {
  if (selectedItemSize.value != item) {
    selectedItemSize.value = item;
    selectedItemSizeCount.value = 1;
  }
}

function clickIncreaseItemCount() {
  selectedItemSizeCount.value += 1;
}

function clickDecreaseItemCount() {
  if (selectedItemSizeCount.value == 1) {
    selectedItemSize.value = null;
  } else {
    selectedItemSizeCount.value -= 1;
  }
}

function clickIncreaseSelectListItemCount(idx) {
  let newList = selectItemList.value;
  newList[idx].count += 1;

  selectItemList.value = newList;
}

function clickDecreaseSelectListItemCount(idx) {
  let newList = selectItemList.value;
  let count = newList[idx].count;

  if (count == 1) {
    newList.splice(idx, 1);
  } else {
    newList[idx].count -= 1;
  }

  selectItemList.value = newList;
}

function clickRemoveItem(idx) {
  let newList = selectItemList.value;
  newList.splice(idx, 1);

  selectItemList.value = newList;
}

function clickAddItem() {
  let newList = selectItemList.value;
  let newItem = selectedItemSize.value;
  let isModify = false;

  for (const i of newList) {
    if (i.item.id == newItem.id) {
      i.count += selectedItemSizeCount.value;
      isModify = true;
    }
  }

  if (!isModify) {
    newList.push({
      item: selectedItemSize.value,
      parent: selectedItemKind.value,
      count: selectedItemSizeCount.value,
    });
  }

  selectItemList.value = newList;

  clickCloseSheet();
}

function clickCloseSheet() {
  isOpenSizeSheet.value = false;
  itemSizeList.value = [];
  selectedItemKind.value = null;
  selectedItemSize.value = null;
  selectedItemSizeCount.value = 1;
}

function clickOpenItemSheetBtn() {
  isOpenItemList.value = !isOpenItemList.value;
}

function clickCompleteBtn() {
  if (selectItemList.value.length > 0) {
    let list = selectItemList.value.map((v) => {
      return {
        categoryName: v.parent.name,
        id: v.item.id,
        name: v.item.name,
        price: v.item.price,
        quantity: v.count,
        isSelected: false,
      };
    });
    requestStore.addItemList(list);
    history.back();
  }
}

function enterSearchItem() {
  const text = searchText.value;
  if (text != "" && text != null) {
    requestGetProductCategoryListSearch(text);
  } else {
    layoutStore.showErrorPopup("공백은 검색할 수 없습니다.");
  }
}

function focusSearchInput() {
  isShowSearchList.value = searchItemList.value.length > 0;
}

function unfocusSearchInput() {
  setTimeout(() => {
    isShowSearchList.value = false;
  }, 200);
}

// api request
async function requestGetProductCategoryList() {
  const govId = requestStore.govId;

  try {
    const response = await getProductCategoryList(govId);

    // 이거는 전체 데이터에 대한 정보임으로 해당 api에서만 초기화 처리해야함
    totalSubCategoryList.value = [];
    totalItemCategoryList.value = [];

    response.category_list.forEach((element) => {
      if (element.level === 1) {
        mainCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      } else if (element.level === 2) {
        totalSubCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      } else if (element.level === 3) {
        totalItemCategoryList.value.push({
          id: element.id,
          name: element.name,
          parentId: element.parent_id,
          level: element.level,
        });
      }
    });

    clickMainCategory(mainCategoryList.value[0]);
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestGetProductStandardList(categoryId, categoryName) {
  const govId = requestStore.govId;
  itemSizeList.value = [];

  try {
    const response = await getProductStandardList(govId, categoryId);

    response.product_standard_list.forEach((element) => {
      itemSizeList.value.push({
        id: element.id,
        name: element.name,
        categoryName: categoryName,
        price: element.price,
        quantity: 1,
        isSelected: false,
      });
    });

    isOpenSizeSheet.value = true;
  } catch (error) {
    sendErrorLogToSentry(error);
  }
}

async function requestGetProductCategoryListSearch(name) {
  const govId = requestStore.govId;
  try {
    const response = await getProductCategoryListSearch(govId, name);

    const list = response.category_list;

    const searchItemFilter = list
      .filter((item) => item.level === 3)
      .map((item) => findItemPath(item, list));

    console.log(searchItemFilter);

    searchItemList.value = searchItemFilter;
    isShowSearchList.value = true;
  } catch (error) {
    sendErrorLogToSentry(error);
    console.log("somethingis Error,  ", error);
  }
}

function findItemPath(item, dataList) {
  let path = [item.name];
  let itemList = [item];

  while (item.parent_id !== null) {
    item = dataList.find((parentItem) => parentItem.id === item.parent_id);

    if (item) {
      path.unshift(item.name);
      itemList.unshift(item);
    }
  }

  return {
    itemPath: path.join(" > "),
    itemList: itemList,
  };
}

// life cycle
onMounted(() => {
  requestGetProductCategoryList();
});
</script>

<template>
  <div id="blw-search-item">
    <div class="page_container flex flex_col justify_center align_center">
      <div class="flex flex_col justify_start align_start" style="width: 100%">
        <!-- 뒤로가기 버튼-->
        <button class="back_btn" @click="clickBackBtn">
          <img src="../../assets/icon/ic_arrow_left_gray.svg" alt="뒤로가기" />
        </button>

        <!-- 본문 박스 -->
        <div class="search_box">
          <p class="subtitle_5" style="margin-bottom: 20px">품목 선택</p>
          <!-- 검색 바-->
          <div
            class="search_input input_wrap2 flex flex_row justify_start align_center"
          >
            <input
              class="input grow"
              type="text"
              name="searchValue"
              id="searchValue"
              v-model="searchText"
              @keyup.enter="enterSearchItem"
              @blur="unfocusSearchInput"
              @focus="focusSearchInput"
              placeholder="배출하실 품목을 입력해 주세요."
            />
            <button
              id="search"
              class="search_btn bg_primary flex justify_center align_center"
              @click="enterSearchItem"
            >
              <img src="../../assets/icon/ic_search.png" alt="검색버튼" />
            </button>
            <div
              class="autocomplete_list flex flex_col"
              v-if="isShowSearchList"
            >
              <span
                v-for="(item, idx) in searchItemList"
                class="body_2 color_secondary"
                style="padding: 10px"
              >
                <button @click="clickItemKind(item.itemList[2])">
                  {{ item.itemPath }}
                </button>
              </span>
            </div>
          </div>

          <!-- 메인 카테고리 -->
          <div
            class="main_category_box flex flex_row justify_start align_center"
          >
            <div
              class="main_category"
              v-for="(item, idx) in mainCategoryList"
              :class="[{ selected: selectedMainCategory.id === item.id }]"
              @click="clickMainCategory(item)"
            >
              <span
                class="main_category_text"
                :class="[{ selected: selectedMainCategory.id === item.id }]"
              >
                {{ item.name }}
              </span>
            </div>
          </div>
          <!-- 서브 카테고리 -->
          <div class="sub_category_box">
            <v-expansion-panels v-model="isOpenPanel" variant="accordion">
              <v-expansion-panel
                v-for="(item, idx) in subCategoryList"
                elevation="0"
                @click="clickSubCategory(item)"
              >
                <v-expansion-panel-title>
                  {{ item.name }}
                </v-expansion-panel-title>
                <!-- 종류 -->
                <v-expansion-panel-text
                  v-for="(item, idx) in itemKindList"
                  @click="clickItemKind(item)"
                >
                  {{ item.name }}
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <!-- 규격 바텀시트 -->
          <v-bottom-sheet v-model="isOpenSizeSheet">
            <div
              class="size_bottom_sheet flex flex_col justify_start align_start"
            >
              <div class="header_container">
                <button @click="clickCloseSheet" class="close_btn">
                  <img src="@/assets/icon/ic_x_blue_grey.svg" alt="닫기" />
                </button>
                <p class="subtitle_5" style="margin: 0px 16px 10px 16px">
                  규격 및 종류
                </p>
              </div>
              <!-- 사이즈 리스트 -->
              <div class="size_list_box flex flex_col">
                <div
                  class="size_list_item flex"
                  v-for="(item, idx) in itemSizeList"
                  :class="[
                    {
                      selected:
                        selectedItemSize != null &&
                        selectedItemSize.id === item.id,
                    },
                  ]"
                  @click="clickItemSize(item)"
                >
                  <span class="body_2">{{ item.name }}</span>
                </div>
              </div>
              <!-- 선택된 아이템 -->
              <div class="item_select_box flex flex_col">
                <div
                  class="flex flex_row justify_center align_center"
                  style="margin-bottom: 10px"
                >
                  <div class="item_type_box flex flex_col">
                    <span class="subtitle_7" style="margin-bottom: 4px">{{
                      selectedItemKind?.name ?? ""
                    }}</span>
                    <span class="body_2 color_caption">
                      {{
                        selectedItemSize == null
                          ? "규격 및 종류 선택"
                          : selectedItemSize.name
                      }}</span
                    >
                  </div>
                  <div
                    v-if="selectedItemSize != null"
                    class="item_count_box flex flex_row justify_space_between align_center"
                  >
                    <button
                      class="count_btn color_caption"
                      @click="clickDecreaseItemCount"
                    >
                      -
                    </button>
                    <span class="caption_1">{{ selectedItemSizeCount }}</span>
                    <button
                      class="count_btn color_caption"
                      @click="clickIncreaseItemCount"
                    >
                      +
                    </button>
                  </div>
                </div>
                <button
                  class="add_button subtitle_7"
                  @click="clickAddItem"
                  :disabled="selectedItemSize == null"
                >
                  추가
                </button>
              </div>
            </div>
          </v-bottom-sheet>
        </div>
        <div class="selected_item_box" v-if="selectItemList.length != 0">
          <button class="list_up_down_btn" @click="clickOpenItemSheetBtn">
            <img
              v-if="!isOpenItemList"
              style="width: 16px; height: 8px; margin-bottom: 2px"
              src="../../assets/icon/ic_arrow_up_white.svg"
            />
            <img
              v-else
              style="width: 16px; height: 8px; margin-bottom: 2px"
              src="../../assets/icon/ic_arrow_down_white.svg"
            />
          </button>
          <div class="select_list_box">
            <div
              v-if="isOpenItemList"
              v-for="(item, idx) in selectItemList"
              class="select_list_item flex flex_row justify_center align_center"
            >
              <button @click="clickRemoveItem(idx)">
                <img
                  class="select_list_item_remove_btn"
                  src="../../assets/icon/ic_delete.svg"
                />
              </button>
              <div
                class="flex flex_col justify_start align_start"
                style="width: 100%"
              >
                <span class="subtitle_7 color_secondary ellipsis">{{
                  item.parent.name
                }}</span>
                <span class="body_2 color_caption ellipsis">{{
                  item.item.name
                }}</span>
              </div>
              <div
                class="item_count_box flex flex_row justify_space_between align_center"
                style="margin: 0px"
              >
                <button
                  class="count_btn color_caption"
                  @click="clickDecreaseSelectListItemCount(idx)"
                >
                  -
                </button>
                <span class="caption_1">{{ item.count }}</span>
                <button
                  class="count_btn color_caption"
                  @click="clickIncreaseSelectListItemCount(idx)"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <button
            @click="clickCompleteBtn"
            class="complete_btn flex flex_row justify_center align_center"
          >
            <div class="select_item_count">{{ selectItemListCount }}</div>
            <span class="subtitle_7">품목 선택 완료하기</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.back_btn {
  width: 26px;
  height: 26px;
  margin: 15px 0px 35px 12px;
}
.search_box {
  width: 100%;
  padding: 0px 16px;
}
.search_input {
  max-width: 100%;
  margin-bottom: 20px;
  gap: 0px;
}
.input_wrap2 input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search_btn {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.autocomplete_list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: white;
  border: 1px solid #c1c8d4;
  border-radius: 4px;
  margin-top: 2px;
  padding: 2px;
  max-height: 300px;
  overflow: scroll;
}

.main_category_box {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 10px;
}

.main_category_box::-webkit-scrollbar {
  display: none;
}

.main_category {
  white-space: nowrap;
  padding: 6px 20px;
  margin: 0px 4px;
}

.main_category.selected {
  background-color: rgba(30, 100, 255, 0.08);
  color: #132b4f;
  font-weight: 700;
  border-radius: 17.5px;
  border: #1e64ff 1px solid;
}

.main_category_text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.main_category_text.selected {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
}

.sub_category_box {
  height: auto;
  margin-bottom: 10px;
}

.v-expansion-panel-title {
  padding: 16px;
}

/* arcodion 아이템 간 border */
:deep(.v-expansion-panel:not(:first-child)::after) {
  border-top-style: none;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 12px 28px;
  height: 44px;
}

:deep(.v-expansion-panel__shadow) {
  box-shadow: none;
}

:deep(.v-expansion-panel-title:hover > .v-expansion-panel-title__overlay) {
  opacity: 0;
}

:deep(
    .v-expansion-panel-title--active:hover > .v-expansion-panel-title__overlay
  ) {
  opacity: 0;
  color: #1e64ff;
  font-weight: 600;
}

:deep(.v-expansion-panel-title--active > .v-expansion-panel-title__overlay) {
  opacity: 0;
}

:deep(
    .v-expansion-panel--active
      > .v-expansion-panel-title:not(.v-expansion-panel-title--static)
  ) {
  color: #1e64ff;
  font-weight: 600;
}

:deep(.v-expansion-panel-title .mdi) {
  color: #7c8fac;
}

.selected_item_box {
  z-index: 2;
  width: 100%;
  padding: 20px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 10px #eee;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
}

.list_up_down_btn {
  z-index: 3;
  position: absolute;
  top: -12px;
  left: calc(50% - 20px);
  width: 40px;
  height: 24px;
  background-color: #1e64ff;
  border-radius: 12px;
}

.select_list_box {
  max-height: 400px;
  width: 100%;
  overflow: scroll;
}

.select_list_item {
  margin: 10px 16px;
  height: 46px;
  width: calc(100% - 32px);
  background-color: white;
}

.select_list_item_remove_btn {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.select_item_count {
  width: 36px;
  height: 18px;
  background-color: white;
  color: #1e64ff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  border-radius: 9px;
  margin-right: 6px;
}

.complete_btn {
  width: 100%;
  height: 40px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}
.complete_btn:disabled {
  width: 100%;
  height: 40px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}

/* 바텀 시트 */
.size_bottom_sheet {
  width: 100%;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 465px;
  overflow: scroll;
}

/*  바텀 시트 헤더 */
.size_bottom_sheet .header_container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.size_bottom_sheet .header_container .scrolled-header {
  box-shadow: 10px 0px 6px rgba(124, 143, 172, 0.12);
}

.close_btn {
  width: 20px;
  height: 20px;
  align-self: flex-end;
  margin: 20px 20px 10px 0px;
}

.size_list_box {
  max-height: 150px;
  overflow: scroll;
  margin: 0px 16px;
  margin-top: 10px;
  width: calc(100% - 32px);
}

.size_list_item {
  padding: 16px;
}

.size_list_item.selected {
  background-color: rgba(30, 100, 255, 0.08);
  border: #1e64ff 1px solid;
  color: #1e64ff;
  border-radius: 4px;
}

.item_select_box {
  padding: 10px 16px 20px 16px;
  width: 100%;
  box-shadow: 10px 0px 6px rgba(124, 143, 172, 0.12);
}

.item_count_box {
  width: 100px;
  height: 30px;
  border: #e7ecf4 1px solid;
  border-radius: 4px;
  margin-right: 16px;
  margin-top: 8px;
}

.count_btn {
  padding: 0px 8px 2px 8px;
}

.item_type_box {
  width: 100%;
  margin-left: 16px;
}

.add_button {
  width: 100%;
  height: 40px;
  background-color: #1e64ff;
  color: white;
  border-radius: 4px;
}

.add_button:disabled {
  width: 100%;
  height: 40px;
  background-color: #ededed;
  color: #7a7a7a;
  border-radius: 4px;
}
</style>
