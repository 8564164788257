import axios from "axios";
import { useLayoutStore } from "@/stores/layoutStore";

const { VITE_QUICK_QUOTE_HOST } = import.meta.env
const axiosServices = axios.create()
axiosServices.defaults.baseURL = VITE_QUICK_QUOTE_HOST

axiosServices.interceptors.request.use(
  function (config) {
    console.log("############################");
    console.log(`request url : ${VITE_QUICK_QUOTE_HOST}`);
    console.log(`request method : ${config.method}`);
    if (config.data !== undefined) {
      console.log(`request body : `);
      console.log(config.data);
    }

    if (config.params !== undefined) {
      console.log(`request parameter : `);
      console.log(config.params);
    }

    console.log("############################");
    // 요청 전에 로딩 오버레이 띄우기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(true);

    return config;
  },
  function (error) {
    // 에러 나면 로딩 끄기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(false);
    return Promise.reject(error);
  }
)

// 응답 인터셉터
axiosServices.interceptors.response.use(
  async function (response) {
    console.log("############################");
    console.log(`response url : ${VITE_QUICK_QUOTE_HOST}`);
    console.log(`response data : ${response.data}`);
    console.log("############################");
    // 응답 받으면 로딩 끄기
    const layoutStore = useLayoutStore();
    layoutStore.setLoading(false);

    return response;
  },
  async function (error) {
    // 응답 에러 시에도 로딩 끄기
    const { data } = error.response;
    const layoutStore = useLayoutStore();
      layoutStore.setLoading(false);

      if (data.message) {
        alert(data.message);
      } else {
        alert("알 수 없는 에러가 발생했습니다.\n다시 시도해주세요.");
      }

      return Promise.reject(error);
  }
);

export default axiosServices;