<script setup>
import {getCurrentInstance, ref, watch, onMounted, onUnmounted, computed} from 'vue';
import {useRoute} from "vue-router";
import {useUserStore} from "@/stores/userStore.js";
import {disposeStatus, listDispose} from "@/api/coffee.js";
import CoffeeAlert from "@/components/coffee/CoffeeAlert.vue";
import {tr} from "vuetify/locale";
import {useCoffeeStore} from "@/stores/coffeeStore.js";
import {storeToRefs} from "pinia";
import {serverDatetimeParser} from "../../utils/coffee.js";

const userStore = useUserStore();
const route = useRoute()
const {proxy} = getCurrentInstance();
const coffeeStore = useCoffeeStore()

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
const showData = ref(true)
const tempData = ref([
  {
    "id": 100,
    "serial": "ZGU-20230526-6453",
    "status": "cp",
    "registered_at": "2023년 05월 26일 13시 28분"
  },
  {
    "id": 99,
    "serial": "ZGU-20230326-7490",
    "status": "rj",
    "registered_at": "2023년 03월 26일 08시 12분"
  },
  {
    "id": 98,
    "serial": "ZGU-20221226-9474",
    "status": "rj",
    "registered_at": "2022년 12월 26일 15시 09분"
  },
  {
    "id": 97,
    "serial": "ZGU-20221221-9149",
    "status": "rq",
    "registered_at": "2022년 12월 21일 09시 58분"
  },
  {
    "id": 96,
    "serial": "ZGU-20220428-0405",
    "status": "cc",
    "registered_at": "2022년 04월 28일 15시 20분"
  },
  {
    "id": 95,
    "serial": "ZGU-20211122-9507",
    "status": "cp",
    "registered_at": "2021년 11월 22일 06시 54분"
  },
  {
    "id": 94,
    "serial": "ZGU-20211102-8939",
    "status": "cc",
    "registered_at": "2021년 11월 02일 09시 28분"
  },
  {
    "id": 93,
    "serial": "ZGU-20201121-8362",
    "status": "rq",
    "registered_at": "2020년 11월 21일 22시 51분"
  },
  {
    "id": 92,
    "serial": "ZGU-20200722-6497",
    "status": "cp",
    "registered_at": "2020년 07월 22일 21시 42분"
  },
  {
    "id": 91,
    "serial": "ZGU-20191127-3981",
    "status": "rq",
    "registered_at": "2019년 11월 27일 13시 11분"
  },
  {
    "id": 90,
    "serial": "ZGU-20190629-8420",
    "status": "rj",
    "registered_at": "2019년 06월 29일 15시 52분"
  },
  {
    "id": 89,
    "serial": "ZGU-20180113-0875",
    "status": "cp",
    "registered_at": "2018년 01월 13일 00시 42분"
  },
  {
    "id": 88,
    "serial": "ZGU-20171204-6751",
    "status": "cc",
    "registered_at": "2017년 12월 04일 10시 04분"
  },
  {
    "id": 87,
    "serial": "ZGU-20171118-1676",
    "status": "rj",
    "registered_at": "2017년 11월 18일 13시 27분"
  },
  {
    "id": 86,
    "serial": "ZGU-20171007-8980",
    "status": "cc",
    "registered_at": "2017년 10월 07일 12시 05분"
  },
  {
    "id": 85,
    "serial": "ZGU-20170603-2985",
    "status": "cc",
    "registered_at": "2017년 06월 03일 02시 46분"
  },
  {
    "id": 84,
    "serial": "ZGU-20170226-5275",
    "status": "fi",
    "registered_at": "2017년 02월 26일 12시 26분"
  },
  {
    "id": 83,
    "serial": "ZGU-20170222-5492",
    "status": "fi",
    "registered_at": "2017년 02월 22일 06시 52분"
  },
  {
    "id": 82,
    "serial": "ZGU-20170203-9511",
    "status": "rj",
    "registered_at": "2017년 02월 03일 22시 14분"
  },
  {
    "id": 81,
    "serial": "ZGU-20160815-4968",
    "status": "cp",
    "registered_at": "2016년 08월 15일 08시 09분"
  },
  {
    "id": 80,
    "serial": "ZGU-20160126-6105",
    "status": "rj",
    "registered_at": "2016년 01월 26일 04시 03분"
  },
  {
    "id": 79,
    "serial": "ZGU-20151227-3222",
    "status": "cc",
    "registered_at": "2015년 12월 27일 16시 52분"
  },
  {
    "id": 78,
    "serial": "ZGU-20151203-8224",
    "status": "rj",
    "registered_at": "2015년 12월 03일 00시 47분"
  },
  {
    "id": 77,
    "serial": "ZGU-20151009-0396",
    "status": "rq",
    "registered_at": "2015년 10월 09일 16시 26분"
  },
  {
    "id": 76,
    "serial": "ZGU-20141019-5764",
    "status": "rj",
    "registered_at": "2014년 10월 19일 14시 03분"
  },
  {
    "id": 75,
    "serial": "ZGU-20140727-5230",
    "status": "cp",
    "registered_at": "2014년 07월 27일 21시 37분"
  },
  {
    "id": 74,
    "serial": "ZGU-20140722-4926",
    "status": "rq",
    "registered_at": "2014년 07월 22일 23시 11분"
  },
  {
    "id": 73,
    "serial": "ZGU-20140602-7229",
    "status": "fi",
    "registered_at": "2014년 06월 02일 06시 04분"
  },
  {
    "id": 72,
    "serial": "ZGU-20140518-4767",
    "status": "cp",
    "registered_at": "2014년 05월 18일 13시 14분"
  },
  {
    "id": 71,
    "serial": "ZGU-20131007-7356",
    "status": "rj",
    "registered_at": "2013년 10월 07일 15시 19분"
  },
  {
    "id": 70,
    "serial": "ZGU-20130901-3098",
    "status": "cc",
    "registered_at": "2013년 09월 01일 15시 37분"
  },
  {
    "id": 69,
    "serial": "ZGU-20130823-7624",
    "status": "rj",
    "registered_at": "2013년 08월 23일 22시 38분"
  },
  {
    "id": 68,
    "serial": "ZGU-20130620-0364",
    "status": "rq",
    "registered_at": "2013년 06월 20일 09시 36분"
  },
  {
    "id": 67,
    "serial": "ZGU-20130223-4090",
    "status": "cc",
    "registered_at": "2013년 02월 23일 19시 48분"
  },
  {
    "id": 66,
    "serial": "ZGU-20130206-0974",
    "status": "cp",
    "registered_at": "2013년 02월 06일 16시 58분"
  },
  {
    "id": 65,
    "serial": "ZGU-20120926-0036",
    "status": "rj",
    "registered_at": "2012년 09월 26일 18시 11분"
  },
  {
    "id": 64,
    "serial": "ZGU-20120921-2770",
    "status": "rq",
    "registered_at": "2012년 09월 21일 16시 42분"
  },
  {
    "id": 63,
    "serial": "ZGU-20120314-5881",
    "status": "cc",
    "registered_at": "2012년 03월 14일 21시 04분"
  },
  {
    "id": 62,
    "serial": "ZGU-20120210-7565",
    "status": "rq",
    "registered_at": "2012년 02월 10일 23시 33분"
  },
  {
    "id": 61,
    "serial": "ZGU-20111202-2783",
    "status": "cp",
    "registered_at": "2011년 12월 02일 21시 35분"
  },
  {
    "id": 60,
    "serial": "ZGU-20110907-9430",
    "status": "cc",
    "registered_at": "2011년 09월 07일 13시 59분"
  },
  {
    "id": 59,
    "serial": "ZGU-20110410-4914",
    "status": "cp",
    "registered_at": "2011년 04월 10일 09시 15분"
  },
  {
    "id": 58,
    "serial": "ZGU-20101028-9927",
    "status": "fi",
    "registered_at": "2010년 10월 28일 16시 49분"
  },
  {
    "id": 57,
    "serial": "ZGU-20100818-0309",
    "status": "cp",
    "registered_at": "2010년 08월 18일 10시 33분"
  },
  {
    "id": 56,
    "serial": "ZGU-20100409-8517",
    "status": "cc",
    "registered_at": "2010년 04월 09일 15시 40분"
  },
  {
    "id": 55,
    "serial": "ZGU-20100401-5586",
    "status": "fi",
    "registered_at": "2010년 04월 01일 22시 47분"
  },
  {
    "id": 54,
    "serial": "ZGU-20100319-7944",
    "status": "rj",
    "registered_at": "2010년 03월 19일 12시 47분"
  },
  {
    "id": 53,
    "serial": "ZGU-20100306-1429",
    "status": "rq",
    "registered_at": "2010년 03월 06일 07시 47분"
  },
  {
    "id": 52,
    "serial": "ZGU-20100116-6179",
    "status": "rq",
    "registered_at": "2010년 01월 16일 22시 47분"
  },
  {
    "id": 51,
    "serial": "ZGU-20100102-3376",
    "status": "rj",
    "registered_at": "2010년 01월 02일 12시 05분"
  },
  {
    "id": 50,
    "serial": "ZGU-20091207-3302",
    "status": "rq",
    "registered_at": "2009년 12월 07일 16시 04분"
  },
  {
    "id": 49,
    "serial": "ZGU-20090908-7831",
    "status": "cp",
    "registered_at": "2009년 09월 08일 22시 17분"
  },
  {
    "id": 48,
    "serial": "ZGU-20090811-7419",
    "status": "cc",
    "registered_at": "2009년 08월 11일 16시 02분"
  },
  {
    "id": 47,
    "serial": "ZGU-20080717-0026",
    "status": "cc",
    "registered_at": "2008년 07월 17일 08시 32분"
  },
  {
    "id": 46,
    "serial": "ZGU-20080509-1377",
    "status": "rj",
    "registered_at": "2008년 05월 09일 08시 03분"
  },
  {
    "id": 45,
    "serial": "ZGU-20071216-8699",
    "status": "rq",
    "registered_at": "2007년 12월 16일 12시 51분"
  },
  {
    "id": 44,
    "serial": "ZGU-20070318-6412",
    "status": "cc",
    "registered_at": "2007년 03월 18일 21시 24분"
  },
  {
    "id": 43,
    "serial": "ZGU-20070301-7838",
    "status": "fi",
    "registered_at": "2007년 03월 01일 15시 58분"
  },
  {
    "id": 42,
    "serial": "ZGU-20061027-1317",
    "status": "cc",
    "registered_at": "2006년 10월 27일 22시 42분"
  },
  {
    "id": 41,
    "serial": "ZGU-20060626-6386",
    "status": "rq",
    "registered_at": "2006년 06월 26일 05시 33분"
  },
  {
    "id": 40,
    "serial": "ZGU-20060523-4326",
    "status": "cc",
    "registered_at": "2006년 05월 23일 15시 43분"
  },
  {
    "id": 39,
    "serial": "ZGU-20060326-6930",
    "status": "cc",
    "registered_at": "2006년 03월 26일 04시 51분"
  },
  {
    "id": 38,
    "serial": "ZGU-20051212-0426",
    "status": "cp",
    "registered_at": "2005년 12월 12일 23시 08분"
  },
  {
    "id": 37,
    "serial": "ZGU-20050818-4523",
    "status": "fi",
    "registered_at": "2005년 08월 18일 03시 51분"
  },
  {
    "id": 36,
    "serial": "ZGU-20050301-4681",
    "status": "fi",
    "registered_at": "2005년 03월 01일 06시 47분"
  },
  {
    "id": 35,
    "serial": "ZGU-20050227-4481",
    "status": "fi",
    "registered_at": "2005년 02월 27일 07시 00분"
  },
  {
    "id": 34,
    "serial": "ZGU-20040918-3783",
    "status": "cp",
    "registered_at": "2004년 09월 18일 03시 19분"
  },
  {
    "id": 33,
    "serial": "ZGU-20040913-7183",
    "status": "fi",
    "registered_at": "2004년 09월 13일 15시 06분"
  },
  {
    "id": 32,
    "serial": "ZGU-20040823-3294",
    "status": "fi",
    "registered_at": "2004년 08월 23일 15시 32분"
  },
  {
    "id": 31,
    "serial": "ZGU-20040521-6990",
    "status": "cp",
    "registered_at": "2004년 05월 21일 01시 51분"
  },
  {
    "id": 30,
    "serial": "ZGU-20031220-9401",
    "status": "cc",
    "registered_at": "2003년 12월 20일 06시 25분"
  },
  {
    "id": 29,
    "serial": "ZGU-20031208-8923",
    "status": "fi",
    "registered_at": "2003년 12월 08일 06시 45분"
  },
  {
    "id": 28,
    "serial": "ZGU-20030907-9645",
    "status": "rj",
    "registered_at": "2003년 09월 07일 07시 25분"
  },
  {
    "id": 27,
    "serial": "ZGU-20030818-5678",
    "status": "cp",
    "registered_at": "2003년 08월 18일 20시 09분"
  },
  {
    "id": 26,
    "serial": "ZGU-20030804-8745",
    "status": "rq",
    "registered_at": "2003년 08월 04일 13시 08분"
  },
  {
    "id": 25,
    "serial": "ZGU-20020907-5718",
    "status": "cc",
    "registered_at": "2002년 09월 07일 22시 20분"
  },
  {
    "id": 24,
    "serial": "ZGU-20020510-2181",
    "status": "cp",
    "registered_at": "2002년 05월 10일 02시 28분"
  },
  {
    "id": 23,
    "serial": "ZGU-20010808-1646",
    "status": "fi",
    "registered_at": "2001년 08월 08일 05시 13분"
  },
  {
    "id": 22,
    "serial": "ZGU-20010420-7583",
    "status": "cc",
    "registered_at": "2001년 04월 20일 13시 52분"
  },
  {
    "id": 21,
    "serial": "ZGU-20010107-8244",
    "status": "cc",
    "registered_at": "2001년 01월 07일 16시 58분"
  },
  {
    "id": 20,
    "serial": "ZGU-20000719-7319",
    "status": "cc",
    "registered_at": "2000년 07월 19일 03시 05분"
  },
  {
    "id": 19,
    "serial": "ZGU-20000209-3428",
    "status": "cc",
    "registered_at": "2000년 02월 09일 20시 13분"
  },
  {
    "id": 18,
    "serial": "ZGU-20000106-2428",
    "status": "cp",
    "registered_at": "2000년 01월 06일 23시 19분"
  },
  {
    "id": 17,
    "serial": "ZGU-19990422-4419",
    "status": "rq",
    "registered_at": "1999년 04월 22일 06시 37분"
  },
  {
    "id": 16,
    "serial": "ZGU-19990220-7318",
    "status": "rj",
    "registered_at": "1999년 02월 20일 20시 06분"
  },
  {
    "id": 15,
    "serial": "ZGU-19990202-8120",
    "status": "fi",
    "registered_at": "1999년 02월 02일 18시 45분"
  },
  {
    "id": 14,
    "serial": "ZGU-19980926-5214",
    "status": "cc",
    "registered_at": "1998년 09월 26일 13시 04분"
  },
  {
    "id": 13,
    "serial": "ZGU-19970908-2633",
    "status": "cc",
    "registered_at": "1997년 09월 08일 13시 54분"
  },
  {
    "id": 12,
    "serial": "ZGU-19970905-7800",
    "status": "rq",
    "registered_at": "1997년 09월 05일 08시 39분"
  },
  {
    "id": 11,
    "serial": "ZGU-19970317-6334",
    "status": "cp",
    "registered_at": "1997년 03월 17일 23시 56분"
  },
  {
    "id": 10,
    "serial": "ZGU-19970218-2613",
    "status": "rj",
    "registered_at": "1997년 02월 18일 04시 42분"
  },
  {
    "id": 9,
    "serial": "ZGU-19961018-7626",
    "status": "fi",
    "registered_at": "1996년 10월 18일 09시 56분"
  },
  {
    "id": 8,
    "serial": "ZGU-19960914-1637",
    "status": "fi",
    "registered_at": "1996년 09월 14일 11시 03분"
  },
  {
    "id": 7,
    "serial": "ZGU-19960804-5503",
    "status": "rj",
    "registered_at": "1996년 08월 04일 18시 17분"
  },
  {
    "id": 6,
    "serial": "ZGU-19960624-3896",
    "status": "cp",
    "registered_at": "1996년 06월 24일 08시 06분"
  },
  {
    "id": 5,
    "serial": "ZGU-19960315-3712",
    "status": "cc",
    "registered_at": "1996년 03월 15일 05시 27분"
  },
  {
    "id": 4,
    "serial": "ZGU-19960227-4856",
    "status": "cp",
    "registered_at": "1996년 02월 27일 17시 22분"
  },
  {
    "id": 3,
    "serial": "ZGU-19950409-8718",
    "status": "rj",
    "registered_at": "1995년 04월 09일 18시 34분"
  },
  {
    "id": 2,
    "serial": "ZGU-19941213-3256",
    "status": "rj",
    "registered_at": "1994년 12월 13일 18시 00분"
  },
  {
    "id": 1,
    "serial": "ZGU-19940626-5377",
    "status": "rj",
    "registered_at": "1994년 06월 26일 06시 05분"
  }
])
// TODO 특정 viewport 넓이에서 좌측에서 두번째 선택시 좌측 첫번째 스크롤 안됨

const statusDict = {
  "rq": "pending",
  "cc": "canceled",
  "cp": "completed",
  "fi": "failed",
  "rj": "forceCanceled"
}

// 필터 슬라이더 관련
const slideItems = ref([
  {label: '전체', value: 'all'},
  {label: '수거 예정', value: 'rq'},
  {label: '수거 완료', value: 'cp'},
  {label: '수거 실패', value: 'fi'},
  {label: '취소', value: 'cc'},
  {label: '강제 취소', value: 'rj'},
])

// 데이터 요청
const itemList = ref([])
const status = ref(slideItems.value[0].value)
const lastId = ref(null)

watch(status, (newValue, oldValue) => {
  // 필터 항목 선택시 스크롤
  let totalLength = slideItems.value.length
  let statusItems = proxy.$refs[`status-${newValue.toString()}`]
  let statusItem = statusItems[0]
  if (!statusItem) return
  let currentIndex = parseInt(statusItem.dataset.index)
  let nextIndex = 0
  let block = 'center'
  if (currentIndex+1 < totalLength/2) {
    // console.log('// 중앙보다 왼쪽 -> 왼쪽 메뉴 표시')
    // 중앙보다 왼쪽 -> 왼쪽 메뉴 표시
    nextIndex = Math.max(currentIndex - 3, 0)
    block = 'start'
  } else if (currentIndex+1 > totalLength/2) {
    // console.log('// 중앙보다 오른쪽 -> 오른쪽 메뉴 표시')
    // 중앙보다 오른쪽 -> 오른쪽 메뉴 표시
    // 중간은 스크롤 없다.
    nextIndex = Math.min(currentIndex + 3, totalLength - 1)
    block = 'end'
  }
  let nextValue = slideItems.value[nextIndex]?.value
  let nextItems = proxy.$refs[`status-${nextValue}`]
  if (nextItems) {
    nextItems[0].scrollIntoView({behavior: 'smooth', block: block})
  }
  setTimeout(() => {
    window.scroll({top: 0, behavior: 'smooth'})
  }, 300)
})


const fetchList = async () => {
  // 데이터 요청
  coffeeStore.setLoading(true)
  const response = await listDispose(status.value === 'all' ? null : status.value, lastId.value)
  const list = response?.results || []
  coffeeStore.setLoading(false)
  return list
}

const loadMore = async () => {
  lastId.value = itemList.value[itemList.value.length - 1].id
  let moreList = await fetchList()
  if (moreList.length === 0) {
    showAlert('더이상 불러올 신청 내역이 없습니다.')
  } else {
    itemList.value = itemList.value.concat(moreList)
  }
}

const changeStatus = async (targetStatus) => {
  lastId.value = null
  status.value = targetStatus
  itemList.value = await fetchList()
}

onMounted(async () => {
  itemList.value = await fetchList()
})


const alertIsShown = ref(false)
const alertBody = ref('')

const showAlert = (body) => {
  alertBody.value = body
  alertIsShown.value = true
}

const filter = ref(null)
const itemListElem = ref(null)

const handleScroll = () => {
  let rect = itemListElem.value.getBoundingClientRect()
  let itemListElemTopBase = 252
  let itemListTopPosition = rect.top
  // 아이템 리스트의 시작 위치 최소값은 128px
  if (48 - (itemListElemTopBase - itemListTopPosition) > 0) {
    filter.value.classList.remove('squeezed')
  } else {
    filter.value.classList.add('squeezed')
  }
}
</script>

<template>
  <div ref="filter"
       class="filter-wrapper z-20 fixed flex w-full justify-center top-0 left-0 right-0 bg-white pt-[100px] mb-3">
    <div class="w-full max-w-md">
      <div class="page-title mb-6 px-5" @click="() => itemList = []">
        수거 신청 내역
      </div>
      <div class="flex w-full overflow-x-scroll space-x-2 no-scrollbar">
        <template v-for="(item, itemIndex) in slideItems" v-bind:key="item.value">
          <div :ref="`status-${item.value}`" :data-index="itemIndex"
               :class="[
                           'status-item', 'flex-grow-0', item.value===status ?'selected':'',
                           itemIndex===0 ? 'ml-5':'',
                           itemIndex === slideItems.length - 1 ? 'mr-5':''
                       ]"
               @click=" changeStatus(item.value)">
            {{ item.label }}
          </div>
        </template>
      </div>
    </div>
  </div>
  <template v-if="itemList.length === 0">
    <div class="flex w-full h-full items-center justify-center pt-[144px] bottom-0"
         style="height: calc(100vh - 159px);">
      <div class="text-center">
        <div class="empty-title mb-3">신청 내역이 없습니다.</div>
        <div class="empty-text mb-6">간편하게 무상 수거 신청하세요!</div>
        <router-link :to="{name: 'coffee-request-collection'}" class="light-btn">수거 신청</router-link>
      </div>
    </div>
  </template>
  <template v-else>
    <!--responsive-->
    <div ref="itemListElem" class="items mt-[192px]">
      <template v-for="(item, itemIndex) in itemList">
        <router-link :ref="`item-${itemIndex}`" :to="{name: 'coffee-collection-detail', params: {id: item.id}}"
                     class="item-box">
          <div :class="['dispose-status', 'small', statusDict[item.status]]">{{ disposeStatus[item.status] }}</div>
          <div class="serial">{{ item.serial }}</div>
          <div class="flex w-full items-center justify-space-between">
            <div class="registered-at-label">수거 신청 일시</div>
            <div class="registered-at">{{ serverDatetimeParser(item.registered_at) }}</div>
          </div>
        </router-link>
      </template>
    </div>
    <div class="w-full mb-[20px]">
      <button class="blank-btn w-full" @click="loadMore">더보기</button>
    </div>
  </template>
<!--  <div v-if="onLoading" class="loading-wrapper z-40" @wheel.prevent @touchmove.prevent @scroll.prevent>-->
<!--    <div class="loading-spinner-wrapper">-->
<!--      <svg class="animate-spin" fill="none" height="32" viewBox="0 0 32 32" width="32"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path clip-rule="evenodd"-->
<!--              d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24ZM16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"-->
<!--              fill="#DFDFDF"-->
<!--              fill-rule="evenodd"-->
<!--              opacity="0.48"/>-->
<!--        <mask id="path-2-inside-1_440_826" fill="white">-->
<!--          <path-->
<!--              d="M31.7458 18.8409C31.2942 21.3439 30.2523 23.7032 28.7067 25.7232C27.1611 27.7431 25.1562 29.3654 22.8582 30.4556C20.5603 31.5458 18.0356 32.0725 15.4934 31.992C12.9513 31.9114 10.4649 31.226 8.24061 29.9926C6.01629 28.7591 4.11807 27.0131 2.70338 24.8994C1.28869 22.7857 0.39828 20.3652 0.105998 17.8387C-0.186285 15.3121 0.127981 12.7522 1.02273 10.3714C1.91748 7.99052 3.36694 5.85728 5.2509 4.14855L10.6306 10.0799C9.68951 10.9335 8.96547 11.9991 8.51853 13.1884C8.07158 14.3777 7.9146 15.6564 8.0606 16.9185C8.2066 18.1805 8.65138 19.3896 9.35805 20.4455C10.0647 21.5013 11.0129 22.3734 12.124 22.9896C13.2351 23.6057 14.4771 23.9481 15.747 23.9883C17.0168 24.0286 18.278 23.7655 19.4258 23.2209C20.5737 22.6763 21.5752 21.8659 22.3473 20.8569C23.1194 19.8479 23.6398 18.6694 23.8654 17.4191L31.7458 18.8409Z"/>-->
<!--        </mask>-->
<!--        <path-->
<!--            d="M31.7458 18.8409C31.2942 21.3439 30.2523 23.7032 28.7067 25.7232C27.1611 27.7431 25.1562 29.3654 22.8582 30.4556C20.5603 31.5458 18.0356 32.0725 15.4934 31.992C12.9513 31.9114 10.4649 31.226 8.24061 29.9926C6.01629 28.7591 4.11807 27.0131 2.70338 24.8994C1.28869 22.7857 0.39828 20.3652 0.105998 17.8387C-0.186285 15.3121 0.127981 12.7522 1.02273 10.3714C1.91748 7.99052 3.36694 5.85728 5.2509 4.14855L10.6306 10.0799C9.68951 10.9335 8.96547 11.9991 8.51853 13.1884C8.07158 14.3777 7.9146 15.6564 8.0606 16.9185C8.2066 18.1805 8.65138 19.3896 9.35805 20.4455C10.0647 21.5013 11.0129 22.3734 12.124 22.9896C13.2351 23.6057 14.4771 23.9481 15.747 23.9883C17.0168 24.0286 18.278 23.7655 19.4258 23.2209C20.5737 22.6763 21.5752 21.8659 22.3473 20.8569C23.1194 19.8479 23.6398 18.6694 23.8654 17.4191L31.7458 18.8409Z"-->
<!--            mask="url(#path-2-inside-1_440_826)" stroke="white" stroke-width="16"/>-->
<!--      </svg>-->
<!--      <div class="loading-spinner-wrapper-text">로딩중</div>-->
<!--    </div>-->
<!--  </div>-->
  <CoffeeAlert :body="alertBody" :show="alertIsShown" @close="alertIsShown=false"/>
</template>

<style scoped>

.registered-at-label {
  color: #777;
  text-align: left;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.registered-at {
  color: #111;
  text-align: right;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.serial {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
}

.item-box {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 12px;
  border: 1px solid #DFDFDF;
  margin-bottom: 12px;
}

.dispose-status.small {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  margin-bottom: 0;
}

.filter-wrapper {
  padding-top: 100px;
  transition: 100ms;
}

.filter-wrapper.squeezed {
  padding: 24px 0 12px 0;
  transition: 300ms;
}

.status-item {
  color: #B7B7B7;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  display: flex;
  height: 36px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid #DFDFDF;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  scroll-margin-left: 100px;
  scroll-margin-right: 100px;
}

.status-item.selected {
  color: white;
  background-color: #3182FF;
  border: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.empty-title {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
}

.empty-text {
  color: #111;
  font-family: NanumSquare, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
</style>